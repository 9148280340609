"use client";
import Image from "next/image";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { routes } from "@webelight-site/utils/routes";
import { Typography } from "@libs/src/components/Typography";
import { NewButton } from "@webelight-site/lib/ui/components/Button/NewButton";
import "swiper/css";
import "swiper/css/navigation";
import "../styles/industry.scss";
import { Transition } from "@libs/src/components/Transition";
import { DIRECTION_VARIANTS } from "@libs/utils/animation";
import Healthcare from "@webelight-site/public/img/homepage/Industry/health.webp";
import Education from "@webelight-site/public/img/homepage/Industry/education.webp";
import TravelAndHospitality from "@webelight-site/public/img/homepage/Industry/travelandhospitality.webp";
import energyandutility from "@webelight-site/public/img/homepage/Industry/energyandutility.webp";
import fintech from "@webelight-site/public/img/homepage/Industry/fintech.webp";
import logistics from "@webelight-site/public/img/homepage/Industry/logistics.webp";
import realestate from "@webelight-site/public/img/homepage/Industry/realestate.webp";
import ecommerce from "@webelight-site/public/img/homepage/Industry/ecommerce.webp";

const industries = [
  {
    id: 1,
    title: "Healthcare",
    description:
      "Revolutionize Patient Care with Cutting-Edge Technology Solutions Tailored for Medical Excellence",
    image: Healthcare,
    slug: "healthcare-software-development",
  },
  {
    id: 2,
    title: "Education",
    description:
      "Unlock Limitless Learning Potential with Personalized Educational Platforms Designed for Success",
    image: Education,
    slug: "elearning-software-development",
  },
  {
    id: 3,
    title: "Travel and Hospitality",
    description:
      "Elevate Guest Experiences with Integrated Solutions for Seamless Travel and Memorable Stays",
    image: TravelAndHospitality,
    slug: "travel-and-hospitality-app-development",
  },

  {
    id: 4,
    title: "Energy And Utility",
    description:
      "Enhance Operational Excellence with Strategic Solutions for Reliable Energy Production and Distribution",
    image: energyandutility,
    slug: "energy-and-utility-software-development",
  },
  {
    id: 5,
    title: "Fintech",
    description:
      "Empower Your Financial Future with Secure, Innovative Solutions for Wealth Growth and Management.",
    image: fintech,
    slug: "fintech-software-development",
  },
  {
    id: 6,
    title: "Logistics",
    description:
      "Optimize Supply Chain Efficiency with Data-Driven Solutions for Swift Delivery and Cost Savings.",
    image: logistics,
    slug: "logistics-software-development",
  },
  {
    id: 7,
    title: "Real Estate",
    description:
      "Discover Your Dream Home Faster with Streamlined Digital Tools for Seamless Property Searches.",
    image: realestate,
    slug: "real-estate-software-development",
  },
  {
    id: 8,
    title: "Retail & E-Commerce",
    description:
      "Boost Sales and Customer Satisfaction with Advanced E-commerce Strategies for Seamless Transactions.",
    image: ecommerce,
    slug: "ecommerce-software-development",
  },
];

const pagination = {
  clickable: true,
  renderBullet(index: number, className: string) {
    return '<span class="' + className + ' custom-bullet"></span>';
  },
};

const ComponentSwiper = () => {
  return (
    <div className="industry-swiper-container ">
      <Swiper
        spaceBetween={46}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 2500,
        }}
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="industry-swiper"
      >
        {industries.map(({ id, slug, title, description, image }) => (
          <SwiperSlide key={`${id}-${slug}`}>
            <Link href={routes.INDUSTRIES(slug)}>
              <div className="wb-bg-custom-radial-dark wb-border-[1.5px] wb-border-primary-100 wb-rounded-lg wb-flex wb-flex-col wb-relative">
                <div className="image-container">
                  <Image
                    width={667}
                    height={375}
                    src={image}
                    alt="service-icon"
                    className="wb-w-full wb-h-full wb-object-cover"
                  />
                </div>
                <div className="wb-px-8 wb-pb-8 wb-pt-1 wb-flex wb-flex-col wb-justify-end wb-gap-5">
                  <div className="wb-flex wb-flex-col wb-gap-[6px] wb-max-w-[357px]">
                    <Typography
                      tag="h3"
                      className="wb-text-3xl wb-font-medium wb-text-white wb-leading-[37.5px]"
                    >
                      {title}
                    </Typography>
                    <Typography
                      tag="p"
                      className="wb-text-base wb-font-normal wb-text-primary-grey wb-line-clamp-3"
                    >
                      {description}
                    </Typography>
                  </div>
                  <div className="wb-flex">
                    <NewButton theme="dark">Learn More</NewButton>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const IndustrySwiper = () => (
  <Transition
    variants={DIRECTION_VARIANTS.FROM_BOTTOM}
    transition={{
      delay: 0.3,
      duration: 0.2,
    }}
    className="wb-mt-12 md:wb-mt-[74px] wb-px-9 md:wb-px-0"
  >
    <ComponentSwiper />
  </Transition>
);
