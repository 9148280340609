"use client";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import { useDeviceDetect } from "@webelight-site/components/Header/hooks/useDeviceDetect";

const TechnologyStack = dynamic(() => import("./TechnologyStack"));
const MobileTechnologyStack = dynamic(() => import("./MobileTechnologyStack"));

export const TechnologyStackSection = () => {
  const { isMobile } = useDeviceDetect();

  return (
    <Suspense>
      {isMobile ? <MobileTechnologyStack /> : <TechnologyStack />}
    </Suspense>
  );
};
