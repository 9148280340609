import { clsx, type ClassValue } from "clsx";
import { phone } from "phone";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const formatDate = (input: string | number): string => {
  const date = new Date(input);

  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const absoluteUrl = (path: string) => {
  return `${process.env.NEXT_PUBLIC_BASE_URL}${path}`;
};

export const textTruncate = (text = "", length = 300) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

export const isValidPhone = ({
  phoneNumber,
  countryCode,
}: {
  phoneNumber: string;
  countryCode: string | undefined;
}) => {
  return phone(phoneNumber, { country: countryCode });
};
