import { useForm, Path, DefaultValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ObjectSchema } from "yup";
import { useCallback } from "react";

type FormValues = Record<string, AnyType>; //any needed

interface FormProps<T extends FormValues> {
  schema?: ObjectSchema<T, AnyType>; //any needed
  initialValues: T;
}

export const useHandleForm = <T extends FormValues>({
  schema,
  initialValues,
}: FormProps<T>) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    clearErrors,
    trigger,
    formState: { errors, isSubmitting, submitCount },
  } = useForm({
    resolver: schema && (yupResolver(schema) as AnyType), //any needed
    defaultValues: initialValues as DefaultValues<T>,
  });

  const setValues = useCallback(
    //any needed
    (data: AnyType) => {
      reset(data);
    },
    [reset],
  );

  const validate = (fieldName: Path<T>) => {
    if (fieldName) {
      trigger(fieldName);
      clearErrors(fieldName);
    } else {
      trigger();
    }
  };

  //any needed
  const setValidateData = (name: Path<T>, value: AnyType) => {
    setValue(name, value, { shouldValidate: true });
  };

  return {
    control,
    handleSubmit,
    values: watch(),
    setValue,
    reset,
    errors,
    register,
    setValues,
    clearErrors,
    trigger,
    validate,
    getValues,
    setValidateData,
    isSubmitting,
    submitCount,
  };
};
