import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/angle-right-primary.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/cloud.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/code.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/database.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/desktop-computer.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/link.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/refresh.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/components/CountUp/CountUp.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/components/SectionHeading/sectionHeading.scss");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/Accolades/style.scss");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/BlogSwiper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/CaseStudies/CaseStudySlider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/ContactUs/ContactForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/ExpectFromUs/ExpectationCard.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/FAQSection/FAQSliders.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/GlobalImpact/GlobalImpactSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/HeroSection/HeroSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/HireExpertise/HireExpertiseSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/Industry/IndustrySwiper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/ourClient/OurClients.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/OurServices/OurServicesCards.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/SectionBreakers/Achievements.css");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/StagesOfPlc/StagesCardStack.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/styles/section-breakers.scss");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/SectionBreakers/transformVision.css");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/OurServices/ourServices.scss");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/modules/homepage/components/TechnologyStack/TechnologyStackSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/accolades/businnes.webp");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/accolades/clutch.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/accolades/top_software.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/accolades/verified_agency.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/Benefits/point01.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/homepage/about/about-us.png");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/public/img/homepage/Expectation/archive.svg");
import(/* webpackMode: "eager" */ "/app/libs/src/components/Transition/Transition.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js")