/* eslint-disable max-lines */
import { v4 as uuidv4 } from "uuid";
import aws from "@webelight-site/public/img/homepage/tech/aws.svg";
import fireBase from "@webelight-site/public/img/homepage/tech/firebase.svg";
import flutter from "@webelight-site/public/img/homepage/tech/flutter.svg";
import mongoDb from "@webelight-site/public/img/homepage/tech/mongodb.svg";
import monoRepo from "@webelight-site/public/img/homepage/tech/monorepo.svg";
import nextJs from "@webelight-site/public/img/homepage/tech/nextjs.svg";
import nodeJs from "@webelight-site/public/img/homepage/tech/nodejs.svg";
import python from "@webelight-site/public/img/homepage/tech/python.svg";
import postgresql from "@webelight-site/public/img/homepage/tech/postgresql.svg";
import reactJs from "@webelight-site/public/img/homepage/tech/reactjs.svg";
import redis from "@webelight-site/public/img/homepage/tech/redis.svg";
import typescript from "@webelight-site/public/img/homepage/tech/typescript.svg";
import vueJs from "@webelight-site/public/img/homepage/tech/vuejs.svg";

export const whyUsReasons = [
  {
    id: uuidv4(),
    reason:
      "We understand your unique business needs, providing customized solutions aligned with your business goals.",
    variants: { initial: { y: 100, opacity: 0 }, final: { y: 0, opacity: 1 } },
    transition: { duration: 0.5, delay: 0.6 },
  },
  {
    id: uuidv4(),
    reason:
      "We create future-ready solutions that scale with your growth & stay relevant in the ever-evolving tech landscapes.",
    variants: { initial: { y: 100, opacity: 0 }, final: { y: 0, opacity: 1 } },
    transition: { duration: 0.5, delay: 0.7 },
  },
  {
    id: uuidv4(),
    reason:
      "We prioritize quality assurance and data security, ensuring robust & reliable solutions that meet industry standards.",
    variants: { initial: { y: 100, opacity: 0 }, final: { y: 0, opacity: 1 } },
    transition: { duration: 0.5, delay: 0.8 },
  },
];

export const techStacksHome = [
  {
    id: uuidv4(),
    name: "aws",
    url: aws,
  },
  {
    id: uuidv4(),
    name: "firebase",
    url: fireBase,
  },
  {
    id: uuidv4(),
    name: "flutter",
    url: flutter,
  },
  {
    id: uuidv4(),
    name: "mongodb",
    url: mongoDb,
  },
  {
    id: uuidv4(),
    name: "monorepo",
    url: monoRepo,
  },
  {
    id: uuidv4(),
    name: "next",
    url: nextJs,
  },
  {
    id: uuidv4(),
    name: "node",
    url: nodeJs,
  },
  {
    id: uuidv4(),
    name: "python",
    url: python,
  },
  {
    id: uuidv4(),
    name: "postgresql",
    url: postgresql,
  },
  {
    id: uuidv4(),
    name: "react",
    url: reactJs,
  },
  {
    id: uuidv4(),
    name: "redis",
    url: redis,
  },
  {
    id: uuidv4(),
    name: "typescript",
    url: typescript,
  },
  {
    id: uuidv4(),
    name: "vue",
    url: vueJs,
  },
];
export const topServicesHome = [
  {
    id: uuidv4(),
    title: "Digital Marketing",
    description:
      "Elevate your brand, engage audience & drive exponential digital growth.",
    href: "/services/digital-marketing-strategy",
    icon: "/img/homepage/services/digital-marketing.svg",
  },
  {
    id: uuidv4(),
    title: "AI/ML Development",
    description:
      "Intelligent data-driven solutions created with future ready technologies.",
    href: "/services/data-and-artificial-intelligence",
    icon: "/img/homepage/services/aiml.svg",
  },
  {
    id: uuidv4(),
    title: "Software Development",
    description:
      "Scalable, efficient software solutions for businesses of all sizes.",
    href: "/services/custom-software-development",
    icon: "/img/homepage/services/software.svg",
  },
  {
    id: uuidv4(),
    title: "App Development",
    description:
      "Mobile/Web apps with exceptional cross-platform user experiences.",
    href: "/services/mobile-app-development",
    icon: "/img/homepage/services/application.svg",
  },
];
export const ourRecentNewsHome = [
  {
    id: "1",
    title: "7 Useful AI Tools for Developers in 2024.",
    category: "Boost Productivity",
    href: "/blogs/7-useful-ai-tools-for-developers-in-2024",
    imgSrc: "/img/homepage/blog/ai-tools-for-developers.png",
    slug: "7-useful-ai-tools-for-developers-in-2024",
    content: `<p>
      Hey there, fellow developers! Are you ready to streamline your
      coding game? In the dynamic world of technology, staying ahead means
      having the right tools in your arsenal. Today, we're diving into
      seven absolute game-changing AI tools that will not only elevate
      your coding skill-set but also make you the hero of your development
      team. Buckle up for a journey into the future of coding!
    </p>

    <h3>1) GitHub Copilot: Your AI Coding Companion</h3>
    <p>
      Ever wished you had a coding sidekick? Well, GitHub Copilot is here
      to make that dream a reality! Powered by OpenAI, this tool is like
      having an AI co-pilot that not only generates code snippets on
      demand but also provides intelligent suggestions as you type. Say
      goodbye to the days of wrestling with syntax.Get ready to code at
      the speed of thought!
    </p>
    <ul>
      <li>
        Works with a variety of programming languages, including Python,
        JavaScript, Java, Go, and more.
      </li>
      <li>
        Generates documentation comments based on your code, improving
        code clarity and maintainability.
      </li>
      <li>
        Suggests refactoring to improve your code structure and
        organization.
      </li>
      <li>
        Works seamlessly with popular IDEs like Visual Studio Code,
        Neovim, and JetBrains products.
      </li>
    </ul>

    <h3>2) TensorFlow: Empowering Your AI and ML Dreams</h3>
    <p>
      For those who are eager to delve into the realms of artificial
      intelligence and machine learning, TensorFlow is the key to
      unlocking your potential. As an open-source framework, TensorFlow
      empowers developers to create, train, and deploy machine learning
      models with ease. Whether you're an ML expert or a curious newcomer,
      TensorFlow is your go-to companion on the journey to building
      intelligent applications.
    </p>
    <ul>
      <li>Performs statistical analysis and infer insights from data.</li>
      <li>
        Builds deep learning models with a simpler and more expressive
        API.
      </li>
      <li>Exports model for running on mobile devices.</li>
      <li>Calculates gradients for optimization algorithms.</li>
    </ul>

    <h3>3) Jupyter Notebook: Where Data Science Meets AI Magic</h3>
    <p>
      Data analysis and visualization are at the heart of many development tasks, and jupyter Notebook is the secret sauce
      that enhances these processes. This AI-enhanced notebook allows you to supercharge your data-related tasks, making exploration and
      presentation a delightful experience. Get ready to impress your team with visually stunning and insight-packed presentations!
    </p>
    <ul>
      <li>
        Creates interactive visualizations and dashboards using libraries
        like matplotlib and bokeh for richer data exploration.
      </li>
      <li>
        Extends functionality with numerous community-developed extensions
        and kernels for specific tasks.
      </li>
      <li>
        Uses version control systems like Git to track changes,
        collaborate, and revert to previous versions.
      </li>
      <li>
        Supports a wide range of programming languages like Python, R,
        Julia, JavaScript, etc.
      </li>
    </ul>
    <p>
      Need a custom solution for your next AI project? Our AI experts are
      equipped to handle any challenge.
    </p>

    <h3>4) PyTorch: Igniting the Flame of Deep Learning</h3>
    <p>
      Deep learning enthusiasts, rejoice! PyTorch is your trusted ally
      when it comes to building and training neural networks. Known for
      its dynamic computational graph and intuitive design, PyTorch makes
      the complex world of deep learning more accessible. Whether you're a
      researcher pushing the boundaries of AI or a developer working on
      cutting-edge projects, PyTorch has got your back.
    </p>
    <ul>
      <li>
        Efficiently handles multidimensional arrays similar to NumPy, with
        strong GPU acceleration.
      </li>
      <li>
        Defines and modifies DNNs on the fly, enabling flexible
        experimentation and prototyping.
      </li>
      <li>
        Leverages multiple GPUs or machines to significantly reduce
        training time.
      </li>
      <li>
        Provides tools for analyzing model behavior and debugging training
        issues.
      </li>
    </ul>

    <h3>5) Checkmarx: Your AI Security Guardian</h3>
    <p>
      In a digital landscape fraught with security threats, Checkmarx
      stands as your AI-powered security guardian. Utilizing in-depth
      static and dynamic code analysis, Checkmarx detects vulnerabilities
      in your software early in the development process. Consider it your
      vigilant guardian scanning your code, ensuring it is impenetrable .
      Sleep well at night, knowing your applications are fortified against
      potential cyber attacks.
    </p>
    <ul>
      <li>
        Analyzes your source code in various languages to detect a wide
        range of vulnerabilities.
      </li>
      <li>
        Integrates with open-source libraries and frameworks to identify
        and track vulnerabilities within your codebase dependencies..
      </li>
      <li>
        Scans web applications for vulnerabilities such as SQL injection,
        cross-site scripting, and insecure direct object references.
      </li>
      <li>
        Helps you comply with various security standards and regulations
        such as OWASP Top 10, PCI DSS, and HIPAA.
      </li>
    </ul>

    <h3>6)  Rasa: Conversational AI Unleashed</h3>
    <p>
      Ever wanted to give life to your applications? Look no further than
      Rasa - your gateway to designing chatbots and virtual assistants.
      Engage your users in natural, dynamic conversations, and watch as
      Rasa transforms your applications into interactive and user-friendly
      experiences. The future of user interaction is here, and it's
      conversational!
    </p>
    <ul>
      <li>
        Orchestrates conversation flow based on NLU insights and rules.
      </li>
      <li>
        Integrates with various platforms like messaging apps and
        websites.
      </li>
      <li>
        Adapts to your specific needs and integrates with other tools.
      </li>
      <li>
        Allows you to create sophisticated conversational interfaces.
      </li>
    </ul>
    <p>
      Need help designing a seamless user interface for your chatbot? Tap
      into Our UI/UX Expertise{" "}
    </p>

    <h3>7) DeepCode: Your AI Code Reviewer</h3>
    <p>
      Code review is a necessary but time-consuming process. DeepCode, the
      AI-powered code reviewer not only catches bugs but also enhances the
      overall quality of your code. Bid farewell to the tedious manual
      reviews and let DeepCode's algorithms analyze your code for
      potential improvements. Think of it as a virtual code mentor
      offering constructive feedback at every turn.
    </p>
    <ul>
      <li>Alerts you to issue as you code, promoting early fixes.</li>
      <li>
        Supports multiple programming languages, including Java,
        JavaScript, TypeScript, Python, and C++.
      </li>
      <li>
        your code in real-time, providing instant feedback and
        highlighting potential problems.
      </li>
      <li>
        Seamlessly integrates with popular code repositories and editors,
        like GitHub and VS Code.
      </li>
    </ul>
    <p>
      And there you have it ! Seven AI tools poised to revolutionize the
      way you code, design, and secure your applications. Whether you're
      an experienced coding veteran or just embarking on your programming
      journey, incorporate these AI tools into your life and witness the
      transformation in your workflow. The future of technology is here,
      and it's in your hands.
    </p>
    <p>
      Ready to put these AI tools to the test and land your dream coding
      job? Check out our careers page for exciting job opportunities!
    </p>`,
  },
];
export const ourTechSwiperBreakpoints = {
  320: {
    slidesPerView: 2,
  },
  740: {
    slidesPerView: 4,
    spaceBetween: "20",
  },
  1000: {
    slidesPerView: 4,
    spaceBetween: "30",
  },
  1100: {
    slidesPerView: 4,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: "18px",
  },
  1300: {
    slidesPerView: 5,
  },
};

export const clientsData = [
  {
    title: "just date",
    image: "/img/breakers-image/clients/03.svg",
    width: 300,
  },
  {
    title: "cold watch",
    image: "/img/breakers-image/clients/06.webp",
    width: 148,
  },
  {
    title: "Og stack",
    image: "/img/breakers-image/clients/04.svg",
    width: 101,
  },
  {
    title: "navodya",
    image: "/img/breakers-image/clients/01.svg",
    width: 300,
  },
  {
    title: "your-e-locker",
    image: "/img/breakers-image/clients/05.webp",
    width: 233,
  },
  {
    title: "toppstr",
    image: "/img/breakers-image/clients/02.svg",
    width: 206,
  },
];

export const slides = [
  {
    title: "Mobile App",
    subtitle: { firstPart: "Development", lastPart: "Company" },
    description:
      "Mobile/Web apps with exceptional cross-platform user experiences.",
  },
  {
    title: "Software",
    subtitle: { firstPart: "Development", lastPart: "Company" },
    description:
      "Scalable, efficient software solutions for businesses of all sizes.",
  },
  {
    title: "AI ML",
    subtitle: { firstPart: "Development", lastPart: "Company" },
    description:
      "Intelligent data-driven solutions created with future ready technologies.",
  },
  {
    title: "Digital",
    subtitle: { firstPart: "Transformation", lastPart: "Company" },
    description:
      "Elevate your brand, engage the audience & drive exponential digital growth.",
  },
  {
    title: "Hire Dedicated",
    subtitle: { firstPart: "Development", lastPart: "Team" },
    description:
      "Empower your vision, achieve superior results with our dedicated developers.",
  },
];
