"use client";
import PhoneInput from "react-phone-input-2";
import { Typography } from "@libs/src/components/Typography";
import { cn } from "@webelight-site/lib/utils";
import "@webelight-site/components/ScheduleACallForm/styles/flags.css";

interface PhoneInputFieldProps {
  name: string;
  id: string;
  value: string;
  error?: string;
  customClass?: string;
  handleChange: (newValue: string, country: string) => void;
}

export const PhoneInputFieldContactUs = ({
  name,
  id,
  value,
  error,
  handleChange,
  customClass,
}: PhoneInputFieldProps) => {
  return (
    <div className="wb-flex wb-flex-col">
      <PhoneInput
        country="in"
        enableSearch
        value={value}
        onChange={handleChange}
        inputProps={{
          name,
          id,
          autoComplete: "none",
          className: cn(
            `${customClass} wb-h-[52px] wb-text-gray-500 wb-w-full wb-h-[45px] wb-pl-[60px] wb-pr-[14px] wb-py-3 form-input wb-gap-2`,
          ),
        }}
        specialLabel=""
        placeholder={value}
      />
      {Boolean(error) && (
        <Typography
          tag="p"
          className="wb-text-red-danger wb-text-xs sm:wb-text-sm wb-h-8 sm:wb-h-5 wb-mt-2"
        >
          <Typography tag="span">{error}</Typography>
        </Typography>
      )}
    </div>
  );
};
