"use client";
import { useState } from "react";
import Link from "next/link";
import { NewButton } from "@webelight-site/lib/ui/components/Button/NewButton";
import { slides } from "@webelight-site/modules/homepage/constant";
import { Typography } from "@libs/src/components/Typography";
import { HeroSectionImageSlider } from "@webelight-site/modules/homepage/components/HeroSection/HeroSectionImageSlider";
import { routes } from "@webelight-site/utils/routes";

export const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const currentContent = slides[currentSlide];

  return (
    <section className="wb-bg-custom-radial">
      <div className="my-container md:wb-pt-[136px] wb-pt-[128px] md:wb-pb-[96px] wb-pb-[48px]">
        <div className="wb-flex wb-flex-col xl:wb-flex-row wb-gap-[84px]">
          <div className="wb-flex wb-flex-col wb-max-w-[495px] wb-w-full xl:wb-py-[95.5px]">
            <Typography
              tag="h3"
              className="wb-text-[42px] wb-leading-[60px] md:wb-text-[60px] wb-text-primary-100 wb-font-semibold md:wb-leading-[75px]"
            >
              {currentContent.title}
            </Typography>
            <Typography
              tag="span"
              className="wb-text-[42px] wb-leading-[60px] md:wb-text-[60px] wb-text-white wb-font-semibold md:wb-leading-[75px]"
            >
              {currentContent.subtitle.firstPart}
            </Typography>
            <Typography
              tag="span"
              className="wb-text-[42px] wb-leading-[60px] md:wb-text-[60px] wb-text-white wb-font-semibold md:wb-leading-[75px]"
            >
              {currentContent.subtitle.lastPart}
            </Typography>
            <Typography
              tag="p"
              className="wb-font-normal wb-text-xl wb-leading-[30px] wb-pt-[20px] wb-text-primary-grey"
            >
              {currentContent.description}
            </Typography>
            <Link
              href={routes.CONTACT_US}
              className="wb-flex wb-items-start wb-pt-8"
            >
              <NewButton spanClassName="wb-text-white">
                Drop Your Queries
              </NewButton>
            </Link>
          </div>
          <div className="wb-w-full wb-h-full wb-mx-auto wb-max-w-[700px] wb-max-[500px] wb-flex wb-justify-center wb-items-center">
            <HeroSectionImageSlider
              onChange={(newIndex: number) => setCurrentSlide(newIndex)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
