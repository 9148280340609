import AiMlDeveloper from "@webelight-site/public/img/hero-section/AiMlDevelopment.webp";
import SoftwareDevelopment from "@webelight-site/public/img/hero-section/SoftwareDevelopment.webp";
import DigitalTransformation from "@webelight-site/public/img/hero-section/DigitalTransformation.webp";
import HireDedicatedDevelopment from "@webelight-site/public/img/hero-section/HireDedicatedDevelopment.webp";
import MobileAppDevelopment from "@webelight-site/public/img/hero-section/MobileAppDevelopment.svg";

export const heroSectionImages = [
  {
    src: MobileAppDevelopment,
    alt: "mobile-app-development",
  },
  {
    src: SoftwareDevelopment,
    alt: "Software-development",
  },
  {
    src: AiMlDeveloper,
    alt: "AI/ML",
  },
  {
    src: DigitalTransformation,
    alt: "Digital-Transformation",
  },
  {
    src: HireDedicatedDevelopment,
    alt: "Hire-Dedicated",
  },
];
