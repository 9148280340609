import { AxiosRequestConfig } from "axios";
import { KeyboardEvent } from "react";
import { axiosInstance } from "./client";
import { ScheduleCallFormDataProps } from "@webelight-site/components/ScheduleACallForm/validate";
import { disallowedKeysForNumberField } from "./constant";

export const cleanScheduleCallForm = (data: ScheduleCallFormDataProps) => {
  const res: ScheduleCallFormDataProps = {
    name: "",
    email: "",
    phone: "",
    companyName: "",
    message: "",
    countryName: "",
  };

  res.name = data.name.trim();
  res.email = data.email.trim();
  res.phone = data.phone.trim();
  res.companyName = data.companyName.trim();
  res.countryName = data.countryName;
  res.message = data.message.trim();

  return res;
};

export const submitScheduleCallForm = async (
  url: string,
  formData: Omit<ScheduleCallFormDataProps, "countryName">,
  options?: AxiosRequestConfig,
) => {
  const { data } = await axiosInstance.post(
    url,
    { formData },
    {
      ...options,
      timeout: 5000,
    },
  );

  return data;
};

export const disAllowedKeysEvent = (e: KeyboardEvent<HTMLInputElement>) => {
  if (disallowedKeysForNumberField.includes(e.key)) {
    e.preventDefault();
  }
};
