import React from "react";
import Link from "next/link";
import Image from "next/image";
import angleRightPrimary from "@webelight-site/assets/angle-right-primary.svg";
import {
  checkMarkImageHeight,
  checkMarkImageWidth,
} from "@webelight-site/constants/image-dimensions";
import { routes } from "@webelight-site/utils/routes";
import { formattedDateBlog } from "@webelight-site/utils/dateFunctions";
import { Typography } from "@libs/src/components/Typography";

interface BlogCardProps {
  imageSrc: string;
  title: string;
  description: string;
  date: string;
  url: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  imageSrc,
  title,
  description,
  date,
  url,
}) => {
  return (
    <article className="wb-flex wb-flex-col wb-p-6 wb-bg-light wb-border-[1px] hover:wb-shadow-xl  wb-border-[#95BEE4] wb-rounded-lg wb-max-w-[405px] wb-h-full wb-w-full">
      <div className="wb-w-full wb-h-full wb-max-w-[357px] wb-max-h-[192px]">
        <Image
          loading="lazy"
          src={imageSrc}
          alt={title}
          width={357}
          height={192}
          className="wb-w-full wb-h-full wb-max-w-[357px] wb-max-h-[192px]"
        />
      </div>
      <div className="md:wb-h-[124px]">
        <Typography
          tag="h3"
          className="wb-pt-6 wb-text-[24px] wb-font-medium wb-leading-[30px] wb-text-primary-dark-200"
        >
          {title}
        </Typography>
      </div>

      <div className="wb-h-[58px]">
        <Typography
          tag="p"
          className="wb-pt-2.5 wb-leading-6  wb-text-primary-grey-100 !wb-line-clamp-2 "
        >
          {description}
        </Typography>
      </div>
      <div className="wb-flex wb-gap-3 wb-justify-between wb-mt-3 wb-w-full wb-text-primary-light-200 wb-items-center">
        <time
          dateTime={date}
          className="wb-text-[16px] wb-font-medium wb-leading-[16px]"
        >
          {formattedDateBlog(date)}
        </time>
        <Link href={routes.SPECIFIC_BLOG(url)}>
          <div className="wb-flex wb-gap-1.5 wb-items-center">
            <span>Read More</span>
            <Image
              loading="lazy"
              width={checkMarkImageWidth}
              height={checkMarkImageHeight}
              src={angleRightPrimary}
              alt="angle-right"
              className="wb-inline-block"
            />
          </div>
        </Link>
      </div>
    </article>
  );
};

export default BlogCard;
