"use client";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { clientsLogo } from "@webelight-site/modules/homepage/components/ourClient/constant";
import { imageQuality } from "@webelight-site/constants/image-dimensions";
import "./client.scss";
import "swiper/css";

export const OurClients = () => {
  return (
    <div id="clients-logo" className="wb-bg-light-slighty-blue">
      <div className="wb-flex wb-justify-center my-container">
        <Swiper
          spaceBetween={40}
          slidesPerView={1}
          loop
          autoplay={{
            delay: 2500,
          }}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            700: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1580: {
              slidesPerView: 4,
            },
          }}
          className="client-swiper"
        >
          {clientsLogo.map((client) => (
            <SwiperSlide key={client.title}>
              <div className="wb-flex wb-justify-center wb-items-center">
                <Image
                  src={client.image}
                  alt={client.title}
                  height={60}
                  width={290}
                  quality={imageQuality}
                  loading="eager"
                  className="wb-h-[60px] wb-select-none"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
