import axios from "axios";
import appConfig from "apps/webelight-site/config/app.config";

export const axiosInstance = axios.create({
  baseURL: appConfig.baseUrlCMS,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${appConfig.cmsToken}`;
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const chatBotAxiosInstance = axios.create({
  baseURL: appConfig.chatBotBaseUrl,
  withCredentials: true,
});

chatBotAxiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${appConfig.chatBotToken}`;
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
