"use client";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import { useDeviceDetect } from "@webelight-site/components/Header/hooks/useDeviceDetect";
import { CaseStudy } from "./CaseStudiesSection";

const CaseStudySwiper = dynamic(() => import("./CaseStudySwiper"));
const CaseStudyHorizontalSwiper = dynamic(
  () => import("./CaseStudyHorizontalSwiper"),
);

interface CaseStudySwiperProps {
  caseStudies: CaseStudy[];
}

export const CaseStudySlider = ({ caseStudies }: CaseStudySwiperProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <Suspense>
      {isMobile ? (
        <CaseStudyHorizontalSwiper caseStudies={caseStudies} />
      ) : (
        <CaseStudySwiper caseStudies={caseStudies} />
      )}
    </Suspense>
  );
};
