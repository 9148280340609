import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@webelight-site/lib/utils";
import { forwardRef } from "react";

const newButtonVariants = cva(
  "wb-group wb-flex wb-flex-row wb-items-center wb-justify-center wb-max-w-full",
  {
    variants: {
      size: {
        default: "wb-h-12 wb-py-4 wb-font-medium wb-text-lg wb-leading-[18px]",
        large: "wb-h-14 wb-px-8 wb-py-4 wb-font-semibold wb-text-lg",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

export interface NewButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof newButtonVariants> {
  asChild?: boolean;
  sx?: string;
  theme?: "light" | "dark";
  spanClassName?: string;
}

const NewButton = forwardRef<HTMLButtonElement, NewButtonProps>(
  (
    {
      className,
      size,
      asChild = false,
      sx,
      theme = "light",
      spanClassName,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <div className={cn(newButtonVariants({ size, className }))}>
        <div className="wb-flex wb-items-center wb-relative">
          <div
            className={`wb-border-2 wb-border-primary-light-200 wb-w-[52px] wb-h-[52px] transition-all wb-duration-[300ms] wb-rounded-full wb-absolute group-hover:wb-bg-primary-light-200 group-hover:wb-w-full ${sx}`}
          />
          <svg
            width="46"
            height="14"
            viewBox="0 0 46 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="wb-relative wb-left-3 wb-ml-[16px] wb-z-10 wb-fill-primary-light-200 wb-stroke-primary-light-200 group-hover:wb-fill-white group-hover:wb-stroke-white"
          >
            <path d="M43.7931 7.50565L42.9396 8.3592L38.6501 12.6486C38.5606 12.7427 38.5112 12.8678 38.5123 12.9977C38.5134 13.1288 38.566 13.2542 38.6587 13.3469C38.7514 13.4396 38.8769 13.4922 39.0079 13.4933C39.1378 13.4945 39.263 13.445 39.357 13.3555L45.3534 7.35915C45.3534 7.35913 45.3534 7.35911 45.3534 7.35909C45.4471 7.26533 45.4998 7.1382 45.4998 7.00565C45.4998 6.87309 45.4471 6.74596 45.3534 6.6522L43.7931 7.50565ZM43.7931 7.50565H42.586H1C0.867392 7.50565 0.740215 7.45297 0.646447 7.3592C0.552678 7.26543 0.5 7.13825 0.5 7.00565C0.5 6.87304 0.552678 6.74586 0.646447 6.65209C0.740215 6.55832 0.867392 6.50565 1 6.50565H42.586H43.7931L42.9396 5.65209L38.6466 1.35915C38.6466 1.35913 38.6466 1.35911 38.6466 1.35909C38.5529 1.26533 38.5002 1.1382 38.5002 1.00565C38.5002 0.873115 38.5528 0.746006 38.6465 0.652252C38.7403 0.558518 38.8674 0.505859 39 0.505859C39.1326 0.505859 39.2597 0.558499 39.3534 0.652199C39.3535 0.652217 39.3535 0.652234 39.3535 0.652252L45.3534 6.65214L43.7931 7.50565Z" />
          </svg>

          <div className="wb-z-10 wb-ml-4 wb-mx-6">
            <Comp
              className={cn(
                "wb-relative wb-ml-[16px] wb-z-10 group-hover:text-white",
                {
                  "wb-text-white": theme === "dark",
                  "wb-text-[#1F3C5B]": theme === "light",
                },
              )}
              ref={ref}
              {...props}
            >
              <span className={`wb-whitespace-nowrap ${spanClassName}`}>
                {props.children}
              </span>
            </Comp>
          </div>
        </div>
      </div>
    );
  },
);

NewButton.displayName = "NewButton";

export { NewButton, newButtonVariants };
