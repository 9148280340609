"use client";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import { useDeviceDetect } from "@webelight-site/components/Header/hooks/useDeviceDetect";

const GlobalImpact = dynamic(() => import("./GlobalImpact"));
const GlobalImpactMobile = dynamic(() => import("./GlobalImpactMobile"));

export const GlobalImpactSection = () => {
  const { isMobile } = useDeviceDetect();

  return (
    <Suspense>{isMobile ? <GlobalImpactMobile /> : <GlobalImpact />}</Suspense>
  );
};
