"use client";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { NewButton } from "@webelight-site/lib/ui/components/Button/NewButton";
import { useHandleForm } from "@webelight-site/lib/ui/components/formHandler";
import { contactFormSchema } from "@webelight-site/utils/validationSchema";
import { submitScheduleCallForm } from "@webelight-site/utils/cleanScheduleCallForm";
import { PhoneInputFieldContactUs } from "@webelight-site/modules/homepage/components/PhoneInputFieldContactUs";
import { ErrorMessage } from "@webelight-site/modules/homepage/components/ErrorMessage/";
import { endpoint } from "@webelight-site/modules/contact-us/Components/hooks/apiKeys";
import { Typography } from "@libs/src/components/Typography";
import "@webelight-site/modules/homepage/components/ContactUs/contact.scss";

const initialValues = {
  name: "",
  email: "",
  message: "",
  companyName: "",
  phone: "",
};

const ContactForm = () => {
  const { handleSubmit, errors, register, control, reset } = useHandleForm({
    schema: contactFormSchema,
    initialValues,
  });

  const [message, setMessage] = useState(false);

  const onSubmit = (data: AnyType) => {
    submitScheduleCallForm(endpoint.contactUsApi, data).then(() => {
      reset();
      setMessage(true);
    });
  };

  return (
    <section className="wb-flex wb-flex-col md:wb-ml-5 wb-w-full md:wb-w-[603px]">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="wb-flex wb-flex-col wb-self-stretch wb-my-auto wb-text-base wb-font-medium wb-leading-6 wb-text-gray-900"
      >
        <div className="wb-flex wb-flex-col wb-gap-y-2 lg:wb-gap-y-[19px]">
          <div className="wb-flex wb-flex-col wb-gap-y-6 md:wb-flex-row wb-gap-4 md:wb-gap-8">
            <div className="wb-w-full md:wb-w-[calc(50%-16px)] wb-flex wb-flex-col">
              <label htmlFor="name" className="required wb-font-medium">
                Full Name
              </label>
              <input
                type="name"
                id="name"
                {...register("name")}
                className="wb-h-[52px] wb-justify-center wb-px-4 wb-py-3.5 wb-mt-2 wb-text-gray-500 wb-bg-gray-50 wb-rounded-lg wb-border wb-border-gray-300 wb-border-solid"
              />
              <ErrorMessage message={errors.name?.message} />
            </div>
            <div className="wb-w-full md:wb-w-[calc(50%-16px)]  wb-flex wb-flex-col">
              <label htmlFor="email" className="required  wb-font-medium">
                Your Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email")}
                className="wb-h-[52px] wb-justify-center wb-px-4 wb-py-3.5 wb-mt-2 wb-text-gray-500 wb-bg-gray-50 wb-rounded-lg wb-border wb-border-gray-300 wb-border-solid "
              />
              <ErrorMessage message={errors.email?.message} />
            </div>
          </div>

          <div className="wb-pt-4 wb-flex wb-flex-col md:wb-flex-row wb-gap-4 md:wb-gap-8">
            <div className="wb-gap-[7px] wb-w-full md:wb-w-[calc(50%-16px)] wb-flex wb-flex-col">
              <label htmlFor="phone" className=" wb-font-medium">
                Contact Number
              </label>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInputFieldContactUs
                    name="phone"
                    id="phone"
                    value={String(field.value)}
                    handleChange={field.onChange}
                    customClass="wb-bg-gray-50 wb-rounded-lg wb-border wb-border-gray-300 wb-border-solid wb-flex wb-border"
                  />
                )}
              />
            </div>
            <div className="wb-w-full md:wb-w-[calc(50%-16px)] wb-flex wb-flex-col">
              <label htmlFor="companyName" className=" wb-font-medium">
                Company Name
              </label>
              <input
                type="companyName"
                id="companyName"
                {...register("companyName")}
                className="wb-h-[52px] wb-justify-center wb-px-4 wb-py-3.5 wb-mt-2 wb-text-gray-500 wb-bg-gray-50 wb-rounded-lg wb-border wb-border-gray-300 wb-border-solid "
              />
              <ErrorMessage message={errors.companyName?.message} />
            </div>
          </div>

          <div className="wb-flex wb-flex-col">
            <label htmlFor="message" className="wb-mt-4  wb-font-medium">
              Your Message
            </label>
            <textarea
              id="message"
              {...register("message")}
              className="wb-h-[155px] wb-justify-center wb-px-5 wb-pt-3.5 wb-pb-8 wb-mt-3 wb-text-sm wb-text-gray-500 wb-bg-gray-50 wb-rounded-lg wb-border wb-border-gray-300 wb-border-solid "
              placeholder="Write text here ..."
            />
            <ErrorMessage message={errors.message?.message} />
          </div>
          <div className="wb-flex wb-pt-[24px] md:wb-items-center wb-gap-4 md:wb-gap-12 wb-flex-col md:wb-flex-row wb-items-start">
            <NewButton
              theme="light"
              spanClassName="group-hover:wb-text-white"
              type="submit"
            >
              Get A Quote
            </NewButton>
            {message ? (
              <Typography tag="span" className="wb-text-primary-300">
                Thank you, we have successfully received your message.
              </Typography>
            ) : null}
          </div>
        </div>
      </form>
    </section>
  );
};

export default ContactForm;
