"use client";
import { useState, useEffect, useRef } from "react";
import { Typography } from "@libs/src/components/Typography";

interface Props {
  targetNumber: number;
}
export const CountUp = ({ targetNumber }: Props) => {
  const [count, setCount] = useState(0);
  const [isInView, setIsInView] = useState<boolean>(false);

  const eleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkInView = () => {
      if (eleRef.current) {
        const rect = eleRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          setIsInView(rect.top < window.innerHeight && rect.bottom >= 0);
        }
      }
    };

    checkInView();
    document.addEventListener("scroll", checkInView);

    return () => {
      document.removeEventListener("scroll", checkInView);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      if (count < targetNumber) {
        const increment = Math.ceil(targetNumber / 10);
        const timeout = setTimeout(() => {
          setCount((prevCount) =>
            Math.min(prevCount + increment, targetNumber),
          );
        }, 100);

        return () => clearTimeout(timeout);
      }
    }
  }, [count, targetNumber, isInView]);

  return (
    <Typography
      tag="span"
      className="wb-text-4xl sm:wb-text-5xl md:wb-text-6xl wb-font-semibold wb-text-primary-dark-200 wb-flex wb-items-baseline wb-mb-1 sm:wb-tracking-wider"
    >
      {count}
      <span
        className="wb-text-primary-light-200 wb-text-5xl sm:wb-text-6xl md:wb-text-7xl"
        ref={eleRef}
      >
        +
      </span>
    </Typography>
  );
};
