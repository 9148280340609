"use client";
import Image from "next/image";
import { useState } from "react";
import { cn } from "@webelight-site/lib/utils";
import { Typography } from "@libs/src/components/Typography";
import discoveryAndPlanning from "@webelight-site/public/img/homepage/stagesOfPlc/discoveryAndPlanning.svg";
import deploymentAndImplementation from "@webelight-site/public/img/homepage/stagesOfPlc/deploymentAndImplementation.svg";
import designAndPrototyping from "@webelight-site/public/img/homepage/stagesOfPlc/designAndPrototyping.svg";
import developmentAndCoding from "@webelight-site/public/img/homepage/stagesOfPlc/developmentAndCoding.svg";
import testingAndQualityAssurance from "@webelight-site/public/img/homepage/stagesOfPlc/testingAndQualityAssurance.svg";
import "./style.css";

const developmentStages = [
  {
    id: 1,
    name: "Discovery and Planning",
    description:
      "Define objectives, and vision for the project with detailed  requirement gathering and brainstorming sessions to define project scope, goals, and timelines.",
    image: discoveryAndPlanning,
    zIndex: 2,
  },
  {
    id: 2,
    name: "Design and Prototyping",
    description:
      "Create interactive prototypes involving wireframing, user interface design, and prototyping to visualize the end product and gather feedback from stakeholders.",
    image: designAndPrototyping,
    zIndex: 3,
  },
  {
    id: 3,
    name: "Development and Coding",
    description:
      "Translate the design into functional software using the latest technologies & best practices to build a scalable, robust, and high-performance solution.",
    image: developmentAndCoding,
    zIndex: 4,
  },
  {
    id: 4,
    name: "Testing and Quality Assurance",
    description:
      "Conduct various tests, including functional, performance, and user acceptance, to ensure that the software functions flawlessly across different environments.",
    image: testingAndQualityAssurance,
    zIndex: 6,
  },
  {
    id: 5,
    name: "Deployment and Implementation",
    description:
      "Ensure a smooth transition to production environment and monitor the performance of the software post-deployment to address any issues or concerns.",
    image: deploymentAndImplementation,
    zIndex: 7,
  },
];

export const StagesCardStack = () => {
  const [techId, setTechId] = useState(0);

  return (
    <div className="wb-w-full wb-max-w-[1177px] wb-flex wb-items-center wb-justify-start wb-flex-col lg:wb-flex-row wb-rounded-3xl wb-border wb-border-gray-600 wb-overflow-hidden">
      {developmentStages.map(({ id, name, description, image }, index) => (
        <div
          key={id}
          className="wb-w-full lg:wb-w-max lg:wb-h-[720px] wb-flex wb-flex-col lg:wb-flex-row wb-items-center wb-justify-start"
        >
          <button
            className="wb-w-full lg:wb-w-[84px] wb-h-[84px] lg:wb-h-full wb-px-3 lg:wb-px-6 wb-py-4 lg:wb-py-8 wb-flex wb-flex-row lg:wb-flex-col wb-items-center wb-justify-start lg:wb-justify-between wb-gap-6 wb-text-2xl wb-font-medium wb-leading-9 wb-text-light-100"
            onClick={() => setTechId(index)}
          >
            <span>0{index + 1}</span>
            <span className="vertical-text wb-text-start lg:wb-bottom-[287px]">
              {name}
            </span>
          </button>
          <div
            className={cn("shrink-card", {
              "card-open": techId === index,
              "card-close": techId !== index,
              "!wb-border-b-0 !lg:wb-border-r-0":
                index === developmentStages.length - 1,
            })}
          >
            <div className="wb-flex wb-flex-col wb-items-start wb-justify-start md:wb-w-[544px] wb-gap-6">
              <Typography
                tag="h6"
                className="!wb-my-0 wb-font-medium wb-text-3xl wb-leading-[38px] wb-text-white"
              >
                {name}
              </Typography>
              <Typography
                tag="p"
                className="!wb-my-0 wb-text-primary-grey wb-text-base wb-leading-6 wb-font-normal"
              >
                {description}
              </Typography>
            </div>
            <div className="wb-w-full lg:wb-w-[660px] lg:wb-h-[410px] wb-rounded-3xl wb-overflow-hidden">
              <Image
                src={image}
                alt={name}
                width={660}
                height={410}
                className="wb-w-full wb-h-full wb-object-cover"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
