import { Typography } from "@libs/src/components/Typography";
import React from "react";

export const ErrorMessage = ({
  message,
  className = "",
}: {
  message?: string;
  className?: string;
}) => {
  if (!message) return null;

  return (
    <Typography
      tag="p"
      className={`wb-text-red-500 wb-text-sm wb-h-[20px] ${className}`}
    >
      {message}
    </Typography>
  );
};
