"use client";
import Image from "next/image";
import { cn } from "@webelight-site/lib/utils";

export const AccordionCard = ({
  title,
  children,
  isOpen,
  onToggle,
}: {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  return (
    <div
      className={cn(
        "wb-h-[auto] wb-shadow-custom-card wb-overflow-hidden wb-rounded-lg wb-w-full wb-gap-3 wb-bg-[#14273D]",
      )}
    >
      <button
        onClick={onToggle}
        className="wb-w-full wb-cursor-default wb-px-6 wb-p-[25px] wb-flex wb-justify-between wb-items-center wb-gap-6 focus:wb-outline-none"
      >
        <div className="wb-flex wb-items-center wb-gap-2">
          {/* <div className="wb-relative">
            <span>
              <Image
                src="/img/faq-section/Vector_circle-white.svg"
                alt="circle"
                width={20}
                height={20}
                className="!wb-max-w-max"
              />
              <Image
                src="/img/faq-section/Vector-question-white.svg"
                alt="question"
                width={7.87}
                height={12}
                className="wb-absolute wb-top-[5px] wb-bottom-[6px] wb-left-[6px]"
              />
            </span>
          </div> */}
          <span className="wb-text-lg wb-text-white wb-font-medium wb-text-start wb-leading-[22.5px]">
            {title}
          </span>
        </div>
        <div className="wb-cursor-pointer">
          <Image
            src={
              isOpen
                ? "/img/faq-section/minimize-white.svg"
                : "/img/faq-section/expand-white.svg"
            }
            alt={isOpen ? "minimize" : "expand"}
            width={24}
            height={24}
            className="wb-max-w-none"
          />
        </div>
      </button>
      <div
        className={cn(
          "wb-px-6 wb-overflow-hidden wb-transition-all wb-duration-700",
          {
            "wb-max-h-0": !isOpen,
            "wb-max-h-96": isOpen,
          },
        )}
      >
        <div className="wb-pb-4 wb-flex wb-gap-2 wb-h-max">
          {/* <Image
            src="/img/faq-section/Arrow-Right-white.svg"
            alt="Right_arrow"
            width={16}
            height={16}
            className="wb-h-4"
          /> */}
          <span className="wb-text-[#E5E7EB]">{children}</span>
        </div>
      </div>
    </div>
  );
};
