import { v4 as uuidv4 } from "uuid";
import { routes } from "./routes";
import { IndustriesNames } from "@webelight-site/modules/industries/constant";
import TopClutch from "@webelight-site/public/img/Footer/top_clutch_2024.svg";

export const verySmallWidth = 27;
export const extraSmallWidth = 30;
export const smallWidth = 63;
export const mSmallWidth = 70;
export const extraNormalWidth = 80;
export const normalWidth = 100;
export const extraMediumWidth = 145;
export const mediumWidth = 200;
export const largeWidth = 288;
export const mLargeWidth = 347;
export const extraLargeWidth = 340;
export const exLargeWidth = 361;
export const blogImageWidth = 392;
export const xxlWidth = 598;
export const verySmallHeight = 27;
export const extraSmallHeight = 30;
export const mSmallHeight = 60;
export const smallHeight = 63;
export const normalHeight = 80;
export const xNormalHeight = 100;
export const extraMediumHeight = 145;
export const mHeight = 200;
export const blogImageHeight = 204;
export const mediumHeight = 216;
export const largeHeight = 242;
export const mLargeHeight = 245;
export const mlLargeHeight = 347;
export const extraLargeHeight = 340;
export const exLargeHeight = 362;
export const xxlHeight = 485;
export const imageQuality = 100;
export const iconWidth = 27;
export const iconHeight = 27;
export const checkMarkImageWidth = 30;
export const checkMarkImageHeight = 30;
export const angleDownWidth = 50;
export const angleDownHeight = 50;
export const servicesImageWidth = 250;
export const servicesImageHeight = 380;
export const smallImageWidth = 70;
export const smallImageHeight = 60;
export const revalidate = {
  blogList: 600,
  blogPost: 3600,
};

export const webelightAddressMap = "https://maps.app.goo.gl/ndnxU8v1duo1NF7u7";
export const mailtoSalesLink = "mailto:sales@webelight.co.in";
export const mailtoCareerLink = "mailto:career@webelight.co.in";

export const smallScreen = 767;
export const mediumScreen = 768;
export const largeScreen = 1024;

export const aiMlSlug =
  "top-9-technology-trends-of-2024-from-generative-ai-to-sustainable-technology";

export const oembedUrlMatch = /url="([^"]*)"/;
export const oembedUrlPattern = "watch?v=";
export const replacementOfOembedUrlPattern = "embed/";

export const companyWeWorkedForSwiperBreakpoint = {
  320: {
    slidesPerView: 1,
  },
  740: {
    slidesPerView: 2,
  },
  1000: {
    slidesPerView: 3,
  },
  1100: {
    slidesPerView: 3,
  },
  1200: {
    slidesPerView: 3,
  },
  1300: {
    slidesPerView: 3,
  },
};

export const topServicesHomeSwiperBreakpoints = {
  320: {
    slidesPerView: 1,
  },
  740: {
    slidesPerView: 2,
    spaceBetween: "20",
  },
  1000: {
    slidesPerView: 2,
    spaceBetween: "30",
  },
  1100: {
    slidesPerView: 2,
  },
  1200: {
    slidesPerView: 2,
    spaceBetween: "18px",
  },
  1300: {
    slidesPerView: 2,
  },
};

export const blogs = [
  {
    id: "1",
    title: "7 Useful AI Tools for Developers in 2024.",
    category: "Boost Productivity",
    img: "/img/homepage/blog/ai-tools-for-developers.png",
  },
  {
    id: "2",
    title: "blog-2",
    category: "",
  },
  {
    id: "3",
    title: "blog-3",
    category: "",
  },
];

export const NAV_LINKS = {
  ABOUT: "/about-us",
};

export const PAGE_LINKS = [
  { id: uuidv4(), title: "Home", url: "/" },
  { id: uuidv4(), title: "About Us", url: "/about-us" },
  { id: uuidv4(), title: "Sitemap", url: "/sitemap.xml" },
  { id: uuidv4(), title: "Contact Us", url: "/contact-us" },
];

const industriesRoutes = Object.keys(IndustriesNames).map(
  (key) => `industries/${key}`,
);

export const staticSitemapSubRoutes = [
  "",
  "about-us",
  "contact-us",
  "career",
  "services",
  "blog",
  "portfolio",
  "industries",
  ...industriesRoutes,
  "services/discovery-phase-services",
  "services/advanced-automation",
  "services/blockchain",
  "services/cloud-computing-and-security",
  "services/custom-software-development",
  "services/data-and-artificial-intelligence",
  "services/digital-marketing-strategy",
  "services/mobile-app-development",
  "services/ui-ux-design",
];

export const portfolioProjectDetails = [
  {
    id: uuidv4(),
    title: "Client",
    description: "theme",
  },
  {
    id: uuidv4(),
    title: "Date",
    description: "January 2024",
  },
  {
    id: uuidv4(),
    title: "Skills",
    url: "design,brand,website",
  },
  {
    id: uuidv4(),
    title: "Project URL",
    url: "theme",
  },
];

export const disallowedKeysForNumberField = [
  "e",
  "E",
  ".",
  "-",
  "+",
  "Space",
  "ArrowUp",
  "ArrowDown",
];
export const mobileMenuItems = [
  {
    id: 1,
    title: "Home",
    path: routes.HOME,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 2,
    title: "About Us",
    path: routes.ABOUT_US,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 3,
    title: "Services",
    path: routes.SERVICES,
    hasSubMenu: true,
    isVisible: true,
  },
  {
    id: 4,
    title: "Industries",
    path: routes.FINTECH_SOFTWARE_DEVELOPER,
    hasSubMenu: false,
    isVisible: true,
    hasIndustryMenu: true,
  },
  //need this code in future
  // {
  //   id: 5,
  //   title: "Hire",
  //   path: routes.HIRE_FULL_STACK_DEVELOPER,
  //   hasSubMenu: false,
  //   isVisible: true,
  //   hasHireDevMenu: true,
  // },
  {
    id: 6,
    title: "Portfolio",
    path: routes.PORTFOLIO,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 7,
    title: "Careers",
    path: routes.CAREER,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 8,
    title: "Blog",
    path: routes.BLOGS,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 9,
    title: "Contact Us",
    path: routes.CONTACT_US,
    hasSubMenu: false,
    isVisible: false,
  },
];

export const badges = [
  {
    id: 1,
    badgeLink: "https://businessfirms.co/company/webelight-solutions",
    badgeSrc: "/img/Footer/business-firms-certified.png",
  },
  {
    id: 2,
    badgeLink: "https://clutch.co/profile/webelight-solutions#highlights",
    badgeSrc: TopClutch,
  },
  {
    id: 3,
    badgeLink: "https://www.designrush.com/agency/profile/webelight-solutions",
    badgeSrc: "/img/Footer/Verified-Agency.png",
  },
  {
    id: 4,
    badgeLink: "https://www.topdevelopers.co/profile/webelight-solutions",
    badgeSrc: "/img/Footer/top-e-commerce.png",
  },
];
