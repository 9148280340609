"use client";
import { Typography } from "@libs/src/components/Typography";
import { useState } from "react";
import angleDownBlue from "@webelight-site/assets/angle-down-primary.svg";
import Image from "next/image";
import { cn } from "@webelight-site/lib/utils";

import "./style.css";

type ExpectationItem = {
  id: string;
  text: string;
  description?: string;
};

type ExpectationCardProps = {
  title: string;
  items: ExpectationItem[];
  defaultOpenIndex?: number | null;
};

const ExpectationCard = ({
  title,
  items,
  defaultOpenIndex = null,
}: ExpectationCardProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(
    defaultOpenIndex,
  );

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="wb-flex wb-flex-col wb-max-w-[493px] wb-w-full wb-h-fit">
      <div className="wb-flex wb-flex-col wb-grow wb-w-full wb-rounded-lg wb-border-[1.5px] wb-border-primary-light-100 wb-bg-primary-dark-transparent">
        <div className="wb-bg-primary-light-100 wb-rounded-lg">
          <Typography
            tag="h3"
            className="wb-text-white wb-p-6 wb-text-[24px] wb-font-semibold wb-leading-[30px] wb-custom-title-text"
          >
            {title}
          </Typography>
        </div>
        <div className="wb-flex wb-flex-col wb-justify-center wb-px-[30px] wb-gap-[28px] wb-py-[30px]">
          {items.map((item, index) => (
            <div key={item.id} className="">
              <button
                className="wb-w-full wb-flex wb-items-center wb-justify-between  wb-gap-2.5 wb-max-md:flex-wrap wb-text-white"
                onClick={() => handleToggle(index)}
              >
                <Typography
                  tag="h4"
                  className="wb-w-max wb-text-white wb-font-medium wb-text-[20px] wb-leading-[25px]"
                >
                  {item.text}
                </Typography>
                <Image
                  src={angleDownBlue}
                  alt="angle-down-icon"
                  className={cn("wb-transition-all wb-duration-500", {
                    "wb-rotate-180": activeIndex === index,
                    "wb-rotate-0": activeIndex !== index,
                  })}
                />
              </button>
              {item.description && (
                <div
                  className={cn(
                    "wb-overflow-hidden wb-transition-all wb-duration-500",
                    {
                      "wb-h-[82px] wb-opacity-100": activeIndex === index,
                      "wb-h-0 wb-opacity-0": activeIndex !== index,
                    },
                  )}
                >
                  <Typography
                    tag="p"
                    className="wb-mt-2.5 wb-font-normal wb-text-base wb-leading-6 wb-text-gray-300 wb-max-md:max-w-full"
                  >
                    {item.description}
                  </Typography>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpectationCard;
