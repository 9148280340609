"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import BlogCard from "./Blogs/BlogCard";
import { REMOVE_HTML_TAG_REGEX } from "@webelight-site/utils/regex";
import { textTruncate } from "@webelight-site/lib/utils";
import "apps/webelight-site/modules/homepage/components/styles/blogSwiper.scss";

interface BlogPost {
  id: number;
  attributes: {
    title: string;
    content: string;
    type: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    slug: string;
    image: AnyType;
  };
}

interface BlogSwiperProps {
  blogs: BlogPost[];
}

const pagination = {
  clickable: true,
  renderBullet(index: number, className: string) {
    return '<span class="' + className + ' custom-bullet"></span>';
  },
};

const BlogSwiper = ({ blogs }: BlogSwiperProps) => {
  return (
    <div className="blog-swiper-container">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 2500,
        }}
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="service-swiper"
      >
        {blogs.map(
          ({ attributes: { slug, title, content, image, publishedAt } }) => {
            const description = content.replace(REMOVE_HTML_TAG_REGEX, "");
            const shortDescription = textTruncate(description, 180);

            return (
              <SwiperSlide key={slug}>
                <div className="flex-center wb-max-h-[480px] wb-h-full">
                  <BlogCard
                    imageSrc={image.data.attributes.url}
                    title={title}
                    description={shortDescription}
                    date={publishedAt}
                    url={slug}
                  />
                </div>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </div>
  );
};

export default BlogSwiper;
