"use client";

import { useState } from "react";
import { AccordionCard } from "@webelight-site/lib/ui/components/AccordionCard";

interface Props {
  question: string;
  answer: string;
}

export const FAQSliders = ({ question, answer }: Props) => {
  const [openAccordionId, setOpenAccordionId] = useState<boolean>(false);
  const handleToggle = () => {
    setOpenAccordionId((prev) => !prev);
  };

  return (
    <AccordionCard
      title={question}
      isOpen={openAccordionId}
      onToggle={handleToggle}
    >
      {answer}
    </AccordionCard>
  );
};
