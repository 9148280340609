"use client";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay, Pagination } from "swiper/modules";
import { heroSectionImages } from "@webelight-site/modules/homepage/components/HeroSection/constant";
import "swiper/css";
import "swiper/css/pagination";

type HeroSectionImageSliderProps = {
  onChange: (index: number) => void;
};

export const HeroSectionImageSlider = ({
  onChange,
}: HeroSectionImageSliderProps) => {
  return (
    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      loop
      modules={[Mousewheel, Autoplay, Pagination]}
      direction="horizontal"
      onSlideChange={(swiper) => onChange(swiper.realIndex)}
      className="wb-flex wb-items-center"
    >
      {heroSectionImages.map((image) => (
        <SwiperSlide key={image.alt}>
          <Image
            src={image.src}
            alt={image.alt}
            width={700}
            height={580}
            loading="eager"
            className="wb-select-none"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
