import { v4 as uuidv4 } from "uuid";
import { routes } from "../../utils/routes";

import financialSoftwareDevelopment from "@webelight-site/public/img/industries/fintech/services/1Financial.webp";
import mobileBanking from "@webelight-site/public/img/industries/fintech/services/2mobilebanking.webp";
import wealthManagement from "@webelight-site/public/img/industries/fintech/services/3wealthmanagement.webp";
import crowdFunding from "@webelight-site/public/img/industries/fintech/services/4crowdfunding.webp";
import digitalWallet from "@webelight-site/public/img/industries/fintech/services/5digitalwallet.webp";
import mobilePayment from "@webelight-site/public/img/industries/fintech/services/6mobilepayment.webp";
import investment from "@webelight-site/public/img/industries/fintech/services/7Investment.webp";
import paymentGatewayDevelopment from "@webelight-site/public/img/industries/fintech/services/8PaymentGatewayDevelopment.webp";
import openBankingPlatformDevelopment from "@webelight-site/public/img/industries/fintech/services/9openbanking.webp";

import dedicatedFintech from "@webelight-site/public/img/industries/fintech/reasons/1dedicatedfintech.svg";
import productUiUx from "@webelight-site/public/img/industries/fintech/reasons/2productuiux.svg";
import devops from "@webelight-site/public/img/industries/fintech/reasons/3devops.svg";
import quality from "@webelight-site/public/img/industries/fintech/reasons/4qualitya.svg";
import security from "@webelight-site/public/img/industries/fintech/reasons/5security.svg";
import agileAppeoach from "@webelight-site/public/img/industries/fintech/reasons/6agileapproach.svg";

import multiUser from "@webelight-site/public/img/industries/fintech/appIntigration/1multi-user.svg";
import crossPlatformIndustries from "@webelight-site/public/img/industries/fintech/appIntigration/2crossplatform.svg";
import paymentGateway from "@webelight-site/public/img/industries/fintech/appIntigration/3paymentg.svg";
import loanTax from "@webelight-site/public/img/industries/fintech/appIntigration/4loantax.svg";
import customerData from "@webelight-site/public/img/industries/fintech/appIntigration/5customer.svg";
import multiAuthentication from "@webelight-site/public/img/industries/fintech/appIntigration/6multi-authenti.svg";
import dataAnalytics from "@webelight-site/public/img/industries/fintech/appIntigration/7dataanalytics.svg";
import customReports from "@webelight-site/public/img/industries/fintech/appIntigration/8customreports.svg";

import crossPlatformFunctionality from "@webelight-site/public/img/industries/healthcare/appIntigration/1crosspl.svg";
import paymentGatewayIntegration from "@webelight-site/public/img/industries/healthcare/appIntigration/2payment.svg";
import googleAndAppleHealthIntegration from "@webelight-site/public/img/industries/healthcare/appIntigration/3google.svg";
import multiAuthenticationSystem from "@webelight-site/public/img/industries/healthcare/appIntigration/4multi-au.svg";
import dataAnalyticsService from "@webelight-site/public/img/industries/healthcare/appIntigration/5dataanalytics.svg";
import onlineAppointmentScheduling from "@webelight-site/public/img/industries/healthcare/appIntigration/6onlineapp.svg";
import ehrIntegrations from "@webelight-site/public/img/industries/healthcare/appIntigration/7ehrinteg.svg";
import cmsAndReporting from "@webelight-site/public/img/industries/healthcare/appIntigration/8cms.svg";

import emrAndEhrService from "@webelight-site/public/img/industries/healthcare/services/1EMRandEHRSoftware.webp";
import customeMedicalSoftware from "@webelight-site/public/img/industries/healthcare/services/2CustomMedical.webp";
import customeMHealthApp from "@webelight-site/public/img/industries/healthcare/services/3CustomHealthApp.webp";
import remotePatientMonitoring from "@webelight-site/public/img/industries/healthcare/services/4RemotePatientMonitoring.webp";
import telemedicineSoftware from "@webelight-site/public/img/industries/healthcare/services/5TelemedicineSoftware.webp";
import healthcareDataAnalytics from "@webelight-site/public/img/industries/healthcare/services/6HealthcareDataAnalytics.webp";
import patientPortalDevelopment from "@webelight-site/public/img/industries/healthcare/services/7PatientPortal.webp";
import fHIRIntegration from "@webelight-site/public/img/industries/healthcare/services/8FHIRIntegration.webp";
import pharmacy from "@webelight-site/public/img/industries/healthcare/services/9Pharmacy.webp";

import dedicatedHealthcare from "@webelight-site/public/img/industries/healthcare/reasons/1DedicatedHealthcare.svg";
import securityAndComplience from "@webelight-site/public/img/industries/healthcare/reasons/2Security.svg";
import agileDevelopment from "@webelight-site/public/img/industries/healthcare/reasons/3Agiledev.svg";
import advancedTech from "@webelight-site/public/img/industries/healthcare/reasons/4Advanced.svg";
import uiUxDesign from "@webelight-site/public/img/industries/healthcare/reasons/5uiux.svg";
import qualityAssurance from "@webelight-site/public/img/industries/healthcare/reasons/6QualityAssurance.svg";

import multiSiteDevelopment from "@webelight-site/public/img/industries/ecommerce/services/1Multisite.svg";
import crmErpSolution from "@webelight-site/public/img/industries/ecommerce/services/2erp.svg";
import aiPowerdDevelopment from "@webelight-site/public/img/industries/ecommerce/services/3ai.svg";
import supplyChainManagement from "@webelight-site/public/img/industries/ecommerce/services/4Supply.svg";
import customEcommerceShopping from "@webelight-site/public/img/industries/ecommerce/services/5Custom.svg";
import paymentGatewayIntegrationDevelopment from "@webelight-site/public/img/industries/ecommerce/services/6payment.svg";
import retailSolutionsDevelopment from "@webelight-site/public/img/industries/ecommerce/services/7vr.svg";
import customAPIDevelopment from "@webelight-site/public/img/industries/ecommerce/services/8api.svg";

import expertData from "@webelight-site/public/img/industries/ecommerce/reasons/1expertdata.svg";
import aiDriven from "@webelight-site/public/img/industries/ecommerce/reasons/2aidriven.svg";
import iotPowered from "@webelight-site/public/img/industries/ecommerce/reasons/3iotpowered.svg";
import efficiency from "@webelight-site/public/img/industries/ecommerce/reasons/4efficiency.svg";
import blackChainIntigration from "@webelight-site/public/img/industries/ecommerce/reasons/5blockchain.svg";

import proficientDeveloper from "@webelight-site/public/img/industries/travel/reasons/1proficient.svg";
import blackChainIntigrationReason from "@webelight-site/public/img/industries/travel/reasons/2Blockchain.svg";
import aiIntegration from "@webelight-site/public/img/industries/travel/reasons/3aiintegration.svg";
import iot from "@webelight-site/public/img/industries/travel/reasons/4iot.svg";
import budgetFriendaly from "@webelight-site/public/img/industries/travel/reasons/5budget.svg";
import userCentric from "@webelight-site/public/img/industries/travel/reasons/6usercentic.svg";

import multiplePaymentGateway from "@webelight-site/public/img/industries/travel/appIntigration/1multiplepay.svg";
import goeLocationMapping from "@webelight-site/public/img/industries/travel/appIntigration/2geolocation.svg";
import customerRealtionship from "@webelight-site/public/img/industries/travel/appIntigration/3crm.svg";
import currencyConversion from "@webelight-site/public/img/industries/travel/appIntigration/4currency.svg";
import virtualTourGuide from "@webelight-site/public/img/industries/travel/appIntigration/5virtualtour.svg";
import analyticsReporting from "@webelight-site/public/img/industries/travel/appIntigration/6analytics.svg";
import blockChainIntigration from "@webelight-site/public/img/industries/travel/appIntigration/7blockchain.svg";
import persinalization from "@webelight-site/public/img/industries/travel/appIntigration/8personalization.svg";

import travelPortalDevelopment from "@webelight-site/public/img/industries/travel/services/1TravelPortalDevelopment.webp";
import ticketingAndBookingSolutions from "@webelight-site/public/img/industries/travel/services/2TicketingAndBookingSolutions.webp";
import gdsApiIntegration from "@webelight-site/public/img/industries/travel/services/3GDSAPIntegration.webp";
import restaurantPos from "@webelight-site/public/img/industries/travel/services/4RestaurantPOS-1.webp";
import revenueCollectionAndTrackingSystem from "@webelight-site/public/img/industries/travel/services/5TrackingSystem.webp";
import travelExpenseManagement from "@webelight-site/public/img/industries/travel/services/6TravelExpenseManagement.webp";

import fintechTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/1buidfinancial.webp";
import healthCareTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/2HealthcareIndustry.webp";
import ecommerceTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/3Retail&E-Commerce.webp";
import travelTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/4tobuildfinacial.webp";
import realEtateTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/real-estate-industry.webp";
import energyTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/energyUtility.webp";
import logisticsTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/logistic-image.webp";
import elearningTitleImage from "@webelight-site/public/img/industries/fintech/financialIndustry/elearning-image.webp";

import productInformationManagement from "@webelight-site/public/img/industries/ecommerce/appIntigration/1productinfo.svg";
import inventoryManagementSystem from "@webelight-site/public/img/industries/ecommerce/appIntigration/2inventorymanagement.svg";
import orderManagementSystem from "@webelight-site/public/img/industries/ecommerce/appIntigration/3ordermanagement.svg";
import contentManagementSystem from "@webelight-site/public/img/industries/ecommerce/appIntigration/4content.svg";
import marketingAutomationTools from "@webelight-site/public/img/industries/ecommerce/appIntigration/5marketing.svg";
import mobileCommerceOptimization from "@webelight-site/public/img/industries/ecommerce/appIntigration/6mobilecommerce.svg";
import securePaymentProcessing from "@webelight-site/public/img/industries/ecommerce/appIntigration/7securepayment.svg";
import returnsExchangeManagementSystem from "@webelight-site/public/img/industries/ecommerce/appIntigration/8returnsexchanges.svg";

import propertyMarketplace from "@webelight-site/public/img/industries/realEstate/services/1PropertyMarketplace.webp";
import propertyManagement from "@webelight-site/public/img/industries/realEstate/services/2PropertyManagement.webp";
import customCRMs from "@webelight-site/public/img/industries/realEstate/services/3CustomCRMs.webp";

import deepIndustryExpertise from "@webelight-site/public/img/industries/realEstate/reasons/1deepindustry.svg";
import customTailoredSolutions from "@webelight-site/public/img/industries/realEstate/reasons/2customtailor.svg";
import devOps from "@webelight-site/public/img/industries/realEstate/reasons/3devops.svg";
import cuttingEdgeTechnology from "@webelight-site/public/img/industries/realEstate/reasons/4cuttingedge.svg";
import dedicatedProjectManagement from "@webelight-site/public/img/industries/realEstate/reasons/5dedicated.svg";
import realEstayeAgileDevelopment from "@webelight-site/public/img/industries/realEstate/reasons/6agiledevelop.svg";

import realCrossPlatformFunctionality from "@webelight-site/public/img/industries/realEstate/appIntigration/1crossplatform.svg";
import propertyPortal from "@webelight-site/public/img/industries/realEstate/appIntigration/2property.svg";
import liveChat from "@webelight-site/public/img/industries/realEstate/appIntigration/3livechat.svg";
import leadGeneration from "@webelight-site/public/img/industries/realEstate/appIntigration/4leadgeneration.svg";
import securePaymentGateways from "@webelight-site/public/img/industries/realEstate/appIntigration/5securepayment.svg";
import internetDataExchange from "@webelight-site/public/img/industries/realEstate/appIntigration/6internetdata.svg";
import crmIntegrations from "@webelight-site/public/img/industries/realEstate/appIntigration/7crminteg.svg";
import projectManagement from "@webelight-site/public/img/industries/realEstate/appIntigration/8projectmana.svg";

import talentedSoftwareEngineers from "@webelight-site/public/img/industries/energyUtility/reasons/1talentedsof.svg";
import dataMachineLearning from "@webelight-site/public/img/industries/energyUtility/reasons/2data&machine.svg";
import cloudAndDevOps from "@webelight-site/public/img/industries/energyUtility/reasons/3cloud&devops.svg";
import embeddedEngineering from "@webelight-site/public/img/industries/energyUtility/reasons/4embedded.svg";
import regulatoryCompliance from "@webelight-site/public/img/industries/energyUtility/reasons/5regulatory.svg";
import customerCentricCulture from "@webelight-site/public/img/industries/energyUtility/reasons/6customer.svg";

import meterDataManagementSystems from "@webelight-site/public/img/industries/energyUtility/appIntigration/1meterdata.svg";
import geographicInformationSystems from "@webelight-site/public/img/industries/energyUtility/appIntigration/2geographic.svg";
import advancedDistributionManagement from "@webelight-site/public/img/industries/energyUtility/appIntigration/3advanced.svg";
import assetPerformanceManagement from "@webelight-site/public/img/industries/energyUtility/appIntigration/4assetperfo.svg";
import enterpriseResourcePlanning from "@webelight-site/public/img/industries/energyUtility/appIntigration/5enterprise.svg";
import renewableEnergyMonitoring from "@webelight-site/public/img/industries/energyUtility/appIntigration/6renewable.svg";
import supervisoryControlDataAcquisition from "@webelight-site/public/img/industries/energyUtility/appIntigration/7supervisory.svg";
import energyTradingRiskManagement from "@webelight-site/public/img/industries/energyUtility/appIntigration/8energytrading.svg";

import utilityTrackingSoftwareDevelopment from "@webelight-site/public/img/industries/energyUtility/services/2utility.webp";
import nuclearAnalysisSoftwareDevelopment from "@webelight-site/public/img/industries/energyUtility/services/3nuclear.webp";
import renewableEnergyManagementSystems from "@webelight-site/public/img/industries/energyUtility/services/4RenewableEnergy.webp";
import gridOptimizationSystems from "@webelight-site/public/img/industries/energyUtility/services/5GridOptimization.webp";
import meterReadingSoftwareDevelopment from "@webelight-site/public/img/industries/energyUtility/services/6MeterReadingSoftware.webp";
import powerPlantDesignSoftwareDevelopment from "@webelight-site/public/img/industries/energyUtility/services/7PowerPlantDesign.webp";
import scadaSystems from "@webelight-site/public/img/industries/energyUtility/services/8SCADA.webp";
import advancedEnergyDataAnalytics from "@webelight-site/public/img/industries/energyUtility/services/9EnergyDataAnalytics.webp";
import solarManagementSystem from "@webelight-site/public/img/industries/energyUtility/services/10Solar Management.webp";

import ecomServiceOne from "@webelight-site/public/img/industries/ecommerce/services/service-1.webp";
import ecomServiceTwo from "@webelight-site/public/img/industries/ecommerce/services/service-2.webp";
import logisticServiceOne from "@webelight-site/public/img/industries/logistics/services/service1.webp";
import logisticServiceTwo from "@webelight-site/public/img/industries/logistics/services/services2.webp";

import ecomTextRoundImage from "@webelight-site/public/img/industries/ecommerce/services/ecommerceCirle.svg";
import logisticsTextRoundImage from "@webelight-site/public/img/industries/logistics/services/logisticsServices.svg";

import dedicatedLogisticsEngineers from "@webelight-site/public/img/industries/logistics/reasons/1logistics.svg";
import impenetrableSecurityDataProtection from "@webelight-site/public/img/industries/logistics/reasons/2security_1.svg";
import logisticsDevOps from "@webelight-site/public/img/industries/logistics/reasons/3devops_1.svg";
import logisticsQualityAssurance from "@webelight-site/public/img/industries/logistics/reasons/4qa.svg";
import logisticsUiUxDesign from "@webelight-site/public/img/industries/logistics/reasons/5uiux.svg";
import logisticsAgileApproach from "@webelight-site/public/img/industries/logistics/reasons/6agile.svg";

import realTimeInventory from "@webelight-site/public/img/industries/logistics/appIntigration/1realtime.svg";
import routeTracker from "@webelight-site/public/img/industries/logistics/appIntigration/2tracker.svg";
import customerSupport from "@webelight-site/public/img/industries/logistics/appIntigration/3customer.svg";
import shippingAndTracking from "@webelight-site/public/img/industries/logistics/appIntigration/4shipping.svg";
import predictiveAnalytics from "@webelight-site/public/img/industries/logistics/appIntigration/5predicative.svg";
import multiplePaymentOptions from "@webelight-site/public/img/industries/logistics/appIntigration/6payment.svg";
import automatedInvoicingFeatures from "@webelight-site/public/img/industries/logistics/appIntigration/7automated.svg";
import driverLog from "@webelight-site/public/img/industries/logistics/appIntigration/8log.svg";

import warehouseManagementSystem from "@webelight-site/public/img/industries/logistics/services/1warehouse.svg";
import erpAndCrm from "@webelight-site/public/img/industries/logistics/services/2erp.svg";
import transportationManagementSystem from "@webelight-site/public/img/industries/logistics/services/3transportion.svg";
import fleetManagementSystem from "@webelight-site/public/img/industries/logistics/services/4fleet.svg";
import assetTrackingSoftware from "@webelight-site/public/img/industries/logistics/services/5AssetTracking.svg";
import parkingManagementSoftware from "@webelight-site/public/img/industries/logistics/services/6parking.svg";
import telematicsSoftwareDevelopment from "@webelight-site/public/img/industries/logistics/services/7telematics.svg";
import routePlanningTracking from "@webelight-site/public/img/industries/logistics/services/8route.svg";

import schoolManagementSystem from "@webelight-site/public/img/industries/eLearning/services/1SchoolManagement.webp";
import learningManagementSystem from "@webelight-site/public/img/industries/eLearning/services/2LearningManagement.webp";
import educationalGameDevelopment from "@webelight-site/public/img/industries/eLearning/services/3EducationalGame.webp";
import skillBoostingAppDevelopmentsolutions from "@webelight-site/public/img/industries/eLearning/services/4SkillBoostingApp.webp";
import corporateTrainingApps from "@webelight-site/public/img/industries/eLearning/services/5CorporateTrainingApp.webp";

import multiLingualSupport from "@webelight-site/public/img/industries/eLearning/appIntigration/1multi.svg";
import progressTrackingDashboard from "@webelight-site/public/img/industries/eLearning/appIntigration/2progress.svg";
import chatSupport from "@webelight-site/public/img/industries/eLearning/appIntigration/3chat.svg";
import gamificationSoftware from "@webelight-site/public/img/industries/eLearning/appIntigration/4gamifi.svg";
import eLearningDataAnalytics from "@webelight-site/public/img/industries/eLearning/appIntigration/5data.svg";
import performanceTracker from "@webelight-site/public/img/industries/eLearning/appIntigration/6performance.svg";
import interactiveGraphics from "@webelight-site/public/img/industries/eLearning/appIntigration/7interactive.svg";
import aIPersonalization from "@webelight-site/public/img/industries/eLearning/appIntigration/8ai.svg";
import advancedTechnology from "@webelight-site/public/img/industries/eLearning/reasons/1advanced.svg";
import increasedCostEffectiveness from "@webelight-site/public/img/industries/eLearning/reasons/2increased.svg";
import enhancedCourseCompletionRates from "@webelight-site/public/img/industries/eLearning/reasons/3enhanced.svg";
import strategicAdvisoryInEdTech from "@webelight-site/public/img/industries/eLearning/reasons/4strategic.svg";
import leanDevelopment from "@webelight-site/public/img/industries/eLearning/reasons/5leandev.svg";
import provenTrackRecord from "@webelight-site/public/img/industries/eLearning/reasons/6proven.svg";
import projectScope from "@webelight-site/public/img/industries/fintech/costEstimation/1scopeofthe.svg";
import techonologyStack from "@webelight-site/public/img/industries/fintech/costEstimation/2technology.svg";
import customization from "@webelight-site/public/img/industries/fintech/costEstimation/3customization.svg";
import integration from "@webelight-site/public/img/industries/fintech/costEstimation/4integration.svg";
import scalingRequirements from "@webelight-site/public/img/industries/fintech/costEstimation/5scaling.svg";

export enum IndustryNames {
  finTech = "finTech",
  healthCare = "healthCare",
  eCommerce = "eCommerce",
  travel = "travel",
  realEstate = "realEstate",
  energyAndUtility = "energyAndUtility",
  logistics = "logistics",
  elearning = "elearning",
}

export enum IndustriesNames {
  "fintech-software-development" = "fintechIndustriesPage",
  "healthcare-software-development" = "healthcareIndustriesPage",
  "ecommerce-software-development" = "retailAndEcommerceIndustriesPage",
  "travel-and-hospitality-app-development" = "travelAndHospitalityIndustriesPage",
  "real-estate-software-development" = "realEstateIndustriesPage",
  "energy-and-utility-software-development" = "energyAndUtilityIndustriesPage",
  "logistics-software-development" = "logisticsIndustriesPage",
  "elearning-software-development" = "elearningIndustriesPage",
}

export const IndustryList = [
  {
    id: uuidv4(),
    title: "Fintech",
    slug: "fintech-software-development",
  },
  {
    id: uuidv4(),
    title: "Healthcare",
    slug: "healthcare-software-development",
  },
  {
    id: uuidv4(),
    title: "Retail And Ecommerce",
    slug: "ecommerce-software-development",
  },
  {
    id: uuidv4(),
    title: "Travel And Hospitality",
    slug: "travel-and-hospitality-app-development",
  },
  {
    id: uuidv4(),
    title: "Real Estate",
    slug: "real-estate-software-development",
  },
  {
    id: uuidv4(),
    title: "Energy And Utility",
    slug: "energy-and-utility-software-development",
  },
  {
    id: uuidv4(),
    title: "Logistics",
    slug: "logistics-software-development",
  },
  {
    id: uuidv4(),
    title: "E-Learning",
    slug: "elearning-software-development",
  },
];

export const industriesPageDetails = {
  fintechIndustriesPage: {
    overview: {
      title: "Fintech Software Development Company",
      description:
        "Harnessing disruptive technologies, our fintech software development company empowers financial institutions to reinvent their strategies and elevate customer engagement. We specialize in digital banking, investment solutions, insurtech, and personal finance management.",
      bannerImage: "/img/industries/fintech/fintech-banner-image.jpg",
      bannerImageClass: "fintech-image",
    },
    softwareDevelopmentServices: {
      title: "Our Full-Stack FinTech Software Development Services",
      industryType: "finTech",
      description:
        "As a premier financial software development firm, we deliver top-tier FinTech solutions that are robust, scalable, and high-performing. <br> Our range of FinTech development offerings comprises.",
      services: [
        {
          id: uuidv4(),
          title: "Financial Software Development",
          description:
            "Boasting almost a decade of expertise, our team of finance software development specialists excels in crafting distinctive and cutting-edge financial software solutions, driving digital transformation to fuel seamless business growth.",
          imagePath: financialSoftwareDevelopment,
          imageAlt: "financial-software-development",
        },
        {
          id: uuidv4(),
          title: "Mobile Banking Software Development",
          description:
            "We stand out as a unique banking software and app development firm, providing scalable custom banking software solutions tailored for investment banks worldwide. Partnering with us ensures banks a secure microservice architecture through agile development practices.",
          imagePath: mobileBanking,
          imageAlt: "mobile-banking-software-development",
        },
        {
          id: uuidv4(),
          title: "Wealth Management Software Development",
          description:
            "As a FinTech app development company, we grasp the significance of streamlined and secure wealth management. Our FinTech app developers provide your clients with a smart platform to monitor, oversee, and enhance their financial growth.",
          imagePath: wealthManagement,
          imageAlt: "wealth-management-software-development",
        },
        {
          id: uuidv4(),
          title: "Crowdfunding Platform Development",
          description:
            "Our FinTech app development offerings extend to creating scalable crowdfunding platforms tailored for fundraising, debt, and donations. Know all about the advanced features such as investment monitoring, social networking integrations, and digital document handling.",
          imagePath: crowdFunding,
          imageAlt: "crowdfunding-platform-development",
        },
        {
          id: uuidv4(),
          title: "Digital Wallet Development",
          description:
            "Banks can address the needs of today's discerning customers through a meticulously crafted wallet app. Our FinTech software developers design digital wallet applications that enable banks and financial institutions to process transactions securely, swiftly and effectively.",
          imagePath: digitalWallet,
          imageAlt: "digital-wallet-development",
        },
        {
          id: uuidv4(),
          title: "Mobile Payment App Development",
          description:
            "Creating a mobile payment app that meets the contemporary demands for security is crucial. Our finance software developers craft fully secure and encrypted mobile payment applications that facilitate smooth mobile money transfers, transactions, and rewards.",
          imagePath: mobilePayment,
          imageAlt: "mobile-payment-app-development",
        },
        {
          id: uuidv4(),
          title: "Investment Management Software Development",
          description:
            "Our FinTech software development services facilitate investment portfolios and trade order execution through predictive analytics methods. With our resilient custom investment management solutions, you can monitor your assets and identify potential business risks.",
          imagePath: investment,
          imageAlt: "investment-management-software-development",
        },
        {
          id: uuidv4(),
          title: "Payment Gateway Development",
          description:
            "We specialize in crafting dynamic payment systems that prioritize frictionless payment experiences. Our experts are proficient in integrating third-party payment gateways using pre-built APIs, ensuring customers have access to secure and convenient payment options.",
          imagePath: paymentGatewayDevelopment,
          imageAlt: "payment-gateway-development",
        },
        {
          id: uuidv4(),
          title: "Open Banking Platform Development",
          description:
            "We are an opening banking software development company that utilizes analytical intelligence and deep learning techniques to provide superior data categorization to clients for complex insights like verifications and cash flows.",
          imagePath: openBankingPlatformDevelopment,
          imageAlt: "open-banking-platform-development",
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose our fintech software development company",
      description:
        "Our fintech software solutions are meticulously crafted to meet your business needs. We blend deep industry knowledge with tech expertise from numerous fintech projects to give your business a competitive advantage in the banking and financial sectors.",
      reasons: [
        {
          id: 1,
          title: "Dedicated Fintech Engineers",
          description:
            "Our team of FinTech engineers comprises seasoned professionals with extensive expertise and senior-level experience. By hiring our FinTech developers, businesses gain access to top-tier talent with a proven track record of delivering exceptional results.",
          imagePath: dedicatedFintech,
          imageAlt: "Dedicated Fintech Engineers",
        },
        {
          id: 2,
          title: "Product UI/UX Design",
          description:
            "A thriving financial service demands an intuitive interface backed by thoughtful UI/UX design and reliable technologies that offer a personal touch. Our data visualization methods translate intricate financial data into comprehensible visuals.",
          imagePath: productUiUx,
          imageAlt: "Product UI/UX Design",
        },
        {
          id: 3,
          title: "DevOps",
          description:
            "Our DevOps team is committed to delivering software deployment solutions, whether on the cloud or on-premises. Leveraging advanced technologies like CI/CD pipelines and Kubernetes, we optimize the deployment process to guarantee seamless software delivery.",
          imagePath: devops,
          imageAlt: "DevOps",
        },
        {
          id: 4,
          title: "Quality Assurance",
          description:
            "Our all-encompassing quality assurance services span the entire software development life cycle, from initial QA assessment to performance and automated testing enabling our clients to roll out software products that meet the industry benchmarks.",
          imagePath: quality,
          imageAlt: "Quality Assurance",
        },
        {
          id: 5,
          title: "Security and Regulatory Compliance",
          description:
            "We ensure clear data ownership, establish secure architectures, and integrate strong authorization mechanisms for building secure applications. Moreover, we encrypt sensitive data, and apply a range of other data security measures to protect your information.",
          imagePath: security,
          imageAlt: "Security and Regulatory Compliance",
        },
        {
          id: 6,
          title: "Agile Approach",
          description:
            "Recognizing the significance of quick market entry, we adopt an Agile methodology for software development. Combined with our project delivery expertise and flexible team scaling, this approach accelerates the introduction of new fintech offerings.",
          imagePath: agileAppeoach,
          imageAlt: "Agile Approach",
        },
      ],
    },
    ourAppIntegrations: {
      title: "Fast and Secure Money Access with Our FinTech App Integrations",
      description:
        "Whether it's payments, invoicing, or financial reporting, our integrations enable users to manage their finances with ease, automate routine tasks and ensure real-time updates.",
      ourApps: [
        {
          id: 1,
          title: "Multi-User Login",
          imagePath: multiUser,
          imageAlt: "Multi-User Login",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Cross Platform Functionality",
          imagePath: crossPlatformIndustries,
          imageAlt: "Cross Platform Functionality",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Payment Gateway Integration",
          imagePath: paymentGateway,
          imageAlt: "Payment Gateway Integration",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Loan & Tax Calculators",
          imagePath: loanTax,
          imageAlt: "Loan & Tax Calculators",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Customer Data Management",
          imagePath: customerData,
          imageAlt: "Customer Data Management",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Multi-Authentication System",
          imagePath: multiAuthentication,
          imageAlt: "Multi-Authentication System",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "Data Analytics",
          imagePath: dataAnalytics,
          imageAlt: "Data Analytics",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "Custom Reports",
          imagePath: customReports,
          imageAlt: "Custom Reports",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title:
        "Build a robust FinTech software that works beyond your expectations",
    },
    buildFinancialIndustrySoftwareDetails: {
      title: "Advanced Technologies to Build Financial Industry Software",
      heroDescription:
        "Derive valuable insights into financial trends and customer behavior with cutting edge technological solutions and make intelligent decisions for your customers.",
      description:
        "As FinTech specialists, our developers adeptly craft custom financial software solutions leveraging cutting-edge technologies, seamlessly integrating advanced tech innovations with deep industry insights to propel business growth and meet the unique needs of our clients.",

      heroImage: fintechTitleImage,
      heroTitle: "Revolutionizing Finance",
      subTitle:
        "Harnessing Advanced Technologies For Financial Industry Software Development",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Frontend",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Backend",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Revolutionize your finances: our fintech software goes above and beyond",
      imageBackground: "fintech-bg-image",
    },
    costEstimationDetail: {
      title: "Financial Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your FinTech solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title: "What is the role of a fintech software developer?",
        description:
          "FinTech software developers create, implement, and support financial software by leveraging expertise in mobile and web development, DevOps, cloud computing, AI, IoT, and blockchain. They work in close partnership with clients to tailor solutions to their unique needs, staying updated with the latest industry trends and technological advancements.",
      },
      {
        id: 2,
        title: "How long does it take to develop a financial app?",
        description: `The time required to develop a financial app varies based on factors like the size of the development team and the complexity and number of features desired for the application.Typically, for a finance app development company, crafting a low to medium complexity app demands over 3,000 hours. For a more precise timeframe, we encourage you to <a href=${routes.INDUSTRIES_FORM}>schedule a call</a>  with our skilled team of financial software developers.`,
      },
      {
        id: 3,
        title: "What is a way for me to evaluate your level of expertise?",
        description: `To verify the proficiency and skills of our fintech developers before hiring, Relevant offers a variety of options such as test tasks, technical calls to discuss the project, or reaching out to companies highlighted in our <a href=${routes.PORTFOLIO}>portfolio</a> section.`,
      },
      {
        id: 4,
        title:
          "What categories of fintech software are within your development capabilities?",
        description: `We have extensive proficiency in fintech app development, ranging from creating streamlined payroll systems and wealth management applications to designing ERP software equipped with robust Big Data analytics features. Our expertise also encompasses crafting diverse fintech solutions including mobile payment apps, invoicing software, banking platforms, document management systems, project management tools, and tax preparation applications.`,
      },
      {
        id: 5,
        title:
          "Will a dedicated project manager be assigned to oversee my project?",
        description: `Definitely, each of our clients is assigned a dedicated project manager who oversees every aspect of their project, supervising their team's work. Our project managers are available for communication through various channels such as Skype, calls, messages, and emails, ensuring that clients are updated on project progress and that any issues are addressed promptly.`,
      },
      {
        id: 6,
        title: "What project management tools are utilized by your team?",
        description: `We utilize a range of project management platforms like Jira, Slack, Skype. Moreover, we can seamlessly integrate with any tools your team is currently using, ensuring a smooth fintech product development outsourcing process that aligns with your existing infrastructure.`,
      },
      {
        id: 7,
        title:
          "Do you offer consulting services for fintech software development?",
        description: `Absolutely. We offer our expertise in fintech product development through our complimentary Idea Validation service. You can choose the best approach that suits your needs, and we'll provide consultation on all stages of fintech software development, from ideation to product launch.`,
      },
      {
        id: 8,
        title: "Are fintech website development services available?",
        description: `Of course. We provide a full team of specialists, including UI/UX designers, motion graphics experts, visual identity designers, full-stack developers, QA analysts, and DevOps engineers, with extensive fintech sector experience.`,
      },
      {
        id: 9,
        title:
          "What is the turnaround time for setting up a dedicated fintech development team?",
        description: `Our team is experienced in working under tight deadlines and will begin with a dedicated team, gradually onboarding specialists as the project progresses. Typically, a full, dedicated fintech development team can be fully operational within a month of commencing work.`,
      },
    ],
    seo: {
      title:
        "Fintech Software Development for Industries: Empowering Financial Innovation",
      description:
        "Webelight builds custom fintech software solutions. Secure & scalable apps for mobile banking, payments, wealth management & more.",
      keywords:
        "Fintech Software Development Services, Fintech Software Development company in India, Hire Fintech Software Development company from India, Fintech app development, Fintech app development company, custom fintech software development, fintech application development company, fintech solutions software development company, fintech software development agency, fintech software outsourcing",
      openGraph: {
        title:
          "Fintech Software Development for Industries: Empowering Financial Innovation",
        description:
          "Webelight builds custom fintech software solutions. Secure & scalable apps for mobile banking, payments, wealth management & more.",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/fintech/ogImages/fintech-og-image.jpg",
          alt: "fintech-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "Fintech Software Development for Industries: Empowering Financial Innovation",
        description:
          "Webelight builds custom fintech software solutions. Secure & scalable apps for mobile banking, payments, wealth management & more.",
        images: {
          url: "/img/industries/fintech/ogImages/fintech-og-image.jpg",
          alt: "fintech-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  healthcareIndustriesPage: {
    overview: {
      title: "Healthcare Software Development Company",
      description:
        "Welcome to the new era of efficiency & innovation in the healthcare industry. We build software solutions for you to eliminate data silos, outdated tech, and get real-time insights to deliver exceptional care for your patients.",
      bannerImage: "/img/industries/healthcare/halthcareBanner.jpg",
      bannerImageClass: "healthcare-image",
    },
    softwareDevelopmentServices: {
      title: "Our Full-Stack Healthcare Software Development Services",
      industryType: "healthCare",
      description:
        "As a premier healthcare software development firm, Our expertise encompasses a comprehensive range of healthcare software development services, including:",
      services: [
        {
          id: uuidv4(),
          title: "EMR and EHR Software Development",
          description:
            "We create secure and robust Electronic Medical Records (EMR) and Electronic Health Records (EHR) systems, ensuring seamless data management, improved clinical decision-making, and streamlined healthcare delivery.",
          imagePath: emrAndEhrService,
          imageAlt: "emrAndEhrService",
        },
        {
          id: uuidv4(),
          title: "Custom Medical Software Development",
          description:
            "Our custom medical software development services include practice management software to optimize tasks, hospital management systems to manage hospital operations effectively, LIMS to streamline laboratory workflows, and healthcare supply chain management.",
          imagePath: customeMedicalSoftware,
          imageAlt: "customeMedicalSoftware",
        },
        {
          id: uuidv4(),
          title: "Custom mHealth App Development",
          description:
            "We have development services like patient engagement apps that provide appointment scheduling, and more, remote monitoring apps through wearable devices, and medical staff apps to streamline workflows for medical staff.",
          imagePath: customeMHealthApp,
          imageAlt: "customeMHealthApp",
        },
        {
          id: uuidv4(),
          title: "Remote Patient Monitoring Software",
          description:
            "We develop cutting-edge remote patient monitoring software that empowers healthcare providers to track vital signs, health data, and medication adherence remotely. This fosters proactive care, early intervention, and improved patient outcomes.",
          imagePath: remotePatientMonitoring,
          imageAlt: "remotePatientMonitoring",
        },
        {
          id: uuidv4(),
          title: "Telemedicine Software Development Services",
          description:
            "Revolutionize healthcare delivery with our secure telemedicine software development services. We enable impeccable virtual consultations, remote diagnosis, and efficient patient follow-up, expanding access to quality healthcare.",
          imagePath: telemedicineSoftware,
          imageAlt: "telemedicineSoftware",
        },
        {
          id: uuidv4(),
          title: "Healthcare Data Analytics",
          description:
            "Gain valuable insights from your healthcare data with our comprehensive data analytics solutions. We leverage advanced analytics tools to identify trends, predict outcomes, and optimize healthcare operations.",
          imagePath: healthcareDataAnalytics,
          imageAlt: "healthcareDataAnalytics",
        },
        {
          id: uuidv4(),
          title: "Patient Portal Development",
          description:
            "Empower patients with secure and convenient access to their medical records, appointment scheduling, and communication with healthcare providers through our patient portal development services.",
          imagePath: patientPortalDevelopment,
          imageAlt: "patientPortalDevelopment",
        },
        {
          id: uuidv4(),
          title: "HL7/FHIR Integration and Software",
          description:
            "Ensure excellent data exchange between disparate healthcare systems with our expertise in HL7 and FHIR integration. We develop software that facilitates efficient communication and data sharing within the healthcare ecosystem.",
          imagePath: fHIRIntegration,
          imageAlt: "fHIRIntegration",
        },
        {
          id: uuidv4(),
          title: "Pharmacy Management System",
          description:
            "From independent pharmacies to large hospital networks, our services are designed to optimize every aspect of pharmacy operations including inventory management, patient profiles to drug interaction alerts ensuring efficiency, accuracy, and compliance.",
          imagePath: pharmacy,
          imageAlt: "pharmacy",
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose our healthcare software development company",
      description:
        "We understand the unique needs of the healthcare industry and translate them into robust, secure, and user-friendly software solutions. Here's what sets us apart:",
      reasons: [
        {
          id: 1,
          title: "Dedicated Healthcare Software Development Team",
          description:
            "Our team comprises experienced professionals with a deep understanding of healthcare workflows and regulations, ensuring that your software solutions align seamlessly with the complexities of the healthcare industry.",
          imagePath: dedicatedHealthcare,
          imageAlt: "Dedicated Healthcare Software Development Team",
        },
        {
          id: 2,
          title: "Security and Compliance",
          description:
            "We prioritize data security and ensure strict adherence to HIPAA and other relevant healthcare regulations, safeguarding sensitive patient information with the utmost care and providing peace of mind.",
          imagePath: securityAndComplience,
          imageAlt: "Security and Compliance",
        },
        {
          id: 3,
          title: "Agile Development Approach",
          description:
            "We adopt an agile methodology for rapid development and efficient project delivery, ensuring timely solutions that meet your evolving needs, ensuring timely solutions that meet your evolving needs and adapt to the ever-changing healthcare landscape.",
          imagePath: agileDevelopment,
          imageAlt: "Agile Development Approach",
        },
        {
          id: 4,
          title: "Advanced Technologies",
          description:
            "We leverage cutting-edge technologies like AI, Big Data, and cloud computing to create intelligent and scalable healthcare software solutions, empowering you to harness the power of these advancements for improved patient care and operational efficiency.",
          imagePath: advancedTech,
          imageAlt: "Advanced Technologies",
        },
        {
          id: 5,
          title: "UI/UX Design",
          description:
            "We prioritize user-centric design principles, creating intuitive and user-friendly interfaces that enhance the overall user experience for both healthcare providers and patients, fostering seamless interaction and positive engagement with your software solutions.",
          imagePath: uiUxDesign,
          imageAlt: "UI/UX Design",
        },
        {
          id: 6,
          title: "Quality Assurance",
          description:
            "Our rigorous quality assurance processes encompass the entire software development lifecycle, from initial testing to performance optimization, guaranteeing the delivery of high-quality, reliable software solutions.",
          imagePath: qualityAssurance,
          imageAlt: "Quality Assurance",
        },
      ],
    },
    ourAppIntegrations: {
      title:
        "Unlock the power of connected care with healthcare app integrations",
      description:
        "Healthcare App Integrations empower patients to take an active role in managing their health, ultimately fostering a more connected and effective healthcare ecosystem.",
      ourApps: [
        {
          id: 1,
          title: "Cross Platform Functionality",
          imagePath: crossPlatformFunctionality,
          imageAlt: "Cross Platform Functionality",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Payment Gateway Integration",
          imagePath: paymentGatewayIntegration,
          imageAlt: "Payment Gateway Integration",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Google and Apple Health Integration",
          imagePath: googleAndAppleHealthIntegration,
          imageAlt: "Google and Apple Health Integration",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Multi-Authentication System",
          imagePath: multiAuthenticationSystem,
          imageAlt: "Multi-Authentication System",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Data Analytics",
          imagePath: dataAnalyticsService,
          imageAlt: "Data Analytics",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Online Appointment Scheduling",
          imagePath: onlineAppointmentScheduling,
          imageAlt: "Online Appointment Scheduling",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "EHR Integrations",
          imagePath: ehrIntegrations,
          imageAlt: "EHR Integrations",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "CMS and Reporting",
          imagePath: cmsAndReporting,
          imageAlt: "CMS and Reporting",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title:
        "Patient Satisfaction in Freefall? Our Software Can Help You Soar.",
    },
    buildFinancialIndustrySoftwareDetails: {
      title: "Advanced Technologies to Build Healthcare Industry Software",
      heroDescription:
        "Derive valuable insights into healthcare trends and customer behavior with cutting edge technological solutions and make intelligent decisions for your customers.",
      heroTitle: "Revolutionizing Healthcare",
      subTitle:
        " Harnessing Advanced Technologies For Healthcare Software Development",
      heroImage: healthCareTitleImage,
      description:
        "We believe that by strategically integrating technological advancements, we can unlock new possibilities for improved patient care, streamlined workflows, for building robust and future-proof healthcare software solutions.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Transform your healthcare operations: build a robust and future-proof healthcare software platform.",
      imageBackground: "healthcare-bg-image",
    },
    costEstimationDetail: {
      title: "Healthcare Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your Healthcare solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title: "What is the role of a healthcare software developer?",
        description:
          "Healthcare software developers create, implement, and support healthcare software by leveraging expertise in mobile and web development, DevOps, cloud computing, AI, IoT, and blockchain. They work closely with clients to tailor solutions to their unique needs, staying updated with the latest industry trends and technological advancements.",
      },
      {
        id: 2,
        title:
          "What custom healthcare solutions can Webelight Solutions deliver to us?",
        description: `Our long-tenured experience and full-stack expertise help us create almost any specialized software for healthcare, in particular:\n <ul><li>Patient Portals.</li><li>EHR and EMR Systems.</li><li>Healthcare Staffing Software.</li><li>Pharmacy Management Software.</li><li>Telemedicine Apps.</li><li>Remote Health Monitoring Apps.</li><li>Healthcare Scheduling Software Systems.</li><li>Hospital Management Software.</li><li>Medical Imaging Software.</li><li>Healthcare CRM Software.</li><li>Wearable App Development.</li><li>Healthcare Accounting Software.</li></ul>`,
      },
      {
        id: 3,
        title: "What mobile applications can you develop?",
        description: `Here are the most common medical application development requests we receive: \n <ul><li>Mobile telemedicine apps for online meetings.</li><li>Clinical apps for the exchange of data and information between institutions and their departments.</li><li>Medical record apps for quick access to sworn doctors and patients.</li><li>Patient access portals for the convenience of those seeking treatment.</li><li>IoT dashboards for analyzing data coming from wearable devices.</li></ul>`,
      },
      {
        id: 4,
        title: "What is a way for me to evaluate your level of expertise?",
        description: `<a href=${routes.SERVICES}>Webelight Solutions</a> offers a variety of options to verify our proficiency and skills, including test tasks, technical calls to discuss your project, or reaching out to companies highlighted in our portfolio section.`,
      },
      {
        id: 5,
        title:
          "Will a dedicated project manager be assigned to oversee my project?",
        description: `Absolutely. Each client is assigned a dedicated project manager who oversees every aspect of their project, supervising the team's work and ensuring clear communication throughout the development process.`,
      },
      {
        id: 6,
        title: "What project management tools are utilized by your team?",
        description: `We utilize a range of project management platforms like Jira, Slack, Skype. Moreover, we can seamlessly integrate with any tools your team is currently using, ensuring a smooth healthtech product development outsourcing process that aligns with your existing infrastructure.`,
      },
      {
        id: 7,
        title:
          "Do you offer consulting services for healthcare software development?",
        description: `Yes, we offer our expertise in healthcare product development through our complimentary Idea Validation service. We can guide you through all stages of software development, from ideation to product launch, ensuring the best approach for your specific needs.`,
      },
      {
        id: 8,
        title: "Are Healthtech website development services available?",
        description: `Of course. We provide a full team of specialists, including UI/UX designers, visual identity designers, full-stack developers, QA analysts, DevOps engineers, blockchain specialists, and AI engineers with extensive healthtech  sector experience.`,
      },
      {
        id: 9,
        title:
          "What is the turnaround time for setting up a dedicated healthtech development team?",
        description: `Our team is experienced in working under tight deadlines and will begin with a dedicated team, gradually onboarding specialists as the project progresses. Typically, a full, dedicated healthtech development team can be fully operational within a month of commencing work.`,
      },
      {
        id: 10,
        title: "What software systems do hospitals use?",
        description: `The healthcare industry is typically slow to implement IT solutions due to complex, inflexible, or even outdated infrastructure. Despite this, many healthcare organizations would like EMS, EHR, HMS, telemedicine (or telehealth), IoT, and clinical research software.`,
      },
    ],
    seo: {
      title:
        "Healthcare Software Solutions: Transforming the Future of Healthcare",
      description:
        "Webelight offers innovative healthcare software solutions to revolutionize your medical practice. Improve patient outcomes and optimize workflows with our advanced technology.",
      keywords:
        "custom healthcare software solutions, telemedicine software development services, custom medical software development, offshore healthcare software development, healthcare software development company",
      openGraph: {
        title:
          "Healthcare Software Solutions: Transforming the Future of Healthcare",
        description:
          "Webelight offers innovative healthcare software solutions to revolutionize your medical practice. Improve patient outcomes and optimize workflows with our advanced technology.",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/healthcare/ogImages/healthcare-og-image.png",
          alt: "healthcare-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "Healthcare Software Solutions: Transforming the Future of Healthcare",
        description:
          "Webelight offers innovative healthcare software solutions to revolutionize your medical practice. Improve patient outcomes and optimize workflows with our advanced technology.",
        images: {
          url: "/img/industries/healthcare/ogImages/healthcare-og-image.png",
          alt: "healthcare-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  retailAndEcommerceIndustriesPage: {
    overview: {
      title: "Retail And Ecommerce Software Development Company",
      description:
        "Imagine a world where your customers glide effortlessly through checkout, your inventory is finely tuned for maximum efficiency, and your online store is booming. Don't just wish for it, make it a reality with Webelight Solutions. Our goal is simple: to deliver a remarkable retail experience that keeps your customers coming back for more.",
      bannerImage: "img/industries/ecommerce/ecommerce-banner-image.jpg",
      bannerImageClass: "ecommerce-image",
    },
    softwareDevelopmentServices: {
      title: "Our Retail & Ecommerce App Development Services",
      industryType: "eCommerce",
      serviceImage1: ecomServiceOne,
      serviceImage2: ecomServiceTwo,
      rotatingTextImage: ecomTextRoundImage,
      description:
        "From secure payment gateways to perfect investment management apps, our e-commerce solutions will help you to make well-informed decisions, personalize marketing efforts, and ultimately, maximize your return on investment (ROI).",
      services: [
        {
          id: uuidv4(),
          title: "Multisite Ecommerce App Development",
          description:
            "Need a sleek, user-friendly website and app that showcases your products and services? We've got you covered. Our team builds custom e-commerce web and mobile apps that align with your brand and are designed to drive sales and consumer interest.",
          imagePath: multiSiteDevelopment,
          imageAlt: "multiSiteDevelopment",
        },
        {
          id: uuidv4(),
          title: "Supply Chain Management Development",
          description:
            "From managing vendors to streamlining logistics, we help you achieve sustainable growth by optimizing warehouses, inventory management, transportation, and order fulfillment while ensuring the accuracy, speed, and cost-efficiency of your application.",
          imagePath: supplyChainManagement,
          imageAlt: "supplyChainManagement",
        },
        {
          id: uuidv4(),
          title: "AI-Powered Intelligence Development",
          description:
            "With the help of big data analytics, we offer AI-powered marketing automation, and sales & demand forecasting solutions. Personalize your customer’s journey and boost conversions. From social media campaigns to chatbot development, we help you create a lasting impact on customers.",
          imagePath: aiPowerdDevelopment,
          imageAlt: "aiPowerdDevelopment",
        },

        {
          id: uuidv4(),
          title: "CRM & ERP Solution Development",
          description:
            "Let's say you have a bustling retail business with customers flooding in, and sales ringing up left and right. But how will you manage all that data? With ERP and CRM solutions, you can sync your sales data with your inventory levels and spot trends accurately.",
          imagePath: crmErpSolution,
          imageAlt: "crmErpSolution",
        },

        {
          id: uuidv4(),
          title: "Custom Ecommerce Shopping Cart Development",
          description:
            "Understanding your worries about shopping cart abandonment solutions, we focus on customized functionalities that directly support your business model, including loyalty programs, subscription services, or advanced product filtering options. Our custom shopping carts will easily integrate with your existing website.",
          imagePath: customEcommerceShopping,
          imageAlt: "customEcommerceShopping",
        },
        {
          id: uuidv4(),
          title: "Payment Gateway Integration Development",
          description:
            "Secure and speedy payment processing is essential for any online business. We integrate top payment gateways to ensure smooth transactions & build long-lasting trust with customers, strengthening brand image.",
          imagePath: paymentGatewayIntegrationDevelopment,
          imageAlt: "paymentGatewayIntegrationDevelopment",
        },
        {
          id: uuidv4(),
          title: "AR/VR Retail Solutions Development",
          description:
            "Discover the future of retail with our AR/VR solutions for online stores and POS systems. Elevate customer engagement with virtual try-ons and interactive product demos seamlessly integrated for a streamlined shopping journey.",
          imagePath: retailSolutionsDevelopment,
          imageAlt: "retailSolutionsDevelopment",
        },
        {
          id: uuidv4(),
          title: "Custom API development",
          description:
            "From RESTful APIs to dynamic GraphQL endpoints, we engineer with precision, ensuring steadfast reliability, robust security, and limitless scalability. Grow your enterprise to amplify connectivity, and improve user experiences across all platforms.",
          imagePath: customAPIDevelopment,
          imageAlt: "customAPIDevelopment",
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose us for Retail & E-commerce App Development",
      description:
        "Tired of inventory spreadsheets and abandoned carts? Picture apps predict demand, preventing stockouts, and simplifying checkout. Our retail solutions boost online dominance, brand loyalty, and sales. We're your partner in e-commerce success, using tech to nurture happy customers and drive revenue growth.",
      reasons: [
        {
          id: 1,
          title: "Expert Data Engineers",
          description:
            "From brainstorming to launching, We handle everything. With our global presence and flexible schedules, we make sure your data is super secure and deliveries are always on time, no surprises there!",
          imagePath: expertData,
          imageAlt: "Expert Data Engineers",
        },
        {
          id: 2,
          title: "AI-Driven Innovations",
          description:
            "Through the integration of AI chatbots and smart virtual assistants, we offer personalized customer support which ultimately boosts sales.",
          imagePath: aiDriven,
          imageAlt: "AI-Driven Innovations",
        },
        {
          id: 3,
          title: "IoT-Powered eCommerce Solutions",
          description:
            "We utilize IoT wearables and connected devices to engage customers through clever proximity marketing tactics.",
          imagePath: iotPowered,
          imageAlt: "IoT-Powered eCommerce Solutions",
        },
        {
          id: 4,
          title: "Efficiency through RPA in eCommerce",
          description:
            "We streamline operations for eCommerce businesses, cutting down on labor costs significantly through RPA.",
          imagePath: efficiency,
          imageAlt: "Efficiency through RPA in eCommerce",
        },
        {
          id: 5,
          title: "Blockchain Integration for eCommerce",
          description:
            "We facilitate decentralized marketplaces, secure payments, and efficient supply chain management with our blockchain services.",
          imagePath: blackChainIntigration,
          imageAlt: "Blockchain Integration for eCommerce",
        },
      ],
    },
    ourAppIntegrations: {
      title:
        "Effortless Shopping Experience With Our Retail & E-Commerce Solutions",
      description:
        "With robust features like AI-driven product discovery, responsive design, and seamless integration with payment gateways, we empower businesses to thrive in the digital marketplace, delivering unparalleled convenience worldwide.",
      ourApps: [
        {
          id: 1,
          title: "Product Information Management",
          imagePath: productInformationManagement,
          imageAlt: "Product Information Management",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Inventory Management System",
          imagePath: inventoryManagementSystem,
          imageAlt: "Inventory Management System",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Order Management System",
          imagePath: orderManagementSystem,
          imageAlt: "Order Management System",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Content Management System",
          imagePath: contentManagementSystem,
          imageAlt: "Content Management System",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Marketing Automation Tools",
          imagePath: marketingAutomationTools,
          imageAlt: "Marketing Automation Tools",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Mobile Commerce Optimization",
          imagePath: mobileCommerceOptimization,
          imageAlt: "Mobile Commerce Optimization",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "Secure Payment Processing",
          imagePath: securePaymentProcessing,
          imageAlt: "Secure Payment Processing",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "Returns and Exchange Management System",
          imagePath: returnsExchangeManagementSystem,
          imageAlt: "Returns and Exchange Management System",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title:
        "Get highly-quality and personalized retail & e-commerce solutions from our skilled and versatile custom development team.",
    },
    buildFinancialIndustrySoftwareDetails: {
      title:
        "Advanced Technologies To Build Retail And E-Commerce Applications",
      heroDescription:
        "Get immersive and personalized buying experiences for your customers with our AR and VR solutions to drive more sales, improve customer satisfaction, and stay ahead of market trends.",
      heroTitle: "Revolutionizing Retail And E-commerce",
      subTitle:
        " Harnessing Advanced Technologies For Retail And E-commerce Software Development",
      heroImage: ecommerceTitleImage,
      description:
        "Our team of developers builds custom retail and ecommerce solutions with a modern technological stack to help businesses like yours flourish and excel in the never-ending race of innovation.As experts in e-commerce product development, our team brings in the knowledge of the latest industry trends blended with their experience in this field to move your business in the right direction.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Feeling overwhelmed by the complexities of setting up an e-commerce platform?  Get in touch with our proficient development team today.",
      imageBackground: "ecommerce-bg-image",
    },
    costEstimationDetail: {
      title:
        "Retail And Ecommerce Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your retail and ecommerce solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title:
          "What custom retail and ecommerce services does your company provide?",
        description: `Our Retail and ecommerce services cater to your business needs, ensuring seamless operations, enhanced intelligence, and optimal customer experiences. Our services include:\n <ul><li>Multisite Ecommerce Website Development.</li><li>CRM & ERP Retail & Ecommerce Solution.</li><li>Healthcare Staffing Software.</li><li>Custom and mobile POS systems.</li><li>AI-Powered Intelligence.</li><li>Supply Chain Management.</li><li>Custom Ecommerce Shopping Cart.</li><li>Payment Gateway Integration.</li><li>Digital Wallet Development.</li><li>Custom API Development.</li><li>AR & VR Retail Solutions.</li><li>Shopping Cart Functionality.</li></ul>`,
      },
      {
        id: 2,
        title:
          "What makes your retail app development solutions stand out from others?",
        description: `Our retail app development solutions are distinguished by their ability to address your challenges effectively. With features like modern inventory management, swift checkouts, and seamless integration with secure payment gateways, our solutions ensure a superior shopping experience for your valued customers. Moreover, we have qualified data engineers and offer AI-powered solutions, guaranteeing enhanced conversions and more business opportunities for you.`,
      },
      {
        id: 3,
        title:
          "What steps do you take to ensure security and privacy in ecommerce app development?",
        description: `Security and privacy can’t be neglected in ecommerce app development. We employ industry-leading practices and technologies to safeguard your data and transactions, including encryption, secure payment gateways, and compliance with privacy regulations. Our <a href=${routes.CLOUD_COMPUTING_AND_SECURITY}>cloud security</a>  solutions are designed to protect your business and your customers from cyber threats and ensure safer online transactions.`,
      },
      {
        id: 4,
        title:
          "How do your retail app development services address inventory management challenges?",
        description: `Our retail app development services provide modern inventory management. By offering real-time insights into stock levels, demand patterns, and sales trends, our solutions help you avoid overstocking or understocking products, minimize lost sales, and optimize inventory turnover. With accurate management and streamlined processes, you can enhance efficiency and profitability while delivering consistent customer experiences.`,
      },
      {
        id: 5,
        title:
          "What support do you provide during the implementation of ecommerce app solutions?",
        description: `We offer comprehensive support throughout the implementation of ecommerce app solutions, ensuring a smooth and successful transition. Our team works closely with you to understand your requirements, customize the solution to fit your needs, and provide training and ongoing support to ensure optimal performance. From setup to troubleshooting, we're here to help you maximize the value of your investment and achieve your business goals.`,
      },
      {
        id: 6,
        title:
          "How do your retail app development services help build a strong brand identity?",
        description: `Our retail app development services and <a href=${routes.DIGITAL_MARKETING_STRATEGY}>digital marketing</a> solutions help you establish a strong brand identity by delivering consistent messaging across all touchpoints. With features like personalized marketing efforts, enhanced product offerings, and improved shopping experiences, our solutions help you differentiate your brand, engage customers, and build lasting relationships.`,
      },
      {
        id: 7,
        title:
          "What measures do you take to prevent website breakdowns and downtime?",
        description: `Website stability and uptime are critical for the success of your online business. We implement robust infrastructure and monitoring systems to detect and prevent potential issues before they occur. Our agile approach to maintenance and optimization ensures that your website always remains available and responsive.`,
      },
      {
        id: 8,
        title:
          "How does your ecommerce and retail app development service enhance competitiveness while maintaining quality?",
        description: `Our ecommerce and retail app development service helps your business grow with competitive advantages while upholding quality standards and leveraging robust data analytics capabilities. Through our solutions, you gain actionable insights into market dynamics, competitor pricing strategies, and customer preferences. Additionally, our advanced data handling features provide deep analytics into customer behavior, sales trends, and inventory performance, enabling you to refine operations and provide a memorable shopping experience to your customers.`,
      },
      {
        id: 9,
        title:
          "How do your ecommerce app development services enhance the shopping experience for customers?",
        description: `Our ecommerce app development services are focused on delivering a personalized shopping experience for customers. We design and develop intuitive, user-friendly apps that make it easy for customers to browse, shop, and checkout. With features like personalized recommendations, in-app messaging, and secure payment options, our apps boost engagement and drive conversions. By putting the customer experience first, we help you build lasting relationships and maximize customer lifetime value.`,
      },
    ],
    seo: {
      title:
        "eCommerce Software Development Services: Boost Your Online Business Success",
      description:
        "Boost your online sales with custom eCommerce software development. Scalable solutions, secure platforms, & features to drive conversions. Get a free quote today!",
      keywords:
        "etail & e-commerce software & app development services,retail software development services, custom retail software development,custom e commerce software development,e commerce software solutions development,retail software development companies,ecommerce app development company,ecommerce development software solutions",
      openGraph: {
        title:
          "eCommerce Software Development Services: Boost Your Online Business Success",
        description:
          "Boost your online sales with custom eCommerce software development. Scalable solutions, secure platforms, & features to drive conversions. Get a free quote today!",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/ecommerce/ogImages/ecommerce-og.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "eCommerce Software Development Services: Boost Your Online Business Success",
        description:
          "Boost your online sales with custom eCommerce software development. Scalable solutions, secure platforms, & features to drive conversions. Get a free quote today!",
        images: {
          url: "/img/industries/ecommerce/ogImages/ecommerce-og.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  travelAndHospitalityIndustriesPage: {
    overview: {
      title: "Travel And Hospitality App Development Company",
      description: `Need help keeping up with the ever-changing demands of your guests? Imagine frustrated customers abandoning bookings due to an outdated user interface, and having to keep up with the rapidly evolving digital era.`,
      bannerImage: "/img/industries/travel/travel-banner-image.jpg",
      bannerImageClass: "travel-image",
    },
    softwareDevelopmentServices: {
      title: "Our Travel and Hospitality App Development Services",
      industryType: "travel",
      description:
        "Thinking about smart automation that saves your team valuable time, distinctive guest experiences that leave them blissed out, and data-driven insights that help you keep up with the trends. That's the kind of service we provide.",
      services: [
        {
          id: uuidv4(),
          title: "Travel Portal Development",
          description:
            "We are experts in building user-centric travel portals that facilitate businesses and individual travelers. Our portals effortlessly integrate with leading GDS providers, offering various travel options including flights, hotels, car rentals, and activities.",
          imagePath: travelPortalDevelopment,
          imageAlt: "emrAndEhrService",
          servicePoints: [
            {
              id: 1,
              title:
                "Seamless UX with generative AI integration for smooth booking.",
            },
            {
              id: 2,
              title: "Real-time Inventory & Pricing with dynamic deals.",
            },
            {
              id: 3,
              title: "B2B & B2C travel marketplaces.",
            },
            {
              id: 4,
              title: "Bespoke travel API integrations for a unified workflow.",
            },
            {
              id: 5,
              title: "Customized CRM and ERP platforms.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Ticketing and Booking Solutions",
          description:
            "We offer your customers easy-to-use solutions for securing accommodations and transportation, scheduling reservations, and organizing journeys. With our travel software development company, you enhance revenue by: ",
          imagePath: ticketingAndBookingSolutions,
          imageAlt: "customeMedicalSoftware",
          servicePoints: [
            {
              id: 1,
              title: "Accommodation and transit booking solutions.",
            },
            {
              id: 2,
              title: "Flight & Hotel Booking App Development.",
            },
            {
              id: 3,
              title: "Taxi and shuttle reservation applications.",
            },
            {
              id: 4,
              title: "Car-sharing and rental applications.",
            },
            {
              id: 5,
              title: "Public transit mapping solutions.",
            },
            {
              id: 6,
              title: "Vacation Rental & Tour Booking App Development.",
            },
            {
              id: 7,
              title:
                "Hotel Management Software & Hospitality channel management apps.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "GDS API Integration",
          description:
            "We specialize in integrating your systems with Global Distribution Systems (GDS), granting you access to a vast network of airlines, hotels, and travel suppliers. Through this integration, you can offer your customers an exceptional selection of travel options. ",
          imagePath: gdsApiIntegration,
          imageAlt: "customeMHealthApp",
          servicePoints: [
            {
              id: 1,
              title:
                "Access a vast network of airlines & hotels with global travel inventory.",
            },
            {
              id: 2,
              title:
                "Streamlined Search & Booking directly through your platform.",
            },
            {
              id: 3,
              title:
                "Receive accurate pricing and instant confirmation with real-time data feeds.",
            },
            {
              id: 4,
              title:
                "Gain benefits from negotiated rates with reduced costs & competitive deals.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Restaurant POS",
          description:
            "With intuitive interfaces and robust reporting features, Our POS systems cater to diverse customer needs, ensuring a memorable dining experience for every visitor. With our Restaurant POS solutions, you can track sales and analyze trends.",
          imagePath: restaurantPos,
          imageAlt: "fHIRIntegration",
          servicePoints: [
            {
              id: 1,
              title: "Intuitive order-taking interface fast order processing.",
            },
            {
              id: 2,
              title:
                "Comprehensive features like menu management, table & waitlist management, etc.",
            },
            {
              id: 3,
              title: "Real-time reporting & analytics for valuable insights.",
            },
            {
              id: 4,
              title: "Inventory management and staff management systems.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Revenue Collection & Tracking System",
          description:
            "Our revenue collection & tracking system simplifies the complex process of financial management, providing you with real-time insights into your earnings and expenditures. Optimize your cash flow and mitigate financial risks.",
          imagePath: revenueCollectionAndTrackingSystem,
          imageAlt: "remotePatientMonitoring",
          servicePoints: [
            {
              id: 1,
              title:
                "Simplify the revenue cycle with automated invoicing, payments, and reconciliation.",
            },
            {
              id: 2,
              title:
                "Check your financial health instantly with real-time insights.",
            },
            {
              id: 3,
              title:
                "Give your customers the convenience of multiple payment options.",
            },
            {
              id: 4,
              title:
                "Protect your business with enhanced security and fraud detection.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Travel Expense Management",
          description:
            "Our Travel Expense Management solution manages the entire process, from expense tracking to reimbursement, saving you time and resources. By automating expense reporting, you can eliminate tedious manual tasks and reduce the risk of errors.",
          imagePath: travelExpenseManagement,
          imageAlt: "telemedicineSoftware",
          servicePoints: [
            {
              id: 1,
              title:
                "Automate expense capture & categorization for faster, error-free reporting.",
            },
            {
              id: 2,
              title:
                "Streamline approvals with a simplified expense reporting process.",
            },
            {
              id: 3,
              title:
                "Travel Policy Enforcement ensures compliance with company policies.",
            },
            {
              id: 4,
              title:
                "Gain data-driven insights to optimize travel budgets and save costs.",
            },
          ],
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose us for Travel and Hospitality App Services",
      description:
        "Running a travel and hospitality business isn’t a cakewalk. Right from booking to checkout, guests crave pleasant and comfortable service. Our customized software solutions personalize every crucial touchpoint possible, compelling your customers to use the same websites and apps for their future travel plans.",
      reasons: [
        {
          id: 1,
          title: "Proficient  Developers",
          description:
            "Even if you have a vague concept in your head about the type of solution you desire, our team of qualified individuals patiently listens to your requirements and brings your ideas to life. We are committed to delivering on time and performing better than your expectations.",
          imagePath: proficientDeveloper,
          imageAlt: "Proficient  Developers",
        },
        {
          id: 2,
          title: "Blockchain Integration",
          description:
            "We make sure that your customers can make secure and stable transactions. Our integrated solutions precisely record all transactions in a transparent ledger, facilitating direct payments. The primary goal is to minimize third-party costs while nurturing direct interaction between providers and customers.",
          imagePath: blackChainIntigrationReason,
          imageAlt: "Blockchain Integration",
        },
        {
          id: 3,
          title: "Artificial Intelligence Integration",
          description:
            "We have the expertise to build innovative AI solutions that improve planning and resource management Our AI-driven hospitality solutions redefine customer service by offering personalized assistance through chatbots and providing to-the-point trend predictions through advanced data analytics.",
          imagePath: aiIntegration,
          imageAlt: "Artificial Intelligence Integration",
        },
        {
          id: 4,
          title: "IOT",
          description:
            "Our solutions seamlessly integrate IoT devices, such as smart thermostats, keyless entry systems, and occupancy sensors, to provide real-time insights and control over various aspects of your hospitality business. From automating room adjustments based on guest preferences to monitoring energy usage and predicting maintenance needs, our IoT-enabled solutions transform how you manage and deliver services.",
          imagePath: iot,
          imageAlt: "IoT",
        },
        {
          id: 5,
          title: "Budget Friendly Solutions",
          description:
            "Our commitment to providing value for money means you get all the features you need without breaking the bank. Our transparent pricing ensures no hidden costs or surprises along the way. With us, you can rest assured that you're getting the most out of your investment in the competitive market.",
          imagePath: budgetFriendaly,
          imageAlt: "Budget-friendly Solutions",
        },
        {
          id: 6,
          title: "User Centric Design",
          description:
            "We understand that an exceptional app is more than just functionality. Our team prioritizes user-centric design principles, ensuring your intuitive design, easy navigations, and a delightful user experience for both guests and your staff, creating a win-win situation for your business and your customers.",
          imagePath: userCentric,
          imageAlt: "User-Centric Design",
        },
      ],
    },
    ourAppIntegrations: {
      title:
        "Get end-to-end services delivered with Our Travel & Hospitality App Integrations",
      description:
        "From booking and itinerary management to customer support and personalized recommendations, our travel integrations ensure a smooth and enjoyable journey for your customers.",
      ourApps: [
        {
          id: 1,
          title: "Multiple Payment Gateways",
          imagePath: multiplePaymentGateway,
          imageAlt: "Multiple Payment Gateways",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Geo-Location and Mapping",
          imagePath: goeLocationMapping,
          imageAlt: "Geo-Location and Mapping",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Customer Relationship Management (CRM)",
          imagePath: customerRealtionship,
          imageAlt: "Customer Relationship Management (CRM)",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Currency Conversion Calculators",
          imagePath: currencyConversion,
          imageAlt: "Currency Conversion Calculators",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Virtual Tour Guide",
          imagePath: virtualTourGuide,
          imageAlt: "Virtual Tour Guide",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Analytics and Reporting",
          imagePath: analyticsReporting,
          imageAlt: "Analytics and Reporting",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "Blockchain Integration",
          imagePath: blockChainIntigration,
          imageAlt: "Blockchain Integration",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "Personalization and Recommendations",
          imagePath: persinalization,
          imageAlt: "Personalization and Recommendations",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title:
        "Don't wait! Turn your travel and hospitality business into a revenue-generating machine with our advanced travel and hospitality solutions.",
    },
    buildFinancialIndustrySoftwareDetails: {
      title:
        "Advanced Technologies to Build Travel And Hospitality App Development Solution",
      heroDescription:
        "Derive valuable insights into travel and hospitality trends and customer behavior with cutting edge technological solutions and make intelligent decisions for your customers.",
      heroTitle: "Revolutionizing Travel And Hospitality",
      subTitle:
        " Harnessing Advanced Technologies For Travel And Hospitality Software Development",
      heroImage: travelTitleImage,
      description:
        "From hotel management to personalizing guest experiences, our innovative team understands all your challenges. Combining industry expertise with market insights, we build solutions to help you stay ahead of your competitors.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Turn your browsers into bookers. Get a free demo and see how you can increase bookings, boost reviews, and drive revenue in a short period.",
      imageBackground: "travel-bg-image",
    },
    costEstimationDetail: {
      title:
        "Travel And Hospitality Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your travel and hospitality solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title:
          "What services do you offer for the travel and hospitality industry?",
        description: `We provide a wide range of customized services to meet the unique needs of the travel and hospitality sector. Our offerings include:\n <ul><li>Travel portal development.</li><li>Accommodation and transit booking solutions.</li><li>GDS API integration.</li><li>Restaurant POS solutions.</li><li>Travel expense management solutions.</li><li>Flight and hotel booking app development.</li><li>Taxi and shuttle reservation applications.</li><li>Car-sharing and rental applications.</li><li>Public transit mapping solutions.</li><li>Vacation Rental and tour booking app development.</li><li>Hotel management software and hospitality channel management apps.</li></ul>`,
      },
      {
        id: 2,
        title:
          "What role does your company play in addressing housekeeping issues and maintaining cleanliness standards in the hospitality sector?",
        description: `We provide housekeeping management solutions that streamline task assignment, tracking, and performance evaluation for housekeeping staff. By optimizing housekeeping processes and ensuring compliance with cleanliness standards, our solutions contribute to a superior guest experience and positive reviews.`,
      },
      {
        id: 3,
        title:
          "What strategies does your company employ to increase profitability and maximize return on investment for travel and hospitality businesses?",
        description: `We collaborate closely with clients to understand their business objectives and develop tailored strategies to increase profitability and ROI. From optimizing pricing strategies to implementing cost-effective technology solutions, we help businesses achieve sustainable growth and financial success in the competitive market.`,
      },

      {
        id: 4,
        title:
          "How can your hospitality app development services help in delivering an end-to-end experience?",
        description: `Our hospitality app development services are designed to cover every aspect of the customer journey, from booking to post-stay feedback. We offer solutions that effortlessly integrate reservation systems, guest management, housekeeping, and feedback mechanisms, ensuring a smooth and satisfying experience for your guests from start to finish.`,
      },
      {
        id: 5,
        title:
          "What measures do you take to ensure the security of the software interface?",
        description: `Our <a href=${routes.CUSTOM_SOFTWARE_DEVELOPMENT}>software development</a> process includes robust measures to safeguard sensitive data. We employ encryption protocols, authentication mechanisms, and regular security audits to prevent unauthorized access and protect against cyber threats, ensuring a secure interface for both you and your customers.`,
      },
      {
        id: 6,
        title:
          "How do you address the challenge of managing online reputation?",
        description: `We understand the importance of maintaining a positive online reputation in the travel and hospitality industry. Our solutions include features for managing online reviews, monitoring social media presence, and engaging with customers to address feedback promptly. By precisely managing your online reputation, we help you build trust and enhance your brand image.`,
      },
      {
        id: 7,
        title:
          "What role does personalization play in your hospitality app development services?",
        description: `Personalization is key to meeting the changing expectations of travelers and providing memorable experiences. Our app development services include features for capturing and analyzing guest preferences, enabling targeted marketing campaigns, and delivering personalized services throughout the customer journey.`,
      },
      {
        id: 8,
        title:
          "How do you ensure the performance and functionality of the application? ",
        description: `We prioritize the performance, functionality, and accessibility of our applications to meet the needs of the travel and hospitality industry. Our development process includes rigorous testing, optimization techniques, and continuous monitoring to ensure that the application performs as expected under various conditions.`,
      },
      {
        id: 9,
        title:
          "Can your solutions integrate with third-party systems such as GDS APIs?",
        description: `Yes, our hospitality app development services include expertise in integrating with third-party systems such as GDS APIs. Whether you need to connect with global distribution systems for booking flights, hotels, or rental cars, or integrate with payment gateways for online transactions, we have the technical capabilities to seamlessly integrate various systems and provide a unified platform for your business needs.`,
      },
      {
        id: 10,
        title:
          "How do you address the challenge of irregular cash inflows in the travel and hospitality industry? ",
        description: `Irregular cash flows can pose challenges for businesses in the travel and hospitality industry, especially during off-peak seasons or economic downturns. Our solutions include features for revenue management, dynamic pricing strategies, and financial forecasting to help you optimize revenue streams and manage cash flow effectively.`,
      },
    ],
    seo: {
      title:
        "Travel & Hospitality App Development: Enhancing the Travel Experience",
      description:
        "Specializing in travel & hospitality app development. Transforming ideas into user-friendly applications for your business.",
      keywords:
        "travel and hospitality app development, hospitality app development, custom hospitality app development, hospitality app development company, travel & hospitality app development, hospitality app development services, travel and hospitality app development services, Travel portal development services, GDS API integration services",
      openGraph: {
        title:
          "Travel & Hospitality App Development: Enhancing the Travel Experience",
        description:
          "Specializing in travel & hospitality app development. Transforming ideas into user-friendly applications for your business.",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/travel/ogImages/travel-og-image.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "Travel & Hospitality App Development: Enhancing the Travel Experience",
        description:
          "Specializing in travel & hospitality app development. Transforming ideas into user-friendly applications for your business.",
        images: {
          url: "/img/industries/travel/ogImages/travel-og-image.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  realEstateIndustriesPage: {
    overview: {
      title: "Real Estate Software Development Company",
      description: `Ditch the endless property searches and inaccurate valuations. Our real estate software development solutions streamlines operations, combats limited exposure, and simplifies cumbersome transactions. We leverage  AI, big data, and cutting-edge technologies to help you gain a competitive advantage and propel your business forward.`,
      bannerImage: "/img/industries/travel/travel-banner-image.jpg",
      bannerImageClass: "realEstate-image",
    },
    softwareDevelopmentServices: {
      title: "Our Full-Stack Real Estate Software Development Services",
      industryType: "realEstate",
      description:
        "Navigate the complexities of the real estate market with our specialized software development services. We offer a comprehensive suite of solutions designed to streamline your operations, overcome common challenges, and unlock new opportunities for growth.",
      services: [
        {
          id: uuidv4(),
          title: "Property Marketplace",
          description:
            "Maximize the exposure of your real estate listings to attract potential buyers, sellers, and renters interested in residential and commercial properties.",
          imagePath: propertyMarketplace,
          imageAlt: "propertyMarketplace",
          backgroundColor: "service-light-red",
          backgroundImage: "property-marketplace",
          servicePoints: [
            {
              id: 1,
              title: "Listing Management.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "User-friendly interface to edit, and manage listings & personalized user dashboards with multi-factor login authentication",
                },
                {
                  id: 2,
                  title:
                    "Detailed property descriptions with high-quality photos, floor plans, virtual tours,and 3D models for a more immersive viewing experience.",
                },
              ],
            },
            {
              id: 2,
              title: "Communication and Messaging.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Built-in call and chat messaging system for communication between buyers and sellers..",
                },
                {
                  id: 2,
                  title:
                    "Real-time notifications for messages, listing updates, and inquiries.",
                },
              ],
            },
            {
              id: 3,
              title: "Transaction Management",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Digital document management for contracts, agreements, and legal paperwork & integration with secure payment gateways.",
                },
                {
                  id: 2,
                  title:
                    "Post your property for sale, rent, or lease and showcase property to millions of buyers and get the right estimate with property valuation calculators",
                },
              ],
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Property Management ERP solutions",
          description:
            "Optimize the operations of your hospitality accommodations, commercial properties, residential complexes, and rental properties with our comprehensive Property Management ERP solutions. ",
          imagePath: propertyManagement,
          imageAlt: "propertyManagement",
          backgroundColor: "service-light-blue",
          backgroundImage: "property-management",
          servicePoints: [
            {
              id: 1,
              title: "Customer Portal.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Offer a centralized dashboard where users can view purchase cycles, make bookings, and track progress.",
                },
                {
                  id: 2,
                  title:
                    "A secure platform for clients and contractors to access documents, and updates related to their properties.",
                },
              ],
            },
            {
              id: 2,
              title: "Pre-Sales and Marketing.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Track and manage pre-sales activities, including lead generation to drive conversions.",
                },
                {
                  id: 2,
                  title:
                    "Enable users to track all activities related to property management, sales, and customer service, ensuring efficient task management.",
                },
              ],
            },
            {
              id: 3,
              title: "Reporting and Analytics.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Customizable dashboards and reports for monitoring key performance indicators (KPIs), occupancy rates, revenue streams, and property expenses.",
                },
                {
                  id: 2,
                  title:
                    "Advanced analytics tools for trend analysis, demand forecasting, and revenue optimization.",
                },
              ],
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Custom CRMs",
          description:
            "This robust platform is a boon for real estate professionals, offering a feature-rich interface that centralizes your operations. From managing client interactions and connecting with brokers to stimulating chatbots, our CRM empowers your business to thrive.",
          imagePath: customCRMs,
          imageAlt: "customCRMs",
          backgroundColor: "service-light-yellow",
          backgroundImage: "custom-crms",
          servicePoints: [
            {
              id: 1,
              title: "Centralized Lead Generation.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Track and manage leads effectively to drive conversions and maximize opportunities & streamline lead generation efforts by consolidating leads.",
                },
                {
                  id: 2,
                  title:
                    "Seamless Integration to Enhance collaboration and efficiency across your organization",
                },
              ],
            },
            {
              id: 2,
              title: "Data-Driven Insights.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Leverage powerful analytics to gain insights into customer behavior, market trends, and performance metrics.",
                },
                {
                  id: 2,
                  title:
                    "Make informed decisions and optimize strategies based on data-driven insights.",
                },
              ],
            },
            {
              id: 3,
              title: "Property Tracking.",
              serviceSubPoints: [
                {
                  id: 1,
                  title:
                    "Monitor property availability, status changes, and key milestones to ensure timely and efficient transactions.",
                },
                {
                  id: 2,
                  title:
                    "AR/VR property tools provide clients with an immersive and interactive walkthrough experience, allowing them to explore properties in an engaging virtual environment.",
                },
              ],
            },
          ],
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose our real estate software development company",
      description:
        "We offer cost-effective real estate and property solutions to give your organization a distinct edge and bring about transformation by optimizing results.",
      reasons: [
        {
          id: 1,
          title: "Deep Industry Expertise",
          description:
            "We leverage our extensive real estate knowledge to craft solutions that directly address your unique challenges, ensuring tangible results that propel your business forward.",
          imagePath: deepIndustryExpertise,
          imageAlt: "Deep Industry Expertise",
        },
        {
          id: 2,
          title: "Custom Tailored Solutions",
          description:
            "We believe in a collaborative approach, meticulously aligning your software with your specific business goals for a seamless fit within your existing workflows and strategic objectives.",
          imagePath: customTailoredSolutions,
          imageAlt: "Custom-Tailored Solutions",
        },
        {
          id: 3,
          title: "DevOps",
          description:
            "Our DevOps team is committed to delivering software deployment solutions, whether on the cloud or on-premises. Leveraging advanced technologies like CI/CD pipelines and Kubernetes, we optimize the deployment process to guarantee seamless software delivery.",
          imagePath: devOps,
          imageAlt: "DevOps",
        },
        {
          id: 4,
          title: "Cutting Edge Technology",
          description:
            "We strategically integrate AI, Big Data, and cloud computing to build intelligent and scalable real estate software, empowering you with powerful data insights and automation for a significant market edge.",
          imagePath: cuttingEdgeTechnology,
          imageAlt: "Cutting-Edge Technology",
        },
        {
          id: 5,
          title: "Dedicated Project Management",
          description:
            "Each project receives a dedicated manager who serves as your central point of contact, ensuring clear communication, proactive problem-solving, and a streamlined workflow for complete peace of mind.",
          imagePath: dedicatedProjectManagement,
          imageAlt: "Dedicated Project Management",
        },
        {
          id: 6,
          title: "Agile Development",
          description:
            "Our rapid development cycles deliver demonstrably improved software iterations quickly, allowing for continuous improvement based on your feedback and a faster time-to-market to maximize your ROI.",
          imagePath: realEstayeAgileDevelopment,
          imageAlt: "Agile Development",
        },
      ],
    },
    ourAppIntegrations: {
      title: "Close more deals with our real estate integrations",
      description:
        "Streamline your workflows and enhance client interactions with solutions designed to boost productivity. Elevate your real estate business and achieve greater success with our comprehensive tools.",
      ourApps: [
        {
          id: 1,
          title: "Cross Platform Functionality",
          imagePath: realCrossPlatformFunctionality,
          imageAlt: "Cross Platform Functionality",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Property Portal",
          imagePath: propertyPortal,
          imageAlt: "Property Portal",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Live Chat & Calling",
          imagePath: liveChat,
          imageAlt: "Live Chat & Calling",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Lead Generation",
          imagePath: leadGeneration,
          imageAlt: "Lead Generation",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Secure Payment Gateways",
          imagePath: securePaymentGateways,
          imageAlt: "Secure Payment Gateways",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Internet Data Exchange (IDX)",
          imagePath: internetDataExchange,
          imageAlt: "Internet Data Exchange (IDX)",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "CRM Integrations",
          imagePath: crmIntegrations,
          imageAlt: "CRM Integrations",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "Project Management",
          imagePath: projectManagement,
          imageAlt: "Project Management",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title: "Turn tenants into angels. Automate the landlord life.",
    },
    buildFinancialIndustrySoftwareDetails: {
      title: "Advanced Technologies to Build Real Estate Software",
      heroDescription:
        "Derive valuable insights into Real Estate trends and customer behavior with cutting edge technological solutions and make intelligent decisions for your customers.",
      heroTitle: "Revolutionizing Real Estate",
      subTitle:
        " Harnessing Advanced Technologies For Real Estate Software Development",
      heroImage: realEtateTitleImage,
      description:
        "The real estate market is booming, but are your tools stuck in the past?  Imagine software that uses cutting-edge tech to give you a competitive edge. We're talking AI-powered insights, virtual tours that wow, and automation that frees you to focus on what matters most - closing deals.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Stop playing tetris with your data and get organized: Contact us today to see how webelight solutions can help you become the best in real estate",
      imageBackground: "realEstate-bg-image",
    },
    costEstimationDetail: {
      title:
        "Travel And Hospitality Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your travel and hospitality solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title:
          "What type of real estate software do you develop? We develop a wide range of real estate?",
        description: `Software solutions, including:\n <ul><li>Custom property CRM software.</li><li>Property mobile & web applications.</li><li>Construction management software.</li><li>Property marketplace.</li><li>Property management ERP software.</li><li>Lease management software.</li><li>AR/VR property tools.</li><li>Real Estate Software Modernization.</li></ul>`,
      },
      {
        id: 2,
        title:
          "What are the benefits of using your real estate software development solutions?",
        description: `Here are just a few benefits:\n <ul><li>Streamlined operations and workflows.</li><li>Increased efficiency and productivity.</li><li>Construction management software.</li><li>Improved lead generation and conversion.</li><li>Enhanced client communication and relationship management.</li><li>Data-driven decision making.</li><li>Reduced costs and improved ROI.</li></ul>`,
      },
      {
        id: 3,
        title: "How long does it take to develop real estate software?",
        description: `The development timeline varies depending on the complexity of the project. However, our Agile development approach allows for faster development cycles and quicker time-to-market.`,
      },

      {
        id: 4,
        title: "What is the cost of developing real estate software?",
        description: `The cost depends on the specific features and functionalities you need. We offer free consultations to discuss your project requirements and provide a custom quote.`,
      },
      {
        id: 5,
        title: "Do you offer ongoing support after the software is developed?",
        description: `Yes, we offer comprehensive ongoing support plans to ensure your software runs smoothly and meets your evolving needs.`,
      },
      {
        id: 6,
        title: "What is a way for me to evaluate your level of expertise?",
        description: `<a href=${routes.SERVICES}>Webelight Solutions</a> offers a variety of options to verify our proficiency and skills, including test tasks, technical calls to discuss your project, or reaching out to companies highlighted in our portfolio section.`,
      },
      {
        id: 7,
        title:
          "Will a dedicated project manager be assigned to oversee my project?",
        description: `Absolutely. Each client is assigned a dedicated project manager who oversees every aspect of their project, supervising the team's work and ensuring clear communication throughout the development process.`,
      },
      {
        id: 8,
        title: "What project management tools are utilized by your team? ",
        description: `We utilize a range of project management platforms like Jira, Slack, Skype. Moreover, we can seamlessly integrate with any tools your team is currently using, ensuring a smooth real estate product development outsourcing process that aligns with your existing infrastructure.`,
      },
    ],
    seo: {
      title: "Real Estate Software Solutions: Streamlining Property Management",
      description:
        "Streamline your property business with top-tier Real Estate Software Solutions! Optimize sales, manage listings & boost productivity.",
      keywords:
        "Real estate software development solutions,Real estate software development services, Real estate software development company, Real estate software development, Custom software development for real estate industry, property management software development services",
      openGraph: {
        title:
          "Real Estate Software Solutions: Streamlining Property Management",
        description:
          "Streamline your property business with top-tier Real Estate Software Solutions! Optimize sales, manage listings & boost productivity.",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/realEstate/ogImages/real-estate-og-image.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "Real Estate Software Solutions: Streamlining Property Management",
        description:
          "Streamline your property business with top-tier Real Estate Software Solutions! Optimize sales, manage listings & boost productivity.",
        images: {
          url: "/img/industries/realEstate/ogImages/real-estate-og-image.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  energyAndUtilityIndustriesPage: {
    overview: {
      title: "Energy And Utility Software Development Company",
      description:
        "Struggling to make systematic use of your resources and scale your system effectively? At Webelight Solutions, we deliver highly efficient and budget-friendly energy and utility software solutions. Our commitment lies in providing sustainable services that outlast generations and maximizing your revenue on oil and gas operations.",
      bannerImage: "/img/industries/healthcare/halthcareBanner.jpg",
      bannerImageClass: "energyAndUtility-image",
    },
    softwareDevelopmentServices: {
      title: "Our Energy and Utility Software Development Services",
      industryType: "finTech",
      description:
        "Prevent costly downtime and ensure reliable energy delivery. Our energy and utility software development team uses predictive maintenance to help you analyze equipment health, anticipate problems, and optimize maintenance schedules.",
      services: [
        {
          id: uuidv4(),
          title: "Utility Tracking Software Development",
          description:
            "Minimize your resource consumption by keeping track of asset performance with power utility software development. It distinguishes areas of wastage and guides you in eliminating them. It gets your budgets, business cases, and ROI reports and helps you analyze overall performance.",
          imagePath: utilityTrackingSoftwareDevelopment,
          imageAlt: "utilityTrackingSoftwareDevelopment",
        },
        {
          id: uuidv4(),
          title: "Nuclear Analysis Software Development",
          description:
            "From online core monitoring to real-time training simulation, our nuclear analysis software solutions examine all your processes. Our developers create software for in-core fuel performance, including features like extended production level run-times and analysis of plant transients.",
          imagePath: nuclearAnalysisSoftwareDevelopment,
          imageAlt: "nuclearAnalysisSoftwareDevelopment",
        },
        {
          id: uuidv4(),
          title: "Renewable Energy Management Systems",
          description:
            "We use historical data and forecasting to anticipate future renewable energy generation, helping you plan the integration with the grid. You can consolidate various distributed energy resources (DERs) into a virtual power plant, simplifying the management of energy as a unified entity.",
          imagePath: renewableEnergyManagementSystems,
          imageAlt: "renewableEnergyManagementSystems",
        },
        {
          id: uuidv4(),
          title: "Grid Optimization Systems",
          description:
            "Our system incorporates renewable energy sources into the grid, including load balancing and voltage regulation, thereby mitigating the effects of intermittent generation on the grid. Its predictive abilities and controls enable utilities to allocate staff and resources more efficiently.",
          imagePath: gridOptimizationSystems,
          imageAlt: "gridOptimizationSystems",
        },
        {
          id: uuidv4(),
          title: "Meter Reading Software Development",
          description:
            "Capture, store, and analyze electrical consumption data. Predict customer demand, identify power theft, and ensure accurate billing through sophisticated algorithms and data processing. Take a step towards sustainable energy usage.",
          imagePath: meterReadingSoftwareDevelopment,
          imageAlt: "meterReadingSoftwareDevelopment",
        },
        {
          id: uuidv4(),
          title: "Power Plant Design Software Development",
          description:
            "Optimize layouts with intelligent 3D modeling and simulation. Say goodbye to cost overruns, lengthy timelines, and commercial uncertainties with our solutions that help streamline both greenfield and brownfield projects. Design smarter, build faster, and generate cleaner energy.",
          imagePath: powerPlantDesignSoftwareDevelopment,
          imageAlt: "powerPlantDesignSoftwareDevelopment",
        },
        {
          id: uuidv4(),
          title: "SCADA Systems",
          description:
            "Get extensive data storage, sensor connectivity, and real-time simulation capabilities. With customizable visualization tools and scalability for any operation size, our easily adaptable system ensures rapid response times and efficient remote monitoring, boosting productivity.",
          imagePath: scadaSystems,
          imageAlt: "scadaSystems",
        },
        {
          id: uuidv4(),
          title: "Advanced Energy Data Analytics",
          description:
            "Accurately predict equipment effectiveness and overcome the challenges of blackouts, power surges, and peak energy demand. Guarantee predictive maintenance to enhance the longevity and effectiveness of your equipment. Make the most out of your current power consumption.",
          imagePath: advancedEnergyDataAnalytics,
          imageAlt: "advancedEnergyDataAnalytics",
        },
        {
          id: uuidv4(),
          title: "Solar Management System",
          description:
            "Conduct detailed assessments of solar resources, enhance site-specific energy predictions, and optimize design configurations and system layouts. Our modules facilitate the commissioning and testing of system performance and easily monitor the system’s overall health",
          imagePath: solarManagementSystem,
          imageAlt: "solarManagementSystem",
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose our energy and utility software development company",
      description:
        "Inefficient resource allocation can have catastrophic consequences, disrupting customer service, stretching repair budgets, and tarnishing your reputation. Recognizing such critical challenges, our experienced team develops strategic custom energy and utility solutions for you.",
      reasons: [
        {
          id: 1,
          title: "Talented Software Engineers",
          description:
            "Our company prides itself on the expertise and dedication exhibited by our team of software engineers specialized in the energy and utilities sector. We understand your organization's unique needs and have a broad understanding of industry trends.",
          imagePath: talentedSoftwareEngineers,
          imageAlt: "Talented Software Engineers",
        },
        {
          id: 2,
          title: "Data & Machine Learning",
          description: `By analyzing and evaluating large volumes of data, we contribute to improving your grid stability, optimize maintenance, and enhance forecasting accuracy. Adapt flexibly to the changes and environments with machine learning models and <a href=${routes.DATA_AND_ARTIFICIAL_INTELLIGENCE}>AI</a>.`,
          imagePath: dataMachineLearning,
          imageAlt: "Data & Machine Learning",
        },
        {
          id: 3,
          title: "Cloud And DevOps",
          description: `Our <a href=${routes.CLOUD_COMPUTING_AND_SECURITY}>Cloud</a> & DevOps engineers efficiently manage software delivery pipelines, accelerating your time-to-market. With versatile programming knowledge and expertise in managing public cloud resources, we dispatch efficient and secure deployments.`,
          imagePath: cloudAndDevOps,
          imageAlt: "Cloud & DevOps",
        },
        {
          id: 4,
          title: "Embedded Engineering",
          description:
            "We assist companies in managing their data and putting energy-saving measures into action. Our embedded systems constantly observe different aspects of energy consumption, including power usage, equipment efficiency, and operational trends.",
          imagePath: embeddedEngineering,
          imageAlt: "Embedded Engineering",
        },
        {
          id: 5,
          title: "Regulatory Compliance And Security",
          description:
            "Committed to upholding the highest standards of regulatory compliance and security, we integrate industry best practices and robust security measures into our software development processes, from the initial design to the final execution.",
          imagePath: regulatoryCompliance,
          imageAlt: "Regulatory Compliance & Security",
        },
        {
          id: 6,
          title: "Customer Centric Culture",
          description:
            "We value open and transparent communication and keep our clients informed about the project's progress. We seek our clients’ feedback regularly and strive to provide timely and empathetic support, ensuring they feel valued and cared for.",
          imagePath: customerCentricCulture,
          imageAlt: "Customer Centric Culture",
        },
      ],
    },
    ourAppIntegrations: {
      title:
        "Reduce Your Carbon Footprint with Sustainable Energy and Utility Software Integrations",
      description:
        "Optimize energy consumption, enhance efficiency, and promote eco-friendly practices with advanced technology solutions designed to support your sustainability goals.",
      ourApps: [
        {
          id: 1,
          title: "Meter Data Management Systems",
          imagePath: meterDataManagementSystems,
          imageAlt: "Meter Data Management Systems",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Geographic Information Systems",
          imagePath: geographicInformationSystems,
          imageAlt: "Geographic Information Systems",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Advanced Distribution Management",
          imagePath: advancedDistributionManagement,
          imageAlt: "Advanced Distribution Management",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Asset Performance Management",
          imagePath: assetPerformanceManagement,
          imageAlt: "Asset Performance Management",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Enterprise Resource Planning for Utilities",
          imagePath: enterpriseResourcePlanning,
          imageAlt: "Enterprise Resource Planning for Utilities",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Renewable Energy Monitoring Platforms",
          imagePath: renewableEnergyMonitoring,
          imageAlt: "Renewable Energy Monitoring Platforms",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "Supervisory Control and Data Acquisition",
          imagePath: supervisoryControlDataAcquisition,
          imageAlt: "Supervisory Control and Data Acquisition",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "Energy Trading and Risk Management",
          imagePath: energyTradingRiskManagement,
          imageAlt: "Energy Trading and Risk Management",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title:
        "Go green and save money. Modernize your approach with our energy and utility software services.",
    },
    buildFinancialIndustrySoftwareDetails: {
      title: "Advanced Technologies to Build Energy Industry Software",
      heroDescription:
        "Imagine software that predicts equipment failure in wind farms, optimizes energy distribution across smart grids, or even forecasts demand based on weather patterns. That's the power our proficient developers put in your hands.",
      heroTitle: "Revolutionizing Energy And Utility",
      subTitle:
        " Harnessing Advanced Technologies For Energy And Utility Software Development",
      heroImage: energyTitleImage,
      description:
        "You can’t handle it alone in this ever-changing market. Our skilled engineers understand the intricacies of the energy sector, from meeting consumer expectations to rapidly processing data exchange. Let's work together to keep you at the forefront of the energy revolution.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Transform your healthcare operations: build a robust and future-proof healthcare software platform.",
      imageBackground: "energy-bg-image",
    },
    costEstimationDetail: {
      title: "Healthcare Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your Healthcare solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title: "What is power plant software for asset management?",
        description: `Power plant software for asset management is a comprehensive solution designed to help energy and utility companies effectively manage their assets within power plants. It enables tracking of equipment performance, maintenance schedules, and resource allocation to optimize plant efficiency and minimize downtime.`,
      },
      {
        id: 2,
        title:
          "How can energy forecasting software benefit my utility company?",
        description: `Energy forecasting software enables utility companies to predict future energy demand with high accuracy, facilitating better resource planning, grid management, and cost optimization. It helps in avoiding under or overproduction of energy, thus reducing operational costs and ensuring reliable supply.`,
      },
      {
        id: 3,
        title: "What features should I look for in power utility software?",
        description: `Power utility software should offer features such as real-time monitoring, automated billing, outage management, and integration capabilities with other systems. It should also provide comprehensive reporting functionalities to facilitate data-driven decision making.`,
      },
      {
        id: 4,
        title: "What are the benefits of renewable energy management software?",
        description: `Renewable energy management software helps in efficiently utilizing and integrating renewable energy sources into the grid. It enables monitoring renewable energy assets, forecasting energy production, and optimizing their utilization to reduce reliance on traditional energy sources and mitigate environmental impact.`,
      },
      {
        id: 5,
        title:
          "How does energy market simulation software assist in decision-making?",
        description: `Energy market simulation software simulates various market scenarios and factors, allowing utility companies to analyze potential outcomes and make informed decisions regarding energy trading, pricing strategies, and risk management. It helps in optimizing market participation and maximizing profitability.`,
      },
      {
        id: 6,
        title: "What are the key features of energy risk management software?",
        description: `Energy risk management software provides tools for identifying, assessing, and mitigating risks associated with energy trading, market fluctuations, regulatory compliance, and operational uncertainties. It helps in safeguarding against financial losses and ensuring regulatory compliance.`,
      },
      {
        id: 7,
        title:
          "How does your company address the challenges of the lack of a unified accounting and management system?",
        description: `We offer a comprehensive utility tracking software solution that provides a single platform for storing data about consumers, tariffs, payment histories, etc. Our system integrates with existing accounting and management systems, ensuring transparency, efficiency, and accuracy in financial operations.`,
      },
      {
        id: 8,
        title:
          "What measures does your software take to mitigate risks such as incorrect entry or duplicate records?",
        description: `Our software employs robust data validation and verification mechanisms to minimize the risks of incorrect entry, duplicate records, or the use of outdated data. We also offer user permissions and audit trails to track and manage data changes, ensuring data integrity and security.`,
      },
      {
        id: 9,
        title:
          "How can your software development solutions help in reducing recurring losses due to expenses and revenue mismatches?",
        description: `Our software streamlines accounting and billing processes, enabling timely and accurate invoicing, payment processing, and revenue reconciliation. It provides real-time insights into financial performance, allowing for proactive identification and resolution of discrepancies to minimize losses.`,
      },
      {
        id: 10,
        title:
          "What steps do you take to ensure seamless integration with third-party digital solutions?",
        description: `We develop software that is designed with open architecture and standardized interfaces, allowing for easy integration with third-party digital solutions such as ERP systems, SCADA systems, and meter reading software. We provide comprehensive documentation, APIs, and support services to facilitate smooth integration and interoperability.`,
      },
      {
        id: 11,
        title:
          "What measures do you take to enhance the level of customer service for utility companies?",
        description: `We prioritize user experience and offer a user-friendly and intuitive user interface, along with comprehensive training and support services. Our solutions enable efficient customer communication, issue resolution, and service delivery, enhancing overall customer satisfaction and loyalty.`,
      },
    ],
    seo: {
      title:
        "Energy & Utility Software Development: Optimizing Resource Efficiency",
      description:
        "Energy & Utility Software Development. Build custom software to manage grids, billing, customer data & more. Improve efficiency & customer satisfaction.",
      keywords:
        "utility software development services,power plant software for asset management,best energy modeling software,energy forecasting software,power utility software,energy cost reporting software,renewable energy management software,energy market simulation software,energy risk management software,power plant management system",
      openGraph: {
        title:
          "Energy & Utility Software Development: Optimizing Resource Efficiency",
        description:
          "Energy & Utility Software Development. Build custom software to manage grids, billing, customer data & more. Improve efficiency & customer satisfaction.",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/energyUtility/ogImges/energy-og-image.png",
          alt: "energy-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "Energy & Utility Software Development: Optimizing Resource Efficiency",
        description:
          "Energy & Utility Software Development. Build custom software to manage grids, billing, customer data & more. Improve efficiency & customer satisfaction.",
        images: {
          url: "/img/industries/energyUtility/ogImges/energy-og-image.png",
          alt: "energy-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  logisticsIndustriesPage: {
    overview: {
      title: "Logistics Software Development Company",
      description:
        "Struggling to manage the supply chain and require assistance for real-time monitoring of your production? Don't settle for outdated approaches.  Reach out to us today, and let's boost your manufacturing operations and automate transportation with our customized and fully functional software solutions.",
      bannerImage: "img/industries/ecommerce/ecommerce-banner-image.jpg",
      bannerImageClass: "logistics-image",
    },
    softwareDevelopmentServices: {
      title: "Our logistics Software Development Services",
      industryType: "eCommerce",
      serviceImage1: logisticServiceOne,
      serviceImage2: logisticServiceTwo,
      rotatingTextImage: logisticsTextRoundImage,
      description:
        "We possess in-depth knowledge of industry nuances, standards, and needs. Our technological solutions assist in the management of materials, goods, freight, orders, and inventory. Utilizing our experience helps other businesses exceed consumer expectations and maintain a competitive edge.",
      services: [
        {
          id: uuidv4(),
          title: "Warehouse Management System",
          description:
            "Warehouse management software reduces the effort of your staff and prevents the occurrence of any manual errors. It also helps you grow the number of goods you can collect per shift and improves the flow of your supply chain. With WMS software, you can automate your tasks and manage inventory efficiently.",
          imagePath: warehouseManagementSystem,
          imageAlt: "warehouseManagementSystem",
        },
        {
          id: uuidv4(),
          title: "Transportation Management System",
          description:
            "Efficiently organize and execute the physical movement of goods, cut fleet costs, and minimize traffic congestion. Right from distributors to third-party providers, anybody can use TMS to coordinate shipments. You can plan the loading space of your containers, and modernize your freight processes.",
          imagePath: transportationManagementSystem,
          imageAlt: "transportationManagementSystem",
        },

        {
          id: uuidv4(),
          title: "Fleet Management System",
          description:
            "Discover the fastest routes to save fuel and enhance delivery metrics. Prolong vehicle lifespan and reduce your organization’s carbon footprint to increase profitability. Monitor driving patterns, identify unsafe behaviors, and equip managers with data needed to coach drivers and promote safer practices.",
          imagePath: fleetManagementSystem,
          imageAlt: "fleetManagementSystem",
        },
        {
          id: uuidv4(),
          title: "ERP and CRM",
          description:
            "Our ERP systems provide clarity into different departmental activities and monitor all payments. Using its insights, you can create customer-oriented solutions. with CRM, you can organize customer data, generate sales reports, calculate shipment frequency, and cleverly stay on top of every order.",
          imagePath: erpAndCrm,
          imageAlt: "erpAndCrm",
        },
        {
          id: uuidv4(),
          title: "Asset Tracking Software",
          description:
            "Track the movements and locations of merchandise, containers, and vehicles, and communicate this information effectively. Manage, operate, and maintain your company's assets, verifying the real-time tracking of high-value resources both indoors and outdoors, regardless of environmental conditions.",
          imagePath: assetTrackingSoftware,
          imageAlt: "assetTrackingSoftware",
        },
        {
          id: uuidv4(),
          title: "Parking Management Software",
          description:
            "Our system streamlines the parking experience and offers multiple payment options and ticketless access. With increased safety measures, we fortify the security of your facility, preventing thefts and unauthorized activities. Such a multitude of benefits will help you achieve better ROI in no time.",
          imagePath: parkingManagementSoftware,
          imageAlt: "parkingManagementSoftware",
        },
        {
          id: uuidv4(),
          title: "Telematics Software Development",
          description:
            "Gather, transmit, and store real-time data concerning vehicles, assets, and individuals. Identify unsafe driving behaviors like speeding, abrupt braking, and distracted driving to enhance driver safety and mitigate the likelihood of accidents. Spot areas where drivers need additional training and coaching.",
          imagePath: telematicsSoftwareDevelopment,
          imageAlt: "telematicsSoftwareDevelopment",
        },
        {
          id: uuidv4(),
          title: "Route Planning & Tracking",
          description:
            "Our route planning solutions make your deliveries cost-effective, reliable, and environmentally friendly. Our software considers multiple variables such as traffic patterns, road conditions, delivery windows, and vehicle capacities to create optimal routes to optimize delivery routes and track shipments in real time.",
          imagePath: routePlanningTracking,
          imageAlt: "routePlanningTracking",
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose our logistics software development company",
      description:
        "From consultation to implementation, we're here to support you every step of the way. Our Logistics software undergoes rigorous testing to ensure reliability and performance. The interface is designed with the user in mind and the data is protected by industry-leading security protocols.",
      reasons: [
        {
          id: 1,
          title: "Dedicated logistics Engineers",
          description:
            "With expertise in maintainability,  our logistics engineers excel in developing and implementing metrics to track equipment performance and streamline repair processes. Their familiarity with the Department of Defense regulations ensures seamless compliance with standards as well.",
          imagePath: dedicatedLogisticsEngineers,
          imageAlt: "Dedicated logistics Engineers",
        },
        {
          id: 2,
          title: "Impenetrable Security & Data Protection",
          description:
            "We are specialists in advanced technologies, such as real-time container tracking, network firewall security, mobile device management, and user-defined accessibility controls. Partnering with reputable security companies like us can provide you with robust protection and data security.",
          imagePath: impenetrableSecurityDataProtection,
          imageAlt: "Impenetrable Security & Data Protection",
        },
        {
          id: 3,
          title: "DevOps",
          description: `Our synergy between <a href=${routes.CLOUD_COMPUTING_AND_SECURITY}>Cloud </a> and DevOps provides value to the pace and helps to scale your business demands. Enhance your infrastructure and operational workflows by integrating and automating processes enhanced by cloud technology.`,
          imagePath: logisticsDevOps,
          imageAlt: "DevOps",
        },
        {
          id: 4,
          title: "Quality Assurance",
          description:
            "Our QA engineers ensure that each package arrives at its destination efficiently and embodies excellence throughout its journey. They oversee everything from proper shipment handling to timely delivery, all while maintaining the package's integrity with meticulous attention to detail.",
          imagePath: logisticsQualityAssurance,
          imageAlt: "Quality Assurance",
        },
        {
          id: 5,
          title: "UI/UX Design",
          description: `Our design team focuses on user-centric principles to create intuitive interfaces. We perform extensive usability testing and iterative refinements to produce highly functional, eye-catching, and visually appealing <a href=${routes.UI_UX_DESIGN}>UI/UX</a> designs. We gather feedback to reiterate designs as per user needs.`,
          imagePath: logisticsUiUxDesign,
          imageAlt: "UI/UX Design",
        },
        {
          id: 6,
          title: "Agile approach",
          description:
            "Our company uses an agile methodology for project management, allowing us to scale our team as needed. We can also bring in part-time professionals for security and DevOps tasks, ensuring we have the right resources and skills to finish your project on time and within budget.",
          imagePath: logisticsAgileApproach,
          imageAlt: "Agile approach",
        },
      ],
    },
    ourAppIntegrations: {
      title: "Get full-cycle assistance with Our logistics App Integrations.",
      description:
        "With robust features like AI-driven product discovery, responsive design, and seamless integration with payment gateways, we empower businesses to thrive in the digital marketplace, delivering unparalleled convenience worldwide.",
      ourApps: [
        {
          id: 1,
          title: "Real time inventory",
          imagePath: realTimeInventory,
          imageAlt: "Real-time inventory",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Route tracker",
          imagePath: routeTracker,
          imageAlt: "Route tracker",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Customer support",
          imagePath: customerSupport,
          imageAlt: "Customer support",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Shipping and tracking APIs",
          imagePath: shippingAndTracking,
          imageAlt: "Shipping and tracking APIs",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Predictive analytics",
          imagePath: predictiveAnalytics,
          imageAlt: "Predictive analytics",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Multiple payment options",
          imagePath: multiplePaymentOptions,
          imageAlt: "Multiple payment options",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "Automated invoicing features",
          imagePath: automatedInvoicingFeatures,
          imageAlt: "Automated invoicing features",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "Driver’s log",
          imagePath: driverLog,
          imageAlt: "Driver’s log",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title:
        "Consult, develop and integrate effortlessly with our logistics software solutions.",
    },
    buildFinancialIndustrySoftwareDetails: {
      title: "Advanced Technologies to Build Logistics Industry Software",
      heroDescription:
        "Our developers develop personalized logistics software to manage inventory effectively and provide visibility to the supply chain. As experts in logistics, we combine our proficiency in technology with a deep understanding of the industry to provide cost-efficient solutions.",
      heroTitle: "Revolutionizing Logistics",
      subTitle:
        " Harnessing Advanced Technologies For Logistics Software Development",
      heroImage: logisticsTitleImage,
      description:
        "We begin by evaluating your current plan, and its inefficiencies, and asses the scalability, integration capabilities, and customization options to formulate the ideal plan for you. Our developers utilize advanced tech to build innovative and adaptable solutions for your business growth.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Struggling with rising transportation costs, driver shortages, and opaque supply chains?",
      imageBackground: "logistics-bg-image",
    },
    costEstimationDetail: {
      title: "Logistics Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your retail and ecommerce solution.",
    },
    industriesFaqList: [
      {
        id: 1,
        title:
          "What types of logistics software development services do you offer?",
        description: `We offer a wide range of logistics software development services like:\n <ul><li>Multisite Ecommerce Website Development.</li><li>Warehouse Management System.</li><li>ERP and CRM.</li><li>Transportation Management System.</li><li>Analytics and BI Systems.</li><li>Fleet Management System.</li><li>Asset Tracking Software.</li><li>Parking Management Software.</li><li>Telematics Software Development.</li><li>Route Planning & Tracking.</li></ul>`,
      },
      {
        id: 2,
        title: "How can logistics software benefit my trucking business?",
        description: `Our logistics software for trucking enhances efficiency by optimizing routes, tracking deliveries in real time, and managing fleet maintenance. This helps reduce costs, improve delivery times, and increase overall productivity. We implement industry-leading security protocols, including real-time container tracking, network firewall security, and mobile device management to protect your data from unauthorized access and ensure compliance with regulations.`,
      },
      {
        id: 3,
        title: "Can you develop software for shipping logistics?",
        description: `Yes, we specialize in developing shipping logistics software that streamlines operations such as cargo handling, route planning, and real-time shipment tracking, ensuring smooth and efficient transportation processes.`,
      },
      {
        id: 4,
        title:
          "What is 3PL warehousing software and how can it help my business?",
        description: `3PL warehousing software is designed to manage third-party logistics operations. It helps in handling inventory, order processing, and warehouse operations, allowing businesses to efficiently manage outsourced logistics services and improve customer satisfaction.`,
      },
      {
        id: 5,
        title:
          "What industries can benefit from your logistics software development services?",
        description: `Our logistics software development services cater to various industries, including trucking, shipping, warehousing, construction, waste management, and more. We tailor our solutions to meet the specific needs of each industry.`,
      },
      {
        id: 6,
        title: "Can your software integrate with my existing systems?",
        description: `Yes, our solutions are designed for seamless integration with existing systems like ERP, CRM, and other third-party applications, ensuring smooth data flow and enhanced operational efficiency.`,
      },
      {
        id: 7,
        title: "What is the pricing model for your 3PL software?",
        description: `Our 3PL software pricing is customized based on the specific needs and scale of your operations. We offer flexible pricing models to suit different budgets and requirements.`,
      },
      {
        id: 8,
        title:
          "What features should I look for in cargo logistics management software?",
        description: `When selecting cargo logistics management software, look for features such as real-time tracking, automated documentation, route optimization, inventory management, and integration with existing systems.`,
      },
      {
        id: 9,
        title: "Do you offer vehicle logistics software for large fleets?",
        description: `Yes, our vehicle logistics software is scalable and can handle large fleets, providing features like route optimization, maintenance scheduling, and driver performance tracking to improve overall fleet efficiency.`,
      },
      {
        id: 10,
        title:
          "Can you develop custom logistics software for niche markets like construction sites?",
        description: `Absolutely. We specialize in creating construction site logistics software that addresses the unique needs of construction logistics, including equipment tracking, material delivery coordination, and project timeline management.`,
      },
      {
        id: 11,
        title:
          "What kind of support do you offer after the logistics software deployment?",
        description: `We provide comprehensive post-deployment support, including regular updates, maintenance, and 24/7 customer support to ensure your software operates smoothly and effectively.`,
      },
      {
        id: 12,
        title:
          "Can waste logistics software integrate with other systems I use in my waste management operations?",
        description: `Yes, modern waste logistics software can integrate easily with other systems used in waste management operations, such as ERP, CRM, and GIS systems. This integration facilitates smooth data exchange, enhances operational coordination, and provides a unified platform for managing all aspects of waste collection, transportation, and disposal.`,
      },
      {
        id: 13,
        title:
          "Can route planning and tracking software help reduce environmental impact in logistics?",
        description: `Yes, our route planning and tracking software can significantly reduce the environmental impact of logistics operations by optimizing delivery routes to minimize fuel consumption and emissions. By selecting the most efficient paths and reducing unnecessary mileage, your logistics operations become more eco-friendly and cost-effective.`,
      },
    ],
    seo: {
      title:
        "Logistics Software Solutions: Simplifying Supply Chain Management",
      description:
        "We're Providing innovative logistics software solutions to streamline operations, increase efficiency, and optimize supply chain management.",
      keywords:
        "logistics software for trucking,shipping logistics software,wms logistics software,3pl warehousing software,logistics fleet management software,construction site logistics software,inbound logistics software,web based freight forwarding software,waste logistics software,vehicle logistics software,3pl software pricing,cargo logistics management software.",
      openGraph: {
        title:
          "Logistics Software Solutions: Simplifying Supply Chain Management",
        description:
          "We're Providing innovative logistics software solutions to streamline operations, increase efficiency, and optimize supply chain management.",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/logistics/ogImages/logistics-og-image.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "Logistics Software Solutions: Simplifying Supply Chain Management",
        description:
          "We're Providing innovative logistics software solutions to streamline operations, increase efficiency, and optimize supply chain management.",
        images: {
          url: "/img/industries/logistics/ogImages/logistics-og-image.png",
          alt: "ecommerce-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
  elearningIndustriesPage: {
    overview: {
      title: "E-Learning Software Development Services",
      description: `In today’s digital age, e-learning is integral to education. With rising demand for online platforms, e-learning software development is crucial. It entails creating digital platforms for diverse online educational needs, from interactive courses to virtual classrooms. Our goal is to provide seamless and engaging learning experiences for students, professionals, and employees.`,
      bannerImage: "/img/industries/travel/travel-banner-image.jpg",
      bannerImageClass: "elearning-image",
    },
    softwareDevelopmentServices: {
      title:
        "Harness the Power of Cutting-Edge EdTech With Custom Education App Development",
      industryType: "realEstate",
      description:
        "Whether you need customizable features for specialized educational needs, intuitive content delivery methods, or seamless integration of multimedia resources, we conceptualize and create it all. Our offerings include:",
      services: [
        {
          id: uuidv4(),
          title: "School Management System",
          description:
            "Our school management system solutions cater to educational institutes, schools, and universities to assist teachers and school administrators in managing all student and curriculum-related data through a centralized platform.",
          imagePath: schoolManagementSystem,
          imageAlt: "schoolManagementSystem",
          backgroundColor: "service-light-red",
          backgroundImage: "school-management",
          servicePoints: [
            {
              id: 1,
              title: "Classroom Management",
              description:
                "We develop comprehensive tools for seating charts, lesson management, assignments, daily lesson logs.",
            },
            {
              id: 2,
              title: "Attendance Management",
              description:
                "Tools that can track student attendance and record absences with reasons.",
            },
            {
              id: 3,
              title: "Study Materials & Homework",
              description:
                "Daily homework tracking and uploads, subject-wise study materials.",
            },
            {
              id: 4,
              title: "Examination Management",
              description:
                "Exam result notices, subject-wise results, parent control view.",
            },
            {
              id: 5,
              title: "School Library Management",
              description:
                "Custom features like book cataloging, availability, issue and return records.",
            },
            {
              id: 6,
              title: "Transport Management",
              description:
                "Online transport service, payment options, route selection.",
            },
            {
              id: 7,
              title: "Communication Tools",
              description:
                "Direct messaging between schools, students, teachers, and parents.",
            },
            {
              id: 8,
              title: "Fees Collection & Accounting",
              description:
                "Online fees payment, accounting tracking for school admins",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Learning Management System (LMS) Development",
          description:
            "LMS oversees the management and delivery of eLearning courses, providing a versatile platform used by businesses, government agencies, educational institutions, and online/eLearning-based organizations. It enables these entities to effectively plan, implement, and assess learning processes online.",
          imagePath: learningManagementSystem,
          imageAlt: "learningManagementSystem",
          backgroundColor: "service-light-blue",
          backgroundImage: "learning-management",
          servicePoints: [
            {
              id: 1,
              title: "Online Courses",
              description:
                "Students can overcome geographical barriers, connect with teachers and peers in interactive virtual classrooms.",
            },
            {
              id: 2,
              title: "Assignments & Practice Tests",
              description:
                "Mock tests for different subjects assess student’s knowledge and exam readiness.",
            },
            {
              id: 3,
              title: "Whiteboard",
              description:
                "Live classes are enhanced with whiteboard support, screen sharing, and annotation (write, draw, color, shapes).",
            },
            {
              id: 4,
              title: "Automated Evaluation",
              description:
                "Quick and comprehensive evaluation reports and student’s scorecards.",
            },
            {
              id: 5,
              title: "Certification",
              description: "Online certification upon course completion.",
            },
            {
              id: 6,
              title: "Offline Videos",
              description:
                "Subject-wise videos accessible anytime, with or without internet.",
            },
            {
              id: 7,
              title: "Study Material/File Sharing",
              description:
                "Upload and save files in various formats (PDF, DOC, videos, audios, PPTs) ensuring that study materials are easily accessible.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Educational Game Development",
          description:
            "Webelight Solutions offers a cutting-edge educational game development app that revolutionizes learning experiences for students of all ages. Our app development solutions combine innovative game mechanics with rich educational content to create engaging and interactive learning environments.",
          imagePath: educationalGameDevelopment,
          imageAlt: "educationalGameDevelopment",
          backgroundColor: "service-light-yellow",
          backgroundImage: "educational-game",
          servicePoints: [
            {
              id: 1,
              title: "User-Friendly Interface",
              description:
                "Intuitive design that allows users to create educational games without requiring extensive technical knowledge.",
            },
            {
              id: 2,
              title: "Customization Options",
              description:
                "Extensive customization for game elements such as characters, backgrounds, and storylines.",
            },
            {
              id: 3,
              title: "Multimedia Integration",
              description:
                "Incorporates various media types, including images, audio, video, and animations.",
            },
            {
              id: 4,
              title: "Gamification Features",
              description:
                "Features that enable game mechanics such as points, levels, badges, and leaderboards.",
            },
            {
              id: 5,
              title: "Educational Content Library",
              description:
                "Access to a library of educational resources and materials that can be integrated into games.",
            },
            {
              id: 6,
              title: "Cross-Platform Compatibility",
              description:
                "Ensure games can be played on various devices, including PCs, tablets, and smartphones.",
            },
            {
              id: 7,
              title: "Study Material/File Sharing:",
              description:
                "Upload and save files in various formats (PDF, DOC, videos, audios, PPTs) ensuring that study materials are easily accessible.",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Skill Boosting App Development solutions ",
          description:
            "Webelight Solutions offers state-of-the-art skill boosting app development solutions designed to help individuals and organizations enhance their skills and competencies. Our apps are tailored to provide personalized, engaging, and effective learning experiences that cater to a wide range of professional and personal development needs.",
          imagePath: skillBoostingAppDevelopmentsolutions,
          imageAlt: "skillBoostingAppDevelopmentsolutions",
          backgroundColor: "service-light-green",
          backgroundImage: "skill-boosting-app",
          servicePoints: [
            {
              id: 1,
              title: "Personalized Learning Paths",
              description:
                "Customized learning experiences based on user preferences, skill levels, and career goals.",
            },
            {
              id: 2,
              title: "Interactive Content",
              description:
                "Gamified elements such as points, badges, and leaderboards to keep learners motivated and engaged.",
            },
            {
              id: 3,
              title: "Microlearning Modules",
              description:
                "Bite-sized learning modules that allow users to acquire skills in manageable increments.",
            },
            {
              id: 4,
              title: "Live Training and Webinars",
              description:
                "Access to live training sessions, webinars, and virtual workshops with industry experts.",
            },
            {
              id: 5,
              title: "Integration with Corporate Systems",
              description:
                "Integration capabilities with existing corporate systems such as HR platforms and Learning Management Systems (LMS).",
            },
          ],
        },
        {
          id: uuidv4(),
          title: "Corporate Training Apps",
          description:
            "Our educational app development services consist of an easy-to-follow design concept for corporate learning apps. Corporate training apps provide a flexible, scalable, and interactive platform for delivering training content to employees, regardless of their location to enhance the knowledge and productivity.",
          imagePath: corporateTrainingApps,
          imageAlt: "corporateTrainingApps",
          backgroundColor: "service-light-orange",
          backgroundImage: "corporate-training",
          servicePoints: [
            {
              id: 1,
              title: "On-Demand Learning App Development",
              description:
                "Enables access to training materials such as  video tutorials, articles, and interactive modules.",
            },
            {
              id: 2,
              title: "Personalized Learning Paths",
              description:
                "Adaptive learning algorithms to customize content based on performance and preferences.",
            },
            {
              id: 3,
              title: "Interactive Content",
              description:
                "Engaging formats such as quizzes, simulations, and gamified learning experiences with real-world scenarios to enhance practical knowledge.",
            },
            {
              id: 4,
              title: "Live Training Sessions",
              description:
                "Custom feature development for live webinars and virtual workshops with industry experts.",
            },
            {
              id: 5,
              title: "Progress Tracking and Reporting",
              description:
                "Interactive dashboard views to monitor employee progress with detailed analytics and reporting tools. ",
            },
            {
              id: 6,
              title: "Collaboration Tools",
              description:
                "Encourages peer-to-peer learning and collaboration through discussion forums and chat groups.",
            },
          ],
        },
      ],
    },
    reasonsForChooseCompony: {
      title: "Why choose our E-learning app development company",
      description:
        "Capitalizing on the combination of vast expertise and talented teams, we offer turnkey EdTech solutions, including end-to-end custom eLearning app development as well as post-implementation support and enhancement.",
      reasons: [
        {
          id: 1,
          title: "Advanced Technology",
          description:
            "We utilize cutting-edge technologies such as AI personalisation, AR/VR, and data analytics to create immersive and interactive learning experiences with continuous innovation to incorporate the latest advancements in EdTech solutions.",
          imagePath: advancedTechnology,
          imageAlt: "Advanced Technology",
        },
        {
          id: 2,
          title: "Increased Cost Effectiveness",
          description:
            "Investment in education app development is a wise decision. It lets educational entrepreneurs generate high revenue by entering into partnerships with digital content suppliers, publishers, and other service providers to provide even more services to your users.",
          imagePath: increasedCostEffectiveness,
          imageAlt: "Increased cost effectiveness",
        },
        {
          id: 3,
          title: "Enhanced Course Completion Rates",
          description:
            "Our mobile learning app development solutions enable learners to access courses on their handheld devices during their downtime. We make learning more convenient and efficient, ensuring that more learners stay engaged and complete their courses successfully.",
          imagePath: enhancedCourseCompletionRates,
          imageAlt: "Enhanced Course Completion Rates",
        },
        {
          id: 4,
          title: "Strategic Advisory in EdTech",
          description:
            "At Webelight solutions, our developers and experts possess a deep understanding of the latest trends, technologies, and methodologies in the e-learning industry. We have an expertise in integrating educational best practices into the app development process.",
          imagePath: strategicAdvisoryInEdTech,
          imageAlt: "Strategic Advisory in EdTech",
        },
        {
          id: 5,
          title: "Lean Development and 360-Degree Support",
          description:
            "At Webelight Solutions, our team is dedicated to employing disciplined methodologies that consistently create profitable value streams. By leveraging our lean development approach and comprehensive 360-degree support services, you can ensure your EdTech project is built on a sustainable foundation.",
          imagePath: leanDevelopment,
          imageAlt: "Lean Development & 360-Degree Support",
        },
        {
          id: 6,
          title: "Proven Track Record",
          description:
            "We have extensive experience in developing e-learning applications for various educational institutions, corporate training programs, and individual learners. With a diverse portfolio we have significantly enhanced learning experiences and outcomes across boundaries.",
          imagePath: provenTrackRecord,
          imageAlt: "Proven Track Record",
        },
      ],
    },
    ourAppIntegrations: {
      title:
        "Transform Education with 2X More Effective e-Learning Software Solutions",
      description:
        "Elearning platform integrations empower educators to create engaging and personalized learning experiences, fostering a more connected and impactful learning ecosystem.",
      ourApps: [
        {
          id: 1,
          title: "Multi Lingual Support",
          imagePath: multiLingualSupport,
          imageAlt: "Multi Lingual Support",
          bgColor: "light-green",
        },
        {
          id: 2,
          title: "Progress Tracking Dashboard",
          imagePath: progressTrackingDashboard,
          imageAlt: "Progress Tracking Dashboard",
          bgColor: "light-purpel",
        },
        {
          id: 3,
          title: "Chat Support",
          imagePath: chatSupport,
          imageAlt: "Chat Support",
          bgColor: "light-blue",
        },
        {
          id: 4,
          title: "Gamification Software",
          imagePath: gamificationSoftware,
          imageAlt: "Gamification Software",
          bgColor: "light-yellow",
        },
        {
          id: 5,
          title: "Data Analytics",
          imagePath: eLearningDataAnalytics,
          imageAlt: "Data Analytics",
          bgColor: "light-red",
        },
        {
          id: 6,
          title: "Performance Tracker",
          imagePath: performanceTracker,
          imageAlt: "Performance Tracker",
          bgColor: "light-orange",
        },
        {
          id: 7,
          title: "Interactive Graphics",
          imagePath: interactiveGraphics,
          imageAlt: "Interactive Graphics",
          bgColor: "light-grey",
        },
        {
          id: 8,
          title: "AI Personalization",
          imagePath: aIPersonalization,
          imageAlt: "AI Personalization",
          bgColor: "light-dark-green",
        },
      ],
    },
    bookFreeConsultationDetails: {
      title: "Fill the gap with dedicated e-learning software solutions",
    },
    buildFinancialIndustrySoftwareDetails: {
      title: "Advanced Technologies to Build EdTech Software Solution",
      heroDescription:
        "Derive valuable insights into E-learning trends and customer behavior with cutting edge technological solutions and make intelligent decisions for your customers.",
      heroTitle: "Revolutionizing E-Learning",
      subTitle:
        "Harnessing Advanced Technologies For E-Learning Software Development",
      heroImage: elearningTitleImage,
      description:
        "We harness the power of advanced technologies to develop highly interactive, personalized, and scalable educational platforms to enhance learning experiences and drive superior educational outcomes.",
      advanceTechnologies: [
        {
          id: 1,
          title: "Robotic Process \nAutomation",
          description:
            "RPA automates manual processes across mobile and web app development workflows and enables organizations to accelerate time-to-market actions and improve overall quality.",
          cardBackgroundImage: "robotic-process-image",
          refUrl: routes.ADVANCE_AUTOMATION,
          imagePath: financialSoftwareDevelopment,
        },
        {
          id: 2,
          title: "AI And ML \nDevelopment",
          description:
            "From recommendation engines and virtual assistants to image recognition and natural language processing, our AI & ML Development Services empower apps with advanced capabilities.",
          cardBackgroundImage: "ai-ml-image",
          refUrl: routes.DATA_AND_ARTIFICIAL_INTELLIGENCE,
          imagePath: mobileBanking,
        },
        {
          id: 3,
          title: "Blockchain \nDevelopment",
          description:
            "Unlock new opportunities to build solutions with unparalleled security, transparency, and decentralization in today's digital ecosystem with our blockchain development services",
          cardBackgroundImage: "block-chain-image",
          refUrl: routes.BLOCKCHAIN,
          imagePath: investment,
        },
        {
          id: 4,
          title: "Cloud Computing Development",
          description:
            "Implement robust foundational pillars like cloud computing, security & DevOps to reduce infrastructure costs, improve scalability, and provide users with a reliable and secure mobile app experience.",
          cardBackgroundImage: "cloud-computing-image",
          refUrl: routes.CLOUD_COMPUTING_AND_SECURITY,
          imagePath: crowdFunding,
        },
      ],
    },
    topGradeToolsAndLanguages: [
      {
        id: 1,
        name: "Front-End",
        zIndex: 2,
        techItems: [
          {
            imgSrc: "/img/services/tech/nextjs.svg",
            name: "Next JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/typescript.svg",
            name: "Typescript",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/vuejs.svg",
            name: "Vue JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/javascript.svg",
            name: "Javascript",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/nuxtjs.svg",
            name: "Nuxt JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/tailwind.svg",
            name: "Tailwind CSS",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/d3js.svg",
            name: "D3 JS",
            id: uuidv4(),
            className: "wb-p-3",
          },
        ],
      },
      {
        id: 2,
        name: "Back-End",
        zIndex: 3,
        techItems: [
          {
            imgSrc: "/img/services/tech/python.svg",
            name: "Python",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nodejs.svg",
            name: "Node JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/nestjs.svg",
            name: "Nest JS",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/fastapi.svg",
            name: "Fast API",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/django.svg",
            name: "django",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/laravel.svg",
            name: "Laravel",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/symfony.svg",
            name: "Symfony",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 3,
        name: "Mobile App",
        zIndex: 4,
        techItems: [
          {
            imgSrc: "/img/services/tech/flutter.svg",
            name: "Flutter",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/reactjs.svg",
            name: "React Native",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/android.svg",
            name: "Android",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/ios.svg",
            name: "iOS",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 5,
        name: "Frameworks",
        zIndex: 6,
        techItems: [
          {
            imgSrc: "/img/services/tech/strapi.svg",
            name: "Strapi",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/shopify.svg",
            name: "Shopify",
            id: uuidv4(),
          },
        ],
      },
      {
        id: 6,
        name: "Database",
        zIndex: 7,
        techItems: [
          {
            imgSrc: "/img/services/tech/mongodb.svg",
            name: "MongoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/postgresql.svg",
            name: "PostgreSQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/dynamodb.svg",
            name: "DynamoDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mysql.svg",
            name: "My SQL",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/sqlLite.svg",
            name: "SQL Lite",
            id: uuidv4(),
            className: "wb-p-3",
          },
          {
            imgSrc: "/img/services/tech/firebase.svg",
            name: "Firebase",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/mariadb.svg",
            name: "MariaDB",
            id: uuidv4(),
          },
          {
            imgSrc: "/img/services/tech/couchdb.svg",
            name: "CouchDB",
            id: uuidv4(),
          },
        ],
      },
    ],
    contactOurExperts: {
      title:
        "Create impactful learning experiences: Partner with webelight solutions for e-learning app development.",
      imageBackground: "elearning-bg-image",
    },
    costEstimationDetail: {
      title: "ELearning Services Software Development Cost Estimation",
      description:
        "We consider multiple factors when determining the final cost of your Elearning solution",
    },
    industriesFaqList: [
      {
        id: 1,
        title: "What types of eLearning software solutions do you provide?",
        description: `Webelight Solutions provide top next-gen eLearning software solutions that our EdTech experts can deliver with 2x effective, engaging, and personalized digital learning. They include:\n <ul><li>Virtual Classroom.</li><li>Cohort Learning Platforms.</li><li>Corporate Training Systems.</li><li>Gamification Software.</li><li>Microlearning Platform.</li><li>Language Learning Software.</li><li>Proctoring Software.</li><li>Massive Open Online Courses (MOOCs).</li><li>Academic Software.</li><li>OBE Software.</li><li>Learning Experience Platforms (LXPs).</li><li>Assessment & Evaluation Software.</li><li>Multi-platform & Multi-lingual Educational Apps.</li></ul>`,
      },
      {
        id: 2,
        title: "What features should an effective eLearning platform have?",
        description: `An effective eLearning platform should include:\n <ul><li>Interactive Content: Multimedia elements like videos, quizzes, and simulations.</li><li>User-Friendly Interface: Easy navigation and accessibility.</li><li>Personalization: Customized learning paths based on individual progress.</li><li>Gamification: Points, badges, and leaderboards to motivate learners.</li><li>Assessment Tools: Quizzes, tests, and analytics to track progress.</li><li>Collaboration Tools: Forums, chat, and group projects.</li><li>Mobile Compatibility: Access on smartphones and tablets.</li><li>Security: Robust measures to protect user data and privacy.</li></ul>`,
      },
      {
        id: 3,
        title: "How long does it take to develop an eLearning platform?",
        description: `The development timeline for an eLearning platform depends on the complexity of the project, the number of features, and the level of customization required. On average, it can take anywhere from a few months to 6 months to develop a fully functional eLearning platform.`,
      },
      {
        id: 4,
        title: "Can eLearning software integrate with existing systems?",
        description: `Yes, most eLearning software can integrate with existing systems such as Learning Management Systems (LMS), Customer Relationship Management (CRM) software, and other educational tools. Integration ensures seamless data flow and enhances the overall functionality of the learning environment.`,
      },
      {
        id: 5,
        title: "How do you ensure the security of eLearning software?",
        description: `Ensuring the security of eLearning software involves implementing multiple layers of protection, including:\n <ul><li>Data Encryption: Securing data in transit and at rest.</li><li>User Authentication: Strong password policies, multi-factor authentication.</li><li>Regular Updates: Keeping the software up-to-date with security patches.</li><li>Access Controls: Restricting access to sensitive information.</li><li>Compliance: Adhering to data protection regulations such as GDPR or CCPA.</li></ul>`,
      },
      {
        id: 6,
        title: "What are the trends in eLearning software development?",
        description: `Current trends in eLearning software development include:\n <ul><li>AI and Machine Learning: For adaptive learning and personalized experiences.</li><li>Microlearning: Delivering content in small, manageable chunks.</li><li>Mobile Learning: Optimizing for smartphones and tablets.</li><li>Gamification: Increasing motivation through game-like features.</li><li>AR/VR: Creating immersive and interactive learning environments.</li><li>Social Learning: Enhancing peer-to-peer interaction and collaboration.</li></ul>\n For more information on how Webelight Solutions can help you develop your eLearning software, contact us today!`,
      },
    ],
    seo: {
      title:
        "E-Learning Software Development Services: Empowering Online Education",
      description:
        "Boost engagement & knowledge retention with custom eLearning software. We develop LMS platforms, mobile learning apps & more. Get a free quote today!",
      keywords:
        "Education App Development Services,E-Learning Software Development Company,Leading Education Software Development Company,Online Learning Platform,Learning Management System,Educational Technology Solutions,Virtual Classroom Software,Distance Learning Software,Interactive Learning Apps,Mobile Learning Solutions,EdTech Development Services,Custom eLearning Solutions,Educational App Developers,Learning Experience Platforms,Digital Education Tools,Personalized Learning Software,Gamified Learning Apps,Cloud-Based Learning Solutions",
      openGraph: {
        title:
          "E-Learning Software Development Services: Empowering Online Education",
        description:
          "Boost engagement & knowledge retention with custom eLearning software. We develop LMS platforms, mobile learning apps & more. Get a free quote today!",
        type: "Website",
        siteName: "Webelight",
        images: {
          url: "/img/industries/eLearning/ogImages/eLearning-og-image.png",
          alt: "elearning-og-image",
          width: 1200,
          height: 630,
        },
      },
      twitter: {
        title:
          "E-Learning Software Development Services: Empowering Online Education",
        description:
          "Boost engagement & knowledge retention with custom eLearning software. We develop LMS platforms, mobile learning apps & more. Get a free quote today!",
        images: {
          url: "/img/industries/eLearning/ogImages/eLearning-og-image.png",
          alt: "elearning-og-image",
          width: 1200,
          height: 675,
        },
      },
    },
  },
};

export const costEstimationProcessSteps = [
  {
    id: uuidv4(),
    imagePath: projectScope,
    imageAlt: "Scope of the Project",
    title: "Scope of the Project",
    bg: "wb-bg-primary",
  },
  {
    id: uuidv4(),
    imagePath: techonologyStack,
    imageAlt: "Technology Stack",
    title: "Technology Stack",
    bg: "wb-bg-primary-100",
  },
  {
    id: uuidv4(),
    imagePath: customization,
    imageAlt: "Custom Functionalities",
    title: "Custom Functionalities",
    bg: "hiring-step-bg-primary-200",
  },
  {
    id: uuidv4(),
    imagePath: integration,
    imageAlt: "Integration Third Party Services",
    title: "Integration Third Party Services",
    bg: "hiring-step-bg-primary-300",
  },
  {
    id: uuidv4(),
    imagePath: scalingRequirements,
    imageAlt: "Scaling Requirements",
    title: "Scaling Requirements",
    bg: "hiring-step-bg-primary-300",
  },
];
