import { Variants } from "framer-motion";

export const DEFAULT_ANIMATION: Variants = {
  initial: { opacity: 0, y: -50 },
  final: { opacity: 1, y: 0 },
};

export const DEFAULT_TRANSITION = {
  duration: 0.5,
};

export const DEFAULT_VIEWPORT = {
  once: true,
};

export const WORD_ROTATION_TRANSITION = {
  type: "spring",
  stiffness: 100,
  duration: 0.3,
};

export const DIRECTION_VARIANTS: {
  FROM_LEFT: Variants;
  FROM_RIGHT: Variants;
  FROM_BOTTOM: Variants;
  FROM_TOP: Variants;
  Z_ROTATE: Variants;
} = {
  FROM_LEFT: {
    initial: { opacity: 0, x: -100 },
    final: { opacity: 1, x: 0 },
  },
  FROM_RIGHT: {
    initial: { opacity: 0, x: 100 },
    final: { opacity: 1, x: 0 },
  },
  FROM_BOTTOM: {
    initial: { opacity: 0, y: 100 },
    final: { opacity: 1, y: 0 },
  },
  FROM_TOP: {
    initial: { opacity: 0, y: -100 },
    final: { opacity: 1, y: 0 },
  },
  Z_ROTATE: {
    initial: { opacity: 0, rotateZ: 90 },
    final: { opacity: 1, rotateZ: 0 },
  },
};

export const FADE_TO_SHOW_ANIMATION = {
  initial: { opacity: 0 },
  final: { opacity: 1 },
};

export const BLUR_ANIMATION: Variants = {
  initial: { filter: "blur(2px)", scale: 0 },
  final: { filter: "blur(0px)", scale: 1 },
};

export const WHILE_ON_HOVER = {
  hover: { opacity: 1, x: 5 },
};

export const SUCCESS_MSG_VARIANT = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -20,
  },
};
export const CONTAINER_VARIANTS = {
  initial: {
    height: 0,
    opacity: 0,
  },
  final: {
    height: "auto",
    opacity: 1,
  },
};

export const PARAGRAPH_VARIANTS = {
  initial: {
    y: -20,
    opacity: 0,
    height: 0,
  },
  final: {
    opacity: 1,
    y: 0,
    height: "auto",
  },
};
export const FADE_TO_SHOW_CIRCLE_ANIMATION = {
  initial: { opacity: 0 },
  final: { opacity: 0.3 },
};
