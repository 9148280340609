import { string, object } from "yup";

export const contactFormSchema = object().shape({
  email: string()
    .email("Invalid email address")
    .required("Please provide us a valid email"),
  name: string().required("Please provide us full name"),
  companyName: string(),
  message: string(),
  phone: string(),
});
