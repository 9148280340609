import justDate from "@webelight-site/public/img/breakers-image/clients/justdate.svg";
import coldwatch from "@webelight-site/public/img/breakers-image/clients/coldwatch.svg";
import og from "@webelight-site/public/img/breakers-image/clients/og.svg";
import navodaya from "@webelight-site/public/img/breakers-image/clients/navodaya.svg";
import eLocker from "@webelight-site/public/img/breakers-image/clients/e-locker.svg";
import tappstr from "@webelight-site/public/img/breakers-image/clients/tappstr.svg";

export const clientsLogo = [
  {
    title: "navodaya",
    image: navodaya,
  },
  {
    title: "tappstr",
    image: tappstr,
  },
  {
    title: "just date",
    image: justDate,
  },
  {
    title: "Og stack",
    image: og,
  },
  {
    title: "your-e-locker",
    image: eLocker,
  },
  {
    title: "cold watch",
    image: coldwatch,
  },
];
