"use client";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import { useDeviceDetect } from "@webelight-site/components/Header/hooks/useDeviceDetect";

const HireExpertise = dynamic(() => import("./HireExpertise"));
const HireExpertiseMobile = dynamic(() => import("./HireExpertiseMobile"));

export const HireExpertiseSection = ({
  showButton = false,
}: {
  showButton?: boolean;
}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <Suspense>
      {isMobile ? (
        <HireExpertiseMobile isShowButton={showButton} />
      ) : (
        <HireExpertise isShowButton={showButton} />
      )}
    </Suspense>
  );
};
