"use client";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import { useDeviceDetect } from "@webelight-site/components/Header/hooks/useDeviceDetect";

const DesktopServiceGrid = dynamic(() => import("./DesktopServicesGrid"));
const ServiceSwiper = dynamic(() => import("./ServiceSwiper"));

type Service = {
  image: string;
  link: string;
  title: string;
  description: string;
};

interface Props {
  services: Service[];
}
export const OurServicesCards = ({ services }: Props) => {
  const { isMobile } = useDeviceDetect();

  return (
    <Suspense>
      {isMobile ? (
        <ServiceSwiper services={services} />
      ) : (
        <DesktopServiceGrid services={services} />
      )}
    </Suspense>
  );
};
